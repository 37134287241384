import { SurgeryTypeGroup } from '../selectors'

export function isSpecialitySurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroup): boolean {
    return surgeryTypeGroup.group_type === 'speciality'
}

export function isCompositeSurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroup): boolean {
    return surgeryTypeGroup.group_type === 'composite'
}

export function isCategorySurgeryTypeGroup(surgeryTypeGroup: SurgeryTypeGroup): boolean {
    return surgeryTypeGroup.group_type === 'category'
}
