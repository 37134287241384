import * as Collapsible from '@radix-ui/react-collapsible'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { RightOutlined } from '~/icons'

import { HeroPopover } from '../HeroPopover/HeroPopover'
import {
    SidebarContent,
    SidebarGroup,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useSidebar,
} from './HeroSideNav'

export type NavItem = {
    title: string
    url: string
    icon?: React.ElementType // Assuming icons are React components
    isHidden?: boolean
    isActive?: boolean
    items?: NavItem[] // Recursive definition for nested items
}

const CollapsibleItem = ({ item }: { item: NavItem }) => (
    <Collapsible.Root asChild defaultOpen={item.isActive} className={`group/collapsible ${item.isHidden ? 'hidden' : ''}`}>
        <SidebarMenuItem className={`${item.isHidden ? 'hidden' : ''}`}>
            <Collapsible.Trigger asChild>
                <SidebarMenuButton tooltipContent={item.title}>
                    {item.icon && <item.icon />}
                    <span>{item.title}</span>
                    {item.items && <RightOutlined className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />}
                </SidebarMenuButton>
            </Collapsible.Trigger>
            <Collapsible.Content>
                <SidebarMenuSub>
                    {item.items?.map(
                        subItem =>
                            !subItem.isHidden && (
                                <SidebarMenuSubItem key={subItem.title}>
                                    <SidebarMenuSubButton
                                        asChild
                                        data-test={`${subItem.url.trim().replace('/', '')}-link`}
                                        className={`${subItem.isActive ? 'bg-sidebar-accent text-sidebar-accent-foreground' : ''}`}
                                    >
                                        <Link to={subItem.url}>
                                            <span>{subItem.title}</span>
                                        </Link>
                                    </SidebarMenuSubButton>
                                </SidebarMenuSubItem>
                            )
                    )}
                </SidebarMenuSub>
            </Collapsible.Content>
        </SidebarMenuItem>
    </Collapsible.Root>
)

const MenuItem = ({ item }: { item: NavItem }) => {
    return (
        <SidebarMenuItem>
            <HeroPopover
                side={'right'}
                align="end"
                noPadding
                sideOffset={4}
                popoverContent={
                    <div>
                        {item.items?.map(
                            subItem =>
                                !subItem.isHidden && (
                                    <Link
                                        to={subItem.url}
                                        key={subItem.title}
                                        className="flex gap-1 rounded px-4 py-2 text-sm outline-none hover:cursor-pointer hover:bg-sidebar-accent"
                                    >
                                        <span>{subItem.title}</span>
                                    </Link>
                                )
                        )}
                    </div>
                }
            >
                <SidebarMenuButton tooltipContent={item.title}>
                    {item.icon && <item.icon />}
                    <span>{item.title}</span>
                    {item.items && <RightOutlined className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />}
                </SidebarMenuButton>
            </HeroPopover>
        </SidebarMenuItem>
    )
}

export const HeroMainNavContent = ({ navItems }: { navItems: NavItem[] }) => {
    const { state } = useSidebar()
    const ItemToShow: Record<typeof state, React.FC<{ item: NavItem }>> = useMemo(() => {
        return {
            collapsed: MenuItem,
            expanded: CollapsibleItem,
        }
    }, [])
    const Comp = useMemo(() => ItemToShow[state], [ItemToShow, state])

    return (
        <SidebarContent>
            <SidebarGroup>
                <SidebarMenu>
                    {navItems.map(item =>
                        item.items && item.items.length > 0 ? (
                            <Comp item={item} key={item.title} />
                        ) : (
                            !item.isHidden && (
                                <SidebarMenuItem key={item.title} className={`${item.isHidden ? 'hidden' : ''} `}>
                                    <SidebarMenuButton
                                        data-test={`${item.url.trim().replace('/', '')}-link`}
                                        tooltipContent={item.title}
                                        asChild
                                        className={`${item.isActive ? 'bg-sidebar-accent text-sidebar-accent-foreground' : ''}`}
                                    >
                                        <Link to={item.url}>
                                            {item.icon && <item.icon />}
                                            <span>{item.title}</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            )
                        )
                    )}
                </SidebarMenu>
            </SidebarGroup>
        </SidebarContent>
    )
}
