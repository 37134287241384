import { Middleware } from 'openapi-fetch'

// investigate https://deepinsight-no.slack.com/archives/C079FF7BG1G/p1732281978180339
export const log400Middleware: Middleware = {
    async onResponse(response) {
        if (response.status === 400 && response.url.includes('waiting?TreatmentLocationId=')) {
            console.error(`400 invalid argument: ${response.url} ${await response.text()}`)
        }
        return response
    },
}
