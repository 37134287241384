import clsx from 'clsx'
import { forwardRef } from 'react'

import { CalendarOutlined } from '~/icons'

import { inputSize, inputStyles, TextInputSize } from '../../TextInput'

const widths = {
    fixed: 'w-[250px]',
    full: 'w-full',
}

type Props = {
    size?: TextInputSize
    formattedDate?: string
    width?: keyof typeof widths
}

export const DatePickerInput = forwardRef<HTMLButtonElement, Props>(({ size = 'md', formattedDate, width = 'fixed', ...props }, ref) => (
    <button ref={ref} {...props} className={clsx(`flex cursor-pointer items-center justify-between px-3 py-1`, inputStyles, inputSize[size], widths[width])}>
        <span className={clsx({ 'text-gray-400': !formattedDate })}>{formattedDate ?? 'Velg dato'}</span>
        <CalendarOutlined className="ml-5 h-5 w-5" />
    </button>
))
