import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { BlockScheduleEditor } from '~/components'
import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { Location } from '~/store/selectors'
import { isHoliday } from '~/utils/extendedDayjs'

import { Duration } from '../../PeriodCalendar/WeeklyTable/Duration'
import { Utilization } from '../../PeriodCalendar/WeeklyTable/Utilization'
import { ResolvedRule } from './ResolvedRule'

type Props = {
    date: Dayjs
    location: Location
}

export const Cell = ({ date, location }: Props) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    if (isHoliday(date)) {
        return null
    }

    return (
        <>
            <HeroPopover
                noPadding
                isOpen={isPopoverOpen}
                onPointerDownOutside={() => setIsPopoverOpen(false)}
                popoverContent={
                    <BlockScheduleEditor date={date} location={location} onRemove={() => setIsPopoverOpen(false)} onSubmit={() => setIsPopoverOpen(false)} />
                }
            >
                <button className="flex h-full w-full flex-col p-2 text-left hover:bg-slate-50" onClick={() => setIsPopoverOpen(true)}>
                    <div className="flex gap-1">
                        <Utilization date={date} location={location} />
                        <Duration date={date} location={location} />
                    </div>
                    <ResolvedRule date={date} location={location} />
                </button>
            </HeroPopover>
        </>
    )
}
