import dayjs from 'dayjs'

import { PlanningPeriod } from '~/store/selectors/entities'
import { getToday } from '~/utils/extendedDayjs'

export type ProgressStatus = 'completed' | 'current' | 'future'

export function getProgressStatus(planningPeriod: PlanningPeriod): ProgressStatus {
    const today = getToday()

    if (dayjs(planningPeriod.end_date).isBefore(today)) {
        return 'completed'
    }

    if (dayjs(planningPeriod.start_date).isAfter(today)) {
        return 'future'
    }

    return 'current'
}
