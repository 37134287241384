import { Show } from '~/components'
import { format, getToday } from '~/utils/extendedDayjs'

import { Status } from '../../shared/Statuses'

const RadioSelected = () => (
    <div className="flex h-4 w-4 items-center justify-center rounded-full border-2 border-indigo-600">
        <div className="h-2 w-2 rounded-full bg-indigo-600" />
    </div>
)

const getOptions = (isUnscheduledItem: boolean): { label: string; status: Status }[] => [
    { label: 'Ikke klarert', status: { type: 'cleared', value: isUnscheduledItem ? 'notCompleted' : 'warning' } },
    { label: 'Klarert', status: { type: 'cleared', value: 'completed' } },
]

const getValue = (isUnscheduledItem: boolean, isNew: boolean, isCleared: boolean) => {
    if (isNew) return undefined
    if (isCleared) return 'completed'
    return isUnscheduledItem ? 'notCompleted' : 'warning'
}

type Props = {
    isUnscheduledItem: boolean
    isNew: boolean
    isCleared: boolean
    onChange: (value: { patient_ready: boolean; follow_up_date: string | null }) => void
}

export function ClearedRadioGroup({ isUnscheduledItem, isNew, isCleared, onChange }: Props) {
    const today = getToday()
    const options = getOptions(isUnscheduledItem)
    const value = getValue(isUnscheduledItem, isNew, isCleared)

    return (
        <div className="flex h-fit w-full items-center gap-2 p-0">
            {options.map(option => {
                const optionValue = option.status.value
                const isSelected = optionValue === value
                const isPatientCleared = optionValue === 'completed'
                const followUpDate = isPatientCleared ? null : format(today, 'YYYY-MM-DD')

                return (
                    <div
                        key={String(option.label)}
                        className={`flex flex-1 cursor-pointer items-center justify-between rounded border px-3 py-2 ${isSelected ? 'border-indigo-500' : 'border-gray-200 hover:border-gray-400'}`}
                        onClick={() => onChange({ patient_ready: isPatientCleared, follow_up_date: followUpDate })}
                    >
                        <div className="flex items-center gap-2">
                            <Status status={option.status} isSelected={isSelected} />
                            <p>{option.label}</p>
                        </div>
                        <Show condition={isSelected}>
                            <RadioSelected />
                        </Show>
                    </div>
                )
            })}
        </div>
    )
}
