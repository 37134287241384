import clsx from 'clsx'
import { ReactNode } from 'react'

export const tagColors = {
    primary: 'bg-blue-700/20 text-blue-700',
    overbooked: 'bg-white text-red-500',
    location: 'bg-diOrange-400/20 border border-diOrange-400',
    holiday: 'bg-red-200 text-black',
    'on-call': 'bg-[#E6E9E8] text-black',
    'no-bg': 'text-blue-700',
    ASA: 'bg-sky-100 text-black',
    new: 'text-blue-700 border-blue-700 border bg-indigo-50',
    speciality: 'bg-white border-zinc-400 border text-black',
    subspeciality: 'bg-zinc-100 text-black',
    composite: 'bg-zinc-100 text-black',
    category: 'bg-indigo-100 text-black',
    none: 'bg-white text-black',
    surgeryInfo: 'bg-sky-100 text-black border border-sky-300',
    practitioner: 'text-gray-600',
    'practitioner-white': 'text-white',
} as const

export type TagColor = keyof typeof tagColors

const tagSizes = {
    xs: 'py-0',
    sm: 'py-px',
    md: 'py-0.5',
    lg: 'py-2',
} as const

type TagSize = keyof typeof tagSizes

const textSizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
} as const

type TextSize = keyof typeof textSizes

const gaps = {
    0.5: 'gap-0.5',
    1: 'gap-1',
} as const

type Gap = keyof typeof gaps

export type TagProps = {
    color?: TagColor
    defaultCursor?: boolean
    fullWidth?: boolean
    size?: TagSize
    textSize?: TextSize
    gap?: Gap
}

type Props = TagProps & {
    children: ReactNode
}

export const Tag = ({ children, color = 'primary', defaultCursor = false, fullWidth = false, size = 'md', textSize = 'xs', gap = 1, ...props }: Props) => {
    const noPadding = color === 'no-bg' || color === 'practitioner' || color === 'practitioner-white'
    const isFontSemibold = color !== 'none' && color !== 'ASA' && color !== 'practitioner' && color !== 'practitioner-white'

    return (
        <span
            className={clsx(
                `inline-block rounded ${fullWidth ? 'w-full' : 'w-fit'} ${noPadding ? 'px-0' : 'px-2'}`,
                tagSizes[size],
                tagColors[color],
                textSizes[textSize],
                {
                    'font-semibold': isFontSemibold,
                    'cursor-default': defaultCursor,
                }
            )}
            {...props}
        >
            <span className={`flex w-full items-center ${gaps[gap]}`}>{children}</span>
        </span>
    )
}

type HolidayTagProps = TagProps & {
    centerPos?: boolean
}

export const HolidayTag = ({ centerPos = true, ...props }: HolidayTagProps) => (
    <div className={clsx('flex', { 'justify-center': centerPos })}>
        <Tag color="holiday" {...props}>
            Helligdag
        </Tag>
    </div>
)
