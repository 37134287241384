import { Dayjs } from 'dayjs'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { StoreState } from '~/store/store'
import { day, getCalendarDates } from '~/utils/extendedDayjs'

import { DepartmentKey } from '../slices/filterSlice'
import { Practitioner, selectEntities } from './entities'

export type PractitionerId = Practitioner['id']

/*
  Groups practitioners into groups by which departments they've been assigned to.
  Also creates a group 'all', such that the key of the resulting grouping is DepartmentKey.

  exported for testing only
*/
export function _groupByDepartment(practitioners: Practitioner[]): Record<DepartmentKey, Practitioner[]> {
    const practitionerWithOrderByDepartment = {} as Record<DepartmentKey, { practitioner: Practitioner; order?: number }[]>

    for (const practitioner of practitioners) {
        for (const { order, department } of practitioner.departmentAssignments) {
            if (department) {
                ;(practitionerWithOrderByDepartment[department.id] ??= []).push({ practitioner, order: order ?? undefined })
            }
        }
    }

    const result: Record<DepartmentKey, Practitioner[]> = {
        all: sortBy(practitioners, practitioner => practitioner.short_name),
    }
    for (const [departmentId, practitioners] of Object.entries(practitionerWithOrderByDepartment)) {
        result[departmentId as DepartmentKey] = sortBy(practitioners, p => p.order).map(({ practitioner }) => practitioner)
    }
    return result
}

export function _filterByServicePeriods(practitioners: Practitioner[], monthStart?: Dayjs, monthEnd?: Dayjs) {
    if (!monthStart || !monthEnd) return practitioners

    return practitioners.filter(practitioner => {
        if (practitioner.servicePeriods.length === 0) return true

        return practitioner.servicePeriods.some(sp => {
            const start = sp.start_time ? day(sp.start_time) : undefined
            const end = sp.end_time ? day(sp.end_time) : undefined

            const startsBeforeMonthEnd = !start || !start.isAfter(monthEnd)
            const endsAfterMonthStart = !end || !end.isBefore(monthStart)

            return startsBeforeMonthEnd && endsAfterMonthStart
        })
    })
}

export const selectGetPractitioners = createSelector(
    selectEntities,
    (state: StoreState) => state.appFilters.selectedDate,
    ({ practitioners }, selectedDate) => {
        const daysOfMonth = getCalendarDates(selectedDate)
        const monthStart = daysOfMonth.at(0)
        const monthEnd = daysOfMonth.at(-1)

        const practitionersByServicePeriods = _filterByServicePeriods(practitioners, monthStart, monthEnd)

        const result = _groupByDepartment(practitionersByServicePeriods)
        const byId = keyBy(practitionersByServicePeriods, 'id')

        return {
            byDepartmentKey: (departmentKey: DepartmentKey): Practitioner[] => result[departmentKey] ?? [],
            byId: (id: number): Practitioner | null => byId[id] ?? null,
            all: (): Practitioner[] => practitionersByServicePeriods,
        }
    }
)
