import { PathsWithMethod } from 'openapi-typescript-helpers'
import { useState } from 'react'

import { getClient } from '~/clients/dips-client'
import { paths } from '~/clients/generated-dips-types'

type Props = {
    label: string
    path: PathsWithMethod<paths, 'get'>
    params?: Record<string, unknown>
}

export const BaseDownloadButton = ({ path, label, params }: Props) => {
    const [isFetching, setIsFetching] = useState(false)

    const handleOnClick = async () => {
        setIsFetching(true)
        const response = await getClient().GET(path, { params })
        setIsFetching(false)

        if (response.response.status !== 200 || !response.data) return

        const blob = new Blob([JSON.stringify(response.data, null, '\t')], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${label}.json`
        a.click()
        a.remove()
    }

    return (
        <button onClick={handleOnClick} disabled={isFetching} className="block cursor-pointer rounded bg-green-200 p-2">
            <div className="flex flex-row items-center justify-center gap-2">
                <div>Download: {label}</div>
                {isFetching && <div className="animate-spin">◒</div>}
            </div>
        </button>
    )
}
