import { ReactNode } from 'react'

import { HeroSwitch } from '~/components'

type SwitchRowProps = {
    label: string
    children: ReactNode
    checked: boolean
    onCheckedChange: (checked: boolean) => void
}

export const SidebarSwitch = ({ label, children, checked, onCheckedChange }: SwitchRowProps) => (
    <div
        className={`flex flex-1 cursor-pointer items-center justify-between rounded border px-3 py-2 ${checked ? 'border-indigo-500' : 'border-gray-200 hover:border-gray-400'}`}
        onClick={() => onCheckedChange(!checked)}
    >
        <div className="flex items-center gap-2">
            {children}
            <p>{label}</p>
        </div>
        <HeroSwitch checked={checked} onCheckedChange={onCheckedChange} />
    </div>
)
