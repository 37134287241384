import { ReactNode, RefObject, useEffect, useRef } from 'react'

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: (event: Event) => void) => {
    useEffect(() => {
        const listener = (event: Event) => {
            const el = ref?.current
            if (!el || el.contains((event?.target as Node) || null)) return
            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}

interface Props {
    children: ReactNode
    callback: () => void
}

export function OutsideClick({ children, callback }: Props) {
    const wrapperRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(wrapperRef, callback)

    return <div ref={wrapperRef}>{children}</div>
}
