import { useStore } from '~/store/store'

import { selectWaitingListFilterValues } from '../selectors/waitingListFilters'

export const useWaitingListResultsMessage = (isNoResults: boolean, needle: string) => {
    let tableMessage = ''
    let tooltipMessage = ''

    const { isFiltersApplied } = useStore(selectWaitingListFilterValues)

    const isFiltersOrSearchApplied = isFiltersApplied || !!needle

    const hasDeniedAccessItems = useStore(state => state.waitingList.hasDeniedAccessItems)

    if (isNoResults) {
        if (hasDeniedAccessItems) {
            if (isFiltersOrSearchApplied) {
                tableMessage =
                    'Du ser ingen pasienter. Dette kan skyldes at det er aktive filtre eller søk, eller at du mangler tilgang til pasientinformasjon.'
            } else {
                tableMessage = 'Du ser ingen pasienter fordi du ikke har tilgang til all pasientinformasjon.'
            }
        } else {
            if (isFiltersOrSearchApplied) {
                tableMessage = 'Du ser ingen pasienter fordi det er aktive filtre eller søk.'
            } else {
                tableMessage = 'Noe gikk galt. Prøv å oppdatere siden.'
            }
        }
    } else {
        if (hasDeniedAccessItems) {
            tooltipMessage = 'Du ser et begrenset antall pasienter fordi du ikke har tilgang til all pasientinformasjon.'
        }
    }

    return {
        tableMessage,
        tooltipMessage,
    }
}
