import { Schemas } from '~/clients/api-client'

export type SomeEntity = {
    entity_type: string
    id: number | string
    [key: string]: string | number | Record<string, unknown> | SomeEntity | SomeEntity[] | null | undefined | boolean
}

type RuleInstanceSchema =
    | Schemas['MaxCountRuleOut']
    | Schemas['MaxWeightRuleOut']
    | Schemas['MaxDurationRuleOut']
    | Schemas['MaxDurationAndCountRuleOut']
    | Schemas['MaxKnifeDurationRuleOut']
    | Schemas['MaxKnifeDurationAndCountRuleOut']

export function isSomeEntity(entity: unknown): entity is SomeEntity {
    return typeof entity === 'object' && entity !== null && 'entity_type' in entity
}

/**
 * Checks if the given data is an array of SomeEntity objects, but only checks
 * the first element and assumes that all elements are also SomeEntity objects.
 */
export function isLikelySomeEntityArray(data: unknown): data is SomeEntity[] {
    return Array.isArray(data) && isSomeEntity(data[0])
}

export function isBlockScheduleOut(entity: unknown): entity is Schemas['BlockScheduleOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'block_schedule'
}

export function isLocationOut(entity: unknown): entity is Schemas['LocationOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'location'
}

export function isLocationScheduleOut(entity: unknown): entity is Schemas['LocationScheduleOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'location_schedule'
}

export function isDepartmentOut(entity: unknown): entity is Schemas['DepartmentOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'department'
}

export function isDepartmentLocationAssignmentOut(entity: unknown): entity is Schemas['DepartmentLocationAssignmentOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'department_location_assignment'
}

export function isDepartmentPractitionerAssignmentOut(entity: unknown): entity is Schemas['DepartmentPractitionerAssignmentOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'department_practitioner_assignment'
}

export function isPlanningPeriodOut(entity: unknown): entity is Schemas['PlanningPeriodOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'planning_period'
}

export function isPractitionerOut(entity: unknown): entity is Schemas['PractitionerOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner'
}

export function isPractitionerScheduleOut(entity: unknown): entity is Schemas['PractitionerScheduleOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner_schedule'
}

export function isPractitionerScheduleStatusOut(entity: unknown): entity is Schemas['PractitionerScheduleStatusOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner_schedule_status'
}

export function isPractitionerStatusDefinitionOut(entity: unknown): entity is Schemas['PractitionerStatusDefinitionOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner_status_definition'
}

export function isPractitionerScheduleLocationOut(entity: unknown): entity is Schemas['PractitionerScheduleLocationOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner_schedule_location'
}

export function isPractitionerServicePeriodOut(entity: unknown): entity is Schemas['PractitionerServicePeriodOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'practitioner_service_period'
}

export function isRuleDefinitionOut(entity: unknown): entity is Schemas['RuleDefinitionOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'rule_definition'
}

export function isRuleInstanceOut(entity: unknown): entity is RuleInstanceSchema {
    return isSomeEntity(entity) && entity.entity_type === 'rule_instance'
}

export function isSectionOut(entity: unknown): entity is Schemas['SectionOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'section'
}

export function isBlockLockOut(entity: unknown): entity is Schemas['BlockLockOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'block_lock'
}

export function isSurgeryMetadatumOut(entity: unknown): entity is Schemas['SurgeryMetadatumOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'surgery_metadatum'
}

export function isSpecialityOut(entity: unknown): entity is Schemas['SpecialityOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'speciality'
}

export function isAgeGroupOut(entity: unknown): entity is Schemas['AgeGroupOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'age_group'
}

export function isHospitalSurgeryTypeOut(entity: unknown): entity is Schemas['HospitalSurgeryTypeOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'hospital_surgery_type'
}

export function isSurgeryTypeGroupOut(entity: unknown): entity is Schemas['SurgeryTypeGroupOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'surgery_type_group'
}

export function isSurgeryTypeGroupAgeRestrictionOut(entity: unknown): entity is Schemas['SurgeryTypeGroupAgeRestrictionOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'surgery_type_group_age_restriction'
}

export function isSurgeryTypeGroupHierarchyOut(entity: unknown): entity is Schemas['SurgeryTypeGroupHierarchyOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'surgery_type_group_hierarchy'
}

export function isSurgeryTypeGroupSpecialityOut(entity: unknown): entity is Schemas['SurgeryTypeGroupSpecialityOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'surgery_type_group_speciality'
}

export function isHospitalSurgeryTypeGroupAssociationOut(entity: unknown): entity is Schemas['HospitalSurgeryTypeGroupAssociationOut'] {
    return isSomeEntity(entity) && entity.entity_type === 'hospital_surgery_type_group_association'
}
