import { createSelector } from 'reselect'

import { largestFirstSurgeryCountForWeight, maxSurgeryCountForWeight } from '~/utils/blockEvaluation'

import { selectSurgeryTypeGroup, SurgeryTypeGroupCode } from './surgeryTypeGroups'

export const selectMinMaxNumberOfSurgeries = createSelector(selectSurgeryTypeGroup, resolvedSurgeryTypeGroups => {
    return {
        byRemainingWeight: (code: SurgeryTypeGroupCode | undefined, remainingWeight: number) => {
            let value = String(remainingWeight)
            let minCount = remainingWeight
            let maxCount = remainingWeight

            const resolvedSurgeryTypeGroup = code ? resolvedSurgeryTypeGroups.byCode(code) : null

            if (resolvedSurgeryTypeGroup) {
                const sortedWeights = resolvedSurgeryTypeGroup.sortedUniqueSurgeryWeights
                maxCount = maxSurgeryCountForWeight(remainingWeight, sortedWeights)
                minCount = largestFirstSurgeryCountForWeight(remainingWeight, sortedWeights)
                value = maxCount > minCount ? `${minCount}-${maxCount}` : `${maxCount}`
            }

            return { value, minCount, maxCount }
        },
    }
})
