import { Dayjs } from 'dayjs'

import {
    createPractitionerSchedule,
    createPractitionerScheduleStatus,
    deleteBatchPractitionerScheduleStatus,
    deletePractitionerScheduleStatus,
} from '~/store/di-entity.api'
import { Practitioner, selectGetPractitionerSchedules, selectGetPractitionerScheduleStatuses } from '~/store/selectors'
import { StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs'

export const usePractitionerScheduleActions = (status: StatusCode, date: Dayjs) => {
    async function removePractitionerScheduleOnDate() {
        try {
            const practitionerSchedules = selectGetPractitionerSchedules(useStore.getState()).byDate(date)

            if (!practitionerSchedules) {
                return
            }

            const practitionerSchedulesToDelete = practitionerSchedules.flatMap(practitionerSchedule => {
                return practitionerSchedule.statuses.filter(({ status_code }) => status_code === status)
            })

            await deleteBatchPractitionerScheduleStatus(practitionerSchedulesToDelete.map(({ id }) => id))
        } catch (e) {
            console.error(e)
        }
    }

    async function removePractitioner(practitioner: Practitioner | null) {
        if (!practitioner) return

        try {
            const practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)

            if (!practitionerSchedule) {
                return
            }

            const practitionerScheduleStatuses = selectGetPractitionerScheduleStatuses(useStore.getState()).byPractitionerScheduleId(practitionerSchedule.id)
            const statusesToDelete = practitionerScheduleStatuses.find(({ status_code }) => status_code === status)

            if (!statusesToDelete) {
                return
            }

            await deletePractitionerScheduleStatus(statusesToDelete.id)
        } catch (e) {
            console.error(e)
        }
    }

    async function addPractitioner(practitioner: Practitioner | null) {
        if (!practitioner) return

        try {
            let practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)

            if (!practitionerSchedule) {
                await createPractitionerSchedule({
                    practitioner_id: practitioner.id,
                    start_time: date.format(),
                    end_time: date.add(1, 'day').format(),
                    tzid: HOSPITAL_TIMEZONE,
                })
                practitionerSchedule = selectGetPractitionerSchedules(useStore.getState()).byDateAndPractitioner(date, practitioner)
            }

            if (!practitionerSchedule) {
                console.error('Practitioner schedule not found')
                return
            }

            await createPractitionerScheduleStatus({
                practitioner_schedule_id: Number(practitionerSchedule.id),
                status_code: status,
            })
        } catch (e) {
            console.error(e)
        }
    }

    return { addPractitioner, removePractitioner, removePractitionerScheduleOnDate } as const
}
