import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar } from '~/components'
import { HeroSideNavTrigger } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'

import { DatePicker } from './DatePicker'
import { Filters } from './Filters'

export const Header = () => (
    <Bar
        left={
            <div className="flex gap-1">
                <HeroSideNavTrigger />
                <ViewSwitcher pageKey="OPERATIONAL_PLANNER" />
            </div>
        }
        center={<DatePicker />}
        right={<Filters />}
    />
)
