import { createRef } from 'react'

import PageLayout from '~/components/PageLayout'
import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import { useStore } from '~/store/store'

import { WaitingListSidebar } from './Drawer/WaitingListSidebar'
import { Header } from './Header/Header'
import { useImportWaitingListEntities } from './hooks/useImportWaitingListEntities'
import { Table } from './Table/Table'

const WaitingListPage = () => {
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const drawerProps = useStore(state => state.waitingList.drawerProps)

    // Enable mapping from surgery type to surgery category in operation type filter
    useImportSurgeryTypeGroupEntities()
    const ref = createRef<HTMLDivElement>()
    const { isLoading } = useImportWaitingListEntities(activeView)
    const { setDrawerProps } = useStore(state => state.waitingList.actions)

    return (
        <PageLayout
            content={<Table isLoading={isLoading} containerRef={ref} />}
            header={<Header />}
            sidebar={<WaitingListSidebar />}
            isSidebarOpen={drawerProps !== null}
            setIsSidebarOpen={open => {
                !open && setDrawerProps(null)
            }}
        />
    )
}

export default WaitingListPage
