import { DISelect } from '~/components'

type Props = {
    title: string
    values: string[]
    options: { label: string; value: string }[]
    onChange: (args: { value: string; label: string }) => void
    emptyFilter: () => void
}

export const MultipleSelectWithTitle = ({ title, values, options, onChange, emptyFilter }: Props) => (
    <div className="px-4 pb-4 pt-2">
        <p className="w-full pb-2">{title}</p>
        <DISelect
            name="multiple-select"
            isMulti
            isClearable
            options={options}
            value={options.filter(option => values.includes(option.value))}
            onChange={(_, meta) => {
                if (meta.action === 'clear') {
                    emptyFilter()
                } else if (meta.action === 'select-option') {
                    if (!meta.option) return
                    onChange({ value: meta.option.value, label: meta.option.label })
                } else if (meta.action === 'pop-value' || meta.action === 'remove-value') {
                    if (!meta.removedValue) return
                    onChange({ value: meta.removedValue.value, label: meta.removedValue.label })
                }
            }}
        />
    </div>
)
