import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Show } from '~/components'
import { ExtraRow } from '~/components/DataGrid/utils'
import {
    IdDate,
    Practitioner,
    PractitionerSchedule,
    selectGetPractitioners,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
} from '~/store/selectors'
import { selectPractitionerValues, StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { isHoliday } from '~/utils/extendedDayjs'
import { getHolidayStyles } from '~/utils/utils'

import { getFridayStyles } from '../../shared/utils'
import { EditableCell } from './EditableCell'

export type DatePractitionerCell = {
    date: Dayjs
    practitionerId: number
}

const isActivePractitioner = (filteredPractitioner: Practitioner[], schedule?: PractitionerSchedule) => {
    return filteredPractitioner.map(practitioner => practitioner.id).includes(Number(schedule?.practitioner?.id))
}

export function useCreateInteractiveRows() {
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    function getMatchingPractitioners(statuses: StatusCode[], date: Dayjs) {
        const practitionerSchedulesByDate = getPractitionerSchedules.byDate(date)
        const practitionerScheduleStatuses = practitionerSchedulesByDate.flatMap(schedule =>
            getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
        )

        const matching = practitionerScheduleStatuses
            .filter(({ schedule }) => isActivePractitioner(activePractitioners, schedule))
            .filter(status => statuses.includes(status.status_code))

        const names = matching.map(({ schedule }) => schedule?.practitioner?.short_name).join(', ')
        return { total: matching.length, names: names }
    }

    function createCountableRow(name: string, statuses: StatusCode[]): ExtraRow<IdDate> {
        return {
            key: name,
            rowHeader: () => <span className="text-xs">{name}</span>,
            cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
            cellRender: ({ date }) => {
                if (isHoliday(date)) return null
                const practitionersWithSurgeryStatus = getMatchingPractitioners(statuses, date)
                return (
                    <Show condition={practitionersWithSurgeryStatus.total > 0}>
                        <span className="text-xs" data-tooltip={practitionersWithSurgeryStatus.names}>
                            {practitionersWithSurgeryStatus.total}
                        </span>
                    </Show>
                )
            },
        }
    }

    function createEditableRow(name: string, status: StatusCode): ExtraRow<IdDate> {
        return {
            key: name,
            rowHeader: () => <p className="truncate p-1">{name}</p>,
            cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
            cellRender: ({ date }) => {
                if (isHoliday(date)) return null
                const schedulesByStatus = getPractitionerSchedules
                    .byDate(date)
                    .filter(schedule => isActivePractitioner(activePractitioners, schedule))
                    .filter(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id).some(s => s.status_code === status))

                return <EditableCell date={date} status={status} practitionerScheduleByStatus={schedulesByStatus} />
            },
        }
    }

    return { createCountableRow, createEditableRow }
}
