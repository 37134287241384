import * as Switch from '@radix-ui/react-switch'

type Props = {
    checked: boolean
    onCheckedChange: (checked: boolean) => void
}

export const HeroSwitch = ({ checked, onCheckedChange }: Props) => (
    <Switch.Root checked={checked} onCheckedChange={onCheckedChange} className={`relative h-4 w-7 rounded-full ${checked ? 'bg-indigo-600' : 'bg-gray-400'}`}>
        <Switch.Thumb
            className={`block h-[14px] w-[14px] rounded-full bg-white transition-transform will-change-transform ${checked ? 'translate-x-[13px]' : 'translate-x-[1px]'}`}
        />
    </Switch.Root>
)
