import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar, DIDatePicker, Show } from '~/components'
import { HeroSideNavTrigger } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'
import {
    importLocationSchedules,
    importPractitionerScheduleLocations,
    importPractitionerSchedules,
    importPractitionerScheduleStatuses,
    importPractitionerServicePeriods,
} from '~/store/di-entity.api'
import { selectTimeArgs } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { LocationTable } from './LocationTable/LocationTable'
import { PractitionerTable } from './PractitionerTable/PractitionerTable'
import { Filters } from './shared/Filters'

const SurgeonSchedulerPage = () => {
    const pageKey = 'SURGEON_SCHEDULER'
    const activeView = useStore(state => state.app.activeViews[pageKey])
    const isPractitionerView = activeView === '/surgeon-scheduler/practitioners'
    const timeArgs = useStore(selectTimeArgs)

    const { isLoading } = useImportEntities(
        () => [
            importPractitionerScheduleStatuses({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerScheduleLocations({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerServicePeriods({}),
            importLocationSchedules({ ...timeArgs, exclude_location: true }),
            importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true }), // include practitioner's status + practitioner's locations
        ],
        [timeArgs]
    )

    return (
        <div className="flex h-full flex-col gap-2 p-2">
            <Bar
                left={
                    <div className="flex gap-1">
                        <HeroSideNavTrigger />
                        <ViewSwitcher pageKey={pageKey} />
                    </div>
                }
                center={<DIDatePicker unit="month" />}
                right={<Filters isPractitionerView={isPractitionerView} />}
            />

            <div className="overflow-hidden" data-test="surgeon-scheduler-page">
                <Show condition={isPractitionerView} fallback={<LocationTable isLoading={isLoading} />}>
                    <PractitionerTable isLoading={isLoading} />
                </Show>
            </div>
        </div>
    )
}

export default SurgeonSchedulerPage
