import Form from '@rjsf/core'
import { RJSFSchema, UiSchema } from '@rjsf/utils'
import { customizeValidator } from '@rjsf/validator-ajv8'
import Ajv2020 from 'ajv/dist/2020'
import localizer from 'ajv-i18n'
import { ComponentProps, memo, ReactNode } from 'react'

import { useStore } from '~/store/store'
import { RuleInstanceParameters } from '~/store/utils/blockEvaluation'

import { CustomArrayFieldTemplate } from './templates/CustomArrayFieldTemplate'
import { CustomObjectFieldTemplate } from './templates/CustomObjectFieldTemplate'
import { customFormats, InputTemplate } from './templates/InputTemplate'
import { CustomSelectWidget } from './widgets/CustomSelectWidget'

const validator = customizeValidator({ AjvClass: Ajv2020, customFormats }, localizer.nb)

const uiSchema: UiSchema = {
    'ui:globalOptions': {
        label: false,
    },
    'ui:hideError': true,
}

type Props = {
    formData?: unknown
    schema?: RJSFSchema
    /**
     * Note: using children within this component will cause the form to be rendered without a submit button.
     * */
    children?: ReactNode

    onSubmit: () => void
    onChange?: (data: RuleInstanceParameters) => void
}

const CustomFormComponent = ({ formData, schema = {}, onSubmit, onChange, children }: Props) => {
    const addToast = useStore(state => state.toaster.actions.addToast)

    const onError: ComponentProps<typeof Form>['onError'] = errors => {
        addToast({ type: 'error', message: errors.map(error => error.message).join(', ') })
    }

    const handleChange: ComponentProps<typeof Form>['onChange'] = data => {
        onChange?.(data.formData as RuleInstanceParameters)
    }

    return (
        <Form
            formData={formData}
            onError={onError}
            onSubmit={onSubmit}
            schema={schema}
            validator={validator}
            uiSchema={uiSchema}
            showErrorList={false}
            onChange={handleChange}
            widgets={{
                SelectWidget: CustomSelectWidget,
            }}
            templates={{
                BaseInputTemplate: InputTemplate,
                ArrayFieldTemplate: CustomArrayFieldTemplate,
                ObjectFieldTemplate: CustomObjectFieldTemplate,
            }}
            noHtml5Validate
        >
            {children}
        </Form>
    )
}

export const CustomForm = memo(CustomFormComponent)
