import { useState } from 'react'

import { Button } from '~/components'

import { CreatePeriodDialog } from '../../shared/CreatePeriodDialog'
import { ListIllustration } from './ListIllustration'

export function CallToAction() {
    const [isCreatePeriodDialogOpen, setIsCreatePeriodDialogOpen] = useState(false)

    return (
        <div className="flex h-full flex-col items-center justify-center gap-6">
            <ListIllustration />
            <div className="flex flex-col gap-2 text-center">
                <h1 className="text-2xl">Ingen perioder opprettet ennå</h1>
                <p className="text-gray-500">Lag din første tidsperiode</p>
            </div>
            <Button onClick={() => setIsCreatePeriodDialogOpen(true)}>Legg til en periode</Button>
            <CreatePeriodDialog open={isCreatePeriodDialogOpen} onClose={() => setIsCreatePeriodDialogOpen(false)} />
        </div>
    )
}
