import { memo, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = JSX.IntrinsicElements['button']
const colorTypeDefinitions = {
    practitioner: '#c1cef6',
    room: '#E6E9E8',
    room_conflict: '#fca5a5',
} as const
type ColorTypeKeys = keyof typeof colorTypeDefinitions

// This is workaround for allowing to pass any string but also give intellisense for predefined values
type ColorType = ColorTypeKeys | (string & { _?: never })

type TagProps = {
    fillType: 'none' | 'some' | 'all'
    whiteText?: boolean
    colorType: ColorType
    isLoading?: boolean
    children?: ReactNode
}

const baseStyles = 'w-full min-w-[64px] cursor-pointer rounded border py-0.5 font-medium'

export const ButtonTag = memo(
    ({ colorType, fillType, className, children, isLoading = false, style, whiteText = false, ...buttonProps }: TagProps & ButtonProps) => {
        const colorTypeValue = colorType in colorTypeDefinitions ? colorTypeDefinitions[colorType as ColorTypeKeys] : colorType

        const backgroundColor = {
            none: '',
            some: `linear-gradient(165deg, ${colorTypeValue} 50%, white 50%)`,
            all: colorTypeValue,
        }

        return (
            <button
                style={{
                    ...style,
                    border: `1px solid ${colorTypeValue}`,
                    background: backgroundColor[fillType],
                }}
                className={twMerge(
                    `${baseStyles} disabled:pointer-events-none disabled:opacity-50 ${isLoading ? 'animate-pulse' : ''}`,
                    `${!!buttonProps.onClick ? 'active:scale-95' : ''}`,
                    whiteText ? 'text-white' : 'text-heroBlack',
                    className
                )}
                {...buttonProps}
            >
                {children}
            </button>
        )
    }
)
