import { Dayjs } from 'dayjs'
import uniqBy from 'lodash/uniqBy'
import { useState } from 'react'

import { createBatchPractitionerSchedule, createBatchPractitionerScheduleLocation, deleteBatchPractitionerScheduleLocation } from '~/store/di-entity.api'
import { Practitioner, selectGetPractitionerScheduleLocations, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format, getOnlyDate } from '~/utils/extendedDayjs'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs/config'

export function useAssignPractitionerToCells(selectedCells: { locationId: number; date: Dayjs }[]) {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const [practitionerIdsBeingAdded, setPractitionerIdsBeingAdded] = useState<Practitioner['id'][]>([])

    async function addPractitioner(practitioner: Practitioner) {
        setPractitionerIdsBeingAdded(prev => [...prev, practitioner.id])

        const dates = uniqBy(
            selectedCells.map(cell => cell.date),
            date => getOnlyDate(date)
        )

        // Ensure that the practitioner has a schedule for each selected date
        const datesWithoutSchedule = dates.filter(date => {
            const schedules = getPractitionerSchedules.byDate(date)
            return !schedules.some(schedule => schedule.practitioner_id === practitioner.id)
        })

        await createBatchPractitionerSchedule(
            datesWithoutSchedule.map(date => ({
                practitioner_id: practitioner.id,
                start_time: format(date),
                end_time: format(date.add(1, 'day')),
                tzid: HOSPITAL_TIMEZONE,
            }))
        )

        // Assign a location to all practitionerSchedules that don't have it, yet
        // We get a fresh state from the store, which was updated by the above createPractitionerSchedule
        const getSchedules = selectGetPractitionerSchedules(useStore.getState())

        // Loop through all selected cells and assign the practitioner to the location, if not already assigned
        await createBatchPractitionerScheduleLocation(
            selectedCells.flatMap(cell => {
                const cellSchedulesForPractitioner = getSchedules.byDate(cell.date).filter(schedule => schedule.practitioner_id === practitioner.id)

                return cellSchedulesForPractitioner
                    .filter(schedule => {
                        const scheduleLocations = schedule.locations.map(location => location.id)
                        return !scheduleLocations.includes(cell.locationId)
                    })
                    .map(schedule => ({
                        practitioner_schedule_id: schedule.id,
                        location_id: cell.locationId,
                    }))
            })
        )

        setPractitionerIdsBeingAdded(prev => prev.filter(id => id !== practitioner.id))
    }

    return { addPractitioner, practitionerIdsBeingAdded }
}

export function useRemovePractitionerFromCells(selectedCells: { locationId: number; date: Dayjs }[]) {
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)

    async function removePractitioner(practitioner: Practitioner) {
        const schedulesToDelete = selectedCells.map(cell => getPractitionerScheduleLocations.byCellAndPractitioner(cell, practitioner)).flat()

        try {
            await deleteBatchPractitionerScheduleLocation(schedulesToDelete.map(schedule => schedule.id))
        } catch (error) {
            console.error(error)
        }
    }

    return { removePractitioner }
}
