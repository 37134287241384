import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

import { pages, redirects } from '~/app/routes'
import { useImportCommonData } from '~/hooks/useImportCommonData'
import { useWebSocketConnection } from '~/hooks/useWebSocketUpdates/useWebSocketUpdates'
import { selectLandingPage } from '~/store/slices/featureFlagSlice'
import { useStore } from '~/store/store'

import LoadingScreen from './LoadingScreen'
import { MainLayout } from './MainLayout/MainLayout'
import AdminPanel from './pages/Admin/AdminPanel.page'
import DownloadPage from './pages/Download/Download.page'
import MasterScheduler from './pages/MasterScheduler/MasterScheduler.page'
import NotFoundPage from './pages/NotFound/NotFound.page'
import OperationalPlannerPage from './pages/OperationalPlanner/OperationalPlanner.page'
import PeriodCalendarPage from './pages/PeriodCalendar/PeriodCalendar.page'
import PlanningPeriodsPage from './pages/PlanningPeriods/PlanningPeriods.page'
import SurgeonSchedulerPage from './pages/SurgeonScheduler/SurgeonScheduler.page'
import WaitingListPage from './pages/WaitingList/WaitingList.page'

const AuthenticatedAppContents = () => {
    const featureFlags = useStore(state => state.featureFlags)
    const landingPage = useStore(selectLandingPage)
    const { hasLoadedOnce } = useImportCommonData()

    useWebSocketConnection()

    if (!hasLoadedOnce) {
        return <LoadingScreen message="◦ ◦ ◦ ◦" />
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<MainLayout />}>
                    {landingPage && (
                        <>
                            <Route path="/" element={<Navigate to={landingPage} replace={true} />} />
                            {featureFlags.MASTER_SURGERY_SCHEDULER && (
                                <>
                                    <Route path={pages.MASTER_SURGERY_SCHEDULER.views['master-scheduler'].path} element={<MasterScheduler />} />
                                    {featureFlags.periods && (
                                        <>
                                            <Route path={pages.MASTER_SURGERY_SCHEDULER.views['planning-periods'].path} element={<PlanningPeriodsPage />} />
                                            <Route path={pages.MASTER_SURGERY_SCHEDULER.views['period-calendar'].path} element={<PeriodCalendarPage />} />
                                        </>
                                    )}
                                </>
                            )}
                            {featureFlags.SURGEON_SCHEDULER && (
                                <>
                                    <Route
                                        path={redirects.SURGEON_SCHEDULER}
                                        element={<Navigate to={pages.SURGEON_SCHEDULER.views.locations.path} replace={true} />}
                                    />
                                    {Object.values(pages.SURGEON_SCHEDULER.views).map(({ path }) => (
                                        <Route key={path} path={path} element={<SurgeonSchedulerPage />} />
                                    ))}
                                </>
                            )}
                            {featureFlags.OPERATIONAL_PLANNER && (
                                <>
                                    <Route
                                        path={redirects.OPERATIONAL_PLANNER}
                                        element={<Navigate to={pages.OPERATIONAL_PLANNER.views.calendar.path} replace={true} />}
                                    />
                                    {Object.values(pages.OPERATIONAL_PLANNER.views).map(({ path }) => (
                                        <Route key={path} path={path} element={<OperationalPlannerPage />} />
                                    ))}
                                </>
                            )}
                            <Route path={redirects.WAITING_LIST} element={<Navigate to={pages.WAITING_LIST.views.unscheduled.path} replace={true} />} />
                            {Object.values(pages.WAITING_LIST.views).map(({ path }) => (
                                <Route key={path} path={path} element={<WaitingListPage />} />
                            ))}
                            <Route path={pages.DOWNLOAD.views.download.path} element={<DownloadPage />} />
                            <Route path={pages.ADMIN_PANEL.views['admin-panel'].path} element={<AdminPanel />} />
                        </>
                    )}
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </>
        )
    )
    return <RouterProvider router={router} />
}

export default AuthenticatedAppContents
