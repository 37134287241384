import { ComponentProps, FC } from 'react'

export const SparklesOutlined: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 14C8 12.6739 8.52678 11.4021 9.46447 10.4645C10.4021 9.52678 11.6739 9 13 9C11.6739 9 10.4021 8.47322 9.46447 7.53553C8.52678 6.59785 8 5.32608 8 4C8 5.32608 7.47322 6.59785 6.53553 7.53553C5.59785 8.47322 4.32608 9 3 9C4.32608 9 5.59785 9.52678 6.53553 10.4645C7.47322 11.4021 8 12.6739 8 14Z"
                fill="#F4F0FE"
                stroke="#F4F0FE"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.665 18.33C15.665 17.6232 15.9458 16.9453 16.4456 16.4456C16.9453 15.9458 17.6232 15.665 18.33 15.665C17.6232 15.665 16.9453 15.3842 16.4456 14.8844C15.9458 14.3847 15.665 13.7068 15.665 13C15.665 13.7068 15.3842 14.3847 14.8844 14.8844C14.3847 15.3842 13.7068 15.665 13 15.665C13.7068 15.665 14.3847 15.9458 14.8844 16.4456C15.3842 16.9453 15.665 17.6232 15.665 18.33Z"
                fill="#F4F0FE"
                stroke="#F4F0FE"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </svg>
)
