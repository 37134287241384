import { Tag } from '~/components'
import { DurationRuleEvaluation, isRoomDurationBasedRuleEvaluation } from '~/store/selectors'
import { formatDuration } from '~/utils/extendedDayjs'

type Props = {
    data_test?: string
    as_tag: boolean
    evaluation: DurationRuleEvaluation
}

export const AvailabilityByTime = ({ data_test, as_tag, evaluation }: Props) => {
    const what = isRoomDurationBasedRuleEvaluation(evaluation) ? 'stuetid' : 'knivtid'
    const duration = evaluation.remaining ? formatDuration(evaluation.remaining.toISOString()) : '?'
    const text = `Ledig ${what}: ${duration}`

    if (as_tag) {
        return (
            <Tag color="no-bg" data-test={`${data_test}-available-slots-duration`}>
                {text}
            </Tag>
        )
    }

    return (
        <span data-test={`${data_test}-available-slots-duration`} className="font-medium text-diBlue-700">
            {text}
        </span>
    )
}
