import { RefObject } from 'react'

import { CustomForm } from '~/components'
import { selectGetRuleDefinitions } from '~/store/selectors'
import { useStore } from '~/store/store'
import { RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/blockEvaluation'

type Props = {
    ruleId: RuleDefinitionId
    parameters: RuleInstanceParameters
    setParameters: (parameters: RuleInstanceParameters) => void
    submitButtonRef: RefObject<HTMLButtonElement>
    onSubmit: () => void
}

export const RuleEditor = ({ ruleId, parameters, setParameters, submitButtonRef, onSubmit }: Props) => {
    const getRuleDefinitions = useStore(selectGetRuleDefinitions)
    const ruleSchema = getRuleDefinitions.byRuleId(ruleId)?.param_schema

    return (
        <CustomForm schema={ruleSchema} formData={parameters} onChange={setParameters} onSubmit={onSubmit}>
            <button ref={submitButtonRef} type="submit" hidden />
        </CustomForm>
    )
}
