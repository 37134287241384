import { ComponentProps } from 'react'

import { SidebarHeader, SidebarMenu, SidebarMenuItem } from './HeroSideNav'

export type HeroSideBarHeaderProps = {
    title: string
    url: string
    Icon: React.FC<ComponentProps<'svg'>>
    CollapsedIcon: React.FC<ComponentProps<'svg'>>
}

export const HeroSideBarHeader = ({ title, url, Icon, CollapsedIcon }: HeroSideBarHeaderProps) => {
    return (
        <SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem>
                    <a href={url} aria-label={title}>
                        <Icon height={24} className="m-1 group-data-[collapsible=icon]:hidden" />
                        <CollapsedIcon width={24} height={24} className="m-1 group-data-[collapsible=]:hidden" />
                    </a>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarHeader>
    )
}
