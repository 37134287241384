import { useCallback, useState } from 'react'

import { createDepartmentPractitionerAssignment, createPractitioner } from '~/store/di-entity.api'
import { selectEntities } from '~/store/selectors'
import { useStore } from '~/store/store'

import AddNewButton from './AddNewButton'
import { EntityList } from './EntityList'
import PractitionerListItem from './PractitionerListItem'

type Props = {
    departmentId: number | 'uncategorized'
}

const PractitionersList = ({ departmentId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const entities = useStore(selectEntities)
    const practitioners = entities.practitioners
        .filter(practitioner =>
            departmentId === 'uncategorized'
                ? practitioner.departments.length === 0
                : practitioner.departments.some(department => department.id === departmentId)
        )
        .sort((a, b) => a.short_name.localeCompare(b.short_name))

    const onAddNew = useCallback(async () => {
        if (isCreating) {
            return
        }

        const roomCode = window.prompt('Enter new practitioner name (short_name)')

        if (!roomCode) {
            return
        }

        setIsCreating(true)

        const newPractitioner = (await createPractitioner({ short_name: roomCode })).data

        if (newPractitioner && departmentId !== 'uncategorized') {
            await createDepartmentPractitionerAssignment({
                department_id: departmentId,
                practitioner_id: newPractitioner.id,
            })
        }

        setIsCreating(false)
    }, [isCreating, departmentId])

    return (
        <EntityList title="Practitioners">
            {practitioners.map(practitioner => (
                <PractitionerListItem key={practitioner.id} practitioner={practitioner} />
            ))}
            <AddNewButton onClick={onAddNew} isCreating={isCreating} entityName="Practitioner" />
        </EntityList>
    )
}

export default PractitionersList
