/**
 * This file is a generic fetch client that does not know about React or store life cycles.
 * It should be treated as simply a convenience wrapper around the generated API client.
 */
import createClient from 'openapi-fetch'

import env from '~/env'

import { components, paths } from './generated-optimus-api-types'
import { apiRefreshingMiddleware } from './unauthorizedHandlingMiddleware'

export const optimusBaseClient = createClient<paths>({
    baseUrl: `${env.VITE_API_BASE_URL}/optimus`,
    credentials: 'include',
})
optimusBaseClient.use(apiRefreshingMiddleware)

export type OptimusSchemas = components['schemas']

/**
 * The client is a wrapper around .GET, .POST and so on methods that help bind the path and query parameters to the response objects.
 * This allows us to use to instantiate generic "import*" methods in the store.
 */
const optimusClient = {
    // GET Operations
    getSurgeonAssignmentSuggestionStatus: async (job_id: string) => await optimusBaseClient.GET('/surgeon-assignment/', { params: { query: { job_id } } }),

    getSurgeonAssignmentSuggestionResult: async (job_id: string) =>
        await optimusBaseClient.GET('/surgeon-assignment/result', { params: { query: { job_id } } }),

    getBlockScheduleUtilization: async (block_schedule_id: number) =>
        await optimusBaseClient.GET('/plan-metrics/block_schedules/{block_schedule_id}/estimated_utilization', {
            params: { path: { block_schedule_id: block_schedule_id } },
        }),

    getBlockScheduleDuration: async (block_schedule_id: number) =>
        await optimusBaseClient.GET('/plan-metrics/block_schedules/{block_schedule_id}/estimated_duration', {
            params: { path: { block_schedule_id: block_schedule_id } },
        }),

    // POST Operations
    createSurgeonAssignmentSuggestion: async (data: OptimusSchemas['SurgeonAssignmentCreate']) =>
        await optimusBaseClient.POST('/surgeon-assignment/', { body: data }),
} as const

export default optimusClient
