import { Show } from '~/components'
import { PlanningPeriod } from '~/store/selectors'

import PlanningPeriodListItem from './PlanningPeriodListItem/PlanningPeriodListItem'

type Props = {
    planningPeriods: PlanningPeriod[]
}

const EmptyContent = () => (
    <div className="flex items-center justify-center">
        <p className="italic text-gray-500">Ingen planleggingsperioder</p>
    </div>
)

export default function PlanningPeriodList({ planningPeriods }: Props) {
    return (
        <Show condition={planningPeriods.length > 0} fallback={<EmptyContent />}>
            <div className="flex flex-col gap-6">
                {planningPeriods.map(period => (
                    <PlanningPeriodListItem planningPeriod={period} key={period.id} />
                ))}
            </div>
        </Show>
    )
}
