// We disable no-use-before-define because we are building recursive types in this file.
/* eslint-disable no-use-before-define */
import { SimplifyDeep } from 'type-fest'

type Id = number | string

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MinimalEntity = Record<string, any>

type Entities = Record<string, MinimalEntity[]>
type SchemaIdValue = string | string[] | number | number[] | undefined | null
type SchemaIdLookupKey = SchemaIdValue | ((entity: MinimalEntity) => SchemaIdValue)

type HasOneSchema = {
    type: 'hasOne'
    localKey: SchemaIdLookupKey

    fromEntity: string
    foreignKey: SchemaIdLookupKey
}

type HasManySchema = {
    type: 'hasMany'
    localKey: SchemaIdLookupKey

    fromEntity: string
    foreignKey: SchemaIdLookupKey
}

type HasManyThrough = {
    type: 'hasManyThrough'
    localKey: SchemaIdLookupKey

    throughEntity: string
    throughForeignKey: SchemaIdLookupKey
    throughLocalKey: SchemaIdLookupKey

    fromEntity: string
    foreignKey: SchemaIdLookupKey

    extraFields?: string[]
}

type AnyRelationship = HasManySchema | HasOneSchema | HasManyThrough

type Relationship<T> = {
    type: AnyRelationship['type']
    fromEntity: T
}
type EntityRelationships<T> = Record<string, Relationship<T>>

type DenormalizeEntity<
    SCH extends Record<keyof ENT, EntityRelationships<keyof SCH>>,
    ENT extends Record<keyof SCH, MinimalEntity[]>,
    RELS extends EntityRelationships<keyof ENT>,
> = {
    [KEY in keyof RELS]: RELS[KEY]['type'] extends 'hasOne'
        ? (ENT[RELS[KEY]['fromEntity']][number] & DenormalizeEntity<SCH, ENT, SCH[RELS[KEY]['fromEntity']]>) | undefined
        : Array<ENT[RELS[KEY]['fromEntity']][number] & DenormalizeEntity<SCH, ENT, SCH[RELS[KEY]['fromEntity']]>>
}

type DenormalizeData<SCH extends Record<keyof ENT, EntityRelationships<keyof ENT>>, ENT extends Record<keyof SCH, MinimalEntity[]>> = SimplifyDeep<{
    [KEY in keyof SCH]: Array<ENT[KEY][number] & DenormalizeEntity<SCH, ENT, SCH[KEY]>>
}>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValue(entity: MinimalEntity, path: SchemaIdLookupKey): SchemaIdValue {
    if (typeof path === 'function') {
        return path(entity)
    } else if (Array.isArray(path)) {
        return path.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), entity) as unknown as SchemaIdValue
    } else {
        if (path && path in entity) {
            return entity[path] as SchemaIdValue
        }
    }
}

function keyBy(arr: MinimalEntity[], key: SchemaIdLookupKey) {
    const result = {} as Record<Id, MinimalEntity | MinimalEntity[]>
    for (const item of arr) {
        const keyValue = getValue(item, key)
        if (Array.isArray(keyValue)) {
            keyValue.forEach(kv => {
                if (kv !== null && kv !== undefined) {
                    if (!result[kv]) {
                        result[kv] = []
                    }
                    result[kv]?.push(item)
                }
            })
        } else if (keyValue !== null && keyValue !== undefined) {
            result[keyValue] = item
        }
    }
    return result
}

function groupBy(arr: MinimalEntity[], key: SchemaIdLookupKey) {
    const result = {} as Record<Id, MinimalEntity[]>
    for (const item of arr) {
        const keyValue = getValue(item, key)
        if (Array.isArray(keyValue)) {
            keyValue.forEach(kv => {
                if (kv !== null && kv !== undefined) {
                    if (!result[kv]) {
                        result[kv] = []
                    }
                    result[kv]?.push(item)
                }
            })
        } else if (keyValue !== null && keyValue !== undefined) {
            if (!result[keyValue]) {
                result[keyValue] = []
            }
            result[keyValue]?.push(item)
        }
    }
    return result
}

const keyByCache = new Map<string, Record<Id, MinimalEntity>>()
function keyByCached(entities: Entities, entityName: keyof Entities, key: SchemaIdLookupKey) {
    const cacheKey = `${entityName}.${key}`
    if (!keyByCache.has(cacheKey)) {
        keyByCache.set(cacheKey, keyBy(entities[entityName]!, key))
    }
    return keyByCache.get(cacheKey)!
}

const groupByCache = new Map<string, Record<Id, MinimalEntity[]>>()
function groupByCached(entities: Entities, entityName: string, key: SchemaIdLookupKey) {
    const cacheKey = `${entityName}.${key}`
    if (!groupByCache.has(cacheKey)) {
        groupByCache.set(cacheKey, groupBy(entities[entityName]!, key))
    }
    return groupByCache.get(cacheKey)!
}

/**
 * Denormalize the entities based on the schema.
 *
 * Note for performance: The performance intensive operation in this function is just the cloning of the input entities.
 * Everything else is very well cached and optimized. If more performance is needed, I suggest to look into pre-cloning
 * the entities before calling this function and skipping the cloning here.
 *
 * Currently casts all IDs to numbers, this is a temporary fix until we fix the external_id type for the Department.
 */
export function denormalize<SCH extends Record<keyof ENT, EntityRelationships<keyof SCH>>, ENT extends Record<keyof SCH, MinimalEntity[]>>(
    inputSchema: SCH,
    inputEntities: ENT
): DenormalizeData<SCH, ENT> {
    const entities = { ...inputEntities }
    for (const key in entities) {
        entities[key] = entities[key].map(entity => ({ ...entity })) as ENT[Extract<keyof ENT, string>]
    }

    keyByCache.clear()
    groupByCache.clear()

    for (const [entityName, relationship] of Object.entries(inputSchema) as [keyof SCH, Relationship<keyof SCH>][]) {
        for (const [relationshipName, schema] of Object.entries(relationship) as unknown as [string, AnyRelationship][]) {
            if (schema.type === 'hasOne') {
                const { fromEntity, foreignKey, localKey } = schema
                const index = keyByCached(entities, fromEntity, foreignKey)

                for (const entity of entities[entityName]) {
                    const localValue = getValue(entity, localKey)

                    if (!localValue) {
                        entity[relationshipName] = null
                        continue
                    }

                    if (Array.isArray(localValue)) {
                        entity[relationshipName] = localValue.map(lv => index[lv]).filter(Boolean) ?? null
                    } else {
                        entity[relationshipName] = index[localValue] ?? null
                    }
                }
            }

            if (schema.type === 'hasMany') {
                const { fromEntity, foreignKey, localKey } = schema
                const index = groupByCached(entities, fromEntity, foreignKey)
                for (const entity of entities[entityName]) {
                    const localValue = getValue(entity, localKey)

                    if (!localValue) {
                        entity[relationshipName] = []
                        continue
                    }

                    if (Array.isArray(localValue)) {
                        entity[relationshipName] = localValue.reduce((acc, lv) => acc.concat(index[lv] ?? []), [] as MinimalEntity[])
                    } else {
                        entity[relationshipName] = index[localValue] ?? []
                    }
                }
            }

            if (schema.type === 'hasManyThrough') {
                const { fromEntity, foreignKey, localKey, throughEntity, throughForeignKey, throughLocalKey } = schema
                const throughIndex = groupByCached(entities, throughEntity, throughLocalKey)
                const targetIndex = keyByCached(entities, fromEntity, foreignKey)
                for (const entity of entities[entityName]) {
                    const localValue = getValue(entity, localKey)

                    if (!localValue) {
                        entity[relationshipName] = []
                        continue
                    }

                    const throughRecords = Array.isArray(localValue)
                        ? localValue.reduce((acc, lv) => acc.concat(throughIndex[lv] ?? []), [] as MinimalEntity[])
                        : (throughIndex[localValue] ?? [])

                    const relatedEntities = []

                    for (const record of throughRecords) {
                        const targetKeys = getValue(record, throughForeignKey)

                        if (!targetKeys) {
                            continue
                        }

                        if (Array.isArray(targetKeys)) {
                            for (const targetKey of targetKeys) {
                                const targetEntity = targetIndex[targetKey]
                                if (targetEntity) {
                                    relatedEntities.push(targetEntity)
                                }
                            }
                        } else {
                            const targetEntity = targetIndex[targetKeys]
                            if (targetEntity) {
                                relatedEntities.push(targetEntity)
                            }
                        }
                    }

                    entity[relationshipName] = relatedEntities
                }
            }
        }
    }

    return entities as unknown as DenormalizeData<SCH, ENT>
}

/**
 * This is a helper function to help write the schema with better type inference.
 */
export function relation<const T extends AnyRelationship>(schema: T): T {
    return schema
}
