import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { DipsSurgeryType, HospitalSurgeryType, selectEntities } from './entities'

/**
 * Used in the admin-panel to display an overview of the surgery types.
 * For other use cases, use the selectSurgeryTypeGroup selector as this returns the resolved surgery type groups (including surgery types).
 * @Returns The surgery types categorized as orphan, unimported, and deprecated.
 */
export const selectSurgeryTypes = createSelector(selectEntities, ({ dipsSurgeryTypes, hospitalSurgeryTypes }) => {
    const dipsSurgeryTypesById = keyBy(dipsSurgeryTypes, dipsSurgeryType => Number(dipsSurgeryType.id))
    const hospitalSurgeryTypesById = keyBy(hospitalSurgeryTypes, 'external_id')

    const orphanDipsSurgeryTypes: DipsSurgeryType[] = [] // common surgery types
    let unimportedDipsSurgeryTypes: DipsSurgeryType[] = [] // only in dipsSurgeryTypes
    const deprecatedHospitalSurgeryTypes: HospitalSurgeryType[] = [] // only in hospitalSurgeryTypes

    hospitalSurgeryTypes.forEach(hospitalSurgeryType => {
        const commonSurgeryType = dipsSurgeryTypesById[hospitalSurgeryType.external_id]
        if (commonSurgeryType) {
            orphanDipsSurgeryTypes.push(commonSurgeryType)
        } else {
            deprecatedHospitalSurgeryTypes.push(hospitalSurgeryType)
        }
    })

    unimportedDipsSurgeryTypes = dipsSurgeryTypes.filter(dipsSurgeryType => !hospitalSurgeryTypesById[Number(dipsSurgeryType.id)])

    return {
        orphanDipsSurgeryTypes,
        unimportedDipsSurgeryTypes,
        deprecatedHospitalSurgeryTypes,
    }
})
