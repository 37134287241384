import { PractitionerOut } from '~/clients/derived-api-types'
import { deletePractitioner, updatePractitioner } from '~/store/di-entity.api'

import { EntityItem } from './EntityList'

type Props = {
    practitioner: PractitionerOut
}

const PractitionerListItem = ({ practitioner }: Props) => {
    function onDelete() {
        void deletePractitioner(practitioner.id)
    }

    function onRename() {
        const roomCode = window.prompt('Enter new practitioner name (short_name)', practitioner.short_name)

        if (!roomCode) {
            return
        }

        void updatePractitioner(practitioner.id, { short_name: roomCode })
    }

    return (
        <EntityItem onDelete={onDelete} onRename={onRename}>
            {practitioner.short_name}
        </EntityItem>
    )
}

export default PractitionerListItem
