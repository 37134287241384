import { RefObject, useEffect, useState } from 'react'

type Props = {
    elementRef: RefObject<HTMLDivElement | null>

    rowsOffset?: number
    rowHeight?: number
}

export const useVirtualizeList = ({ elementRef, rowsOffset = 2, rowHeight = 65 }: Props) => {
    const [indexesOfVisibleRows, setIndexesOfVisibleRows] = useState<number[]>([])

    useEffect(() => {
        const dataGrid = elementRef.current

        if (dataGrid) {
            const handleScroll = () => {
                const scrollTop = dataGrid.scrollTop
                const visibleRows = Math.floor(dataGrid.clientHeight) / rowHeight

                const start = Math.floor(scrollTop / rowHeight) - rowsOffset
                const end = start + visibleRows + rowsOffset

                // set the indexes of the visible rows using both start and end values
                setIndexesOfVisibleRows(Array.from({ length: end - start }, (_, i) => start + i))
            }

            handleScroll()

            const resizeObserver = new ResizeObserver(handleScroll)

            dataGrid.addEventListener('scroll', handleScroll, true)
            resizeObserver.observe(dataGrid)

            return () => {
                dataGrid.removeEventListener('scroll', handleScroll, true)
                resizeObserver.disconnect()
            }
        }
    }, [elementRef, rowHeight, rowsOffset])

    return indexesOfVisibleRows
}
