import { Duration } from 'dayjs/plugin/duration'

import { CapacityRule, CapacityRuleTypes } from '~/store/selectors'
import { getKnifeTime } from '~/utils/dips'

import { newDurationBasedRule } from '../durationBasedRule'

const marginInMinutes = 30

export function maxKnifeTimeRule(maxDurations: Duration): CapacityRule {
    return newDurationBasedRule(CapacityRuleTypes.KnifeTimeBased, null, maxDurations, getKnifeTime, marginInMinutes)
}
