import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { PractitionerStatusDefinition, selectEntities } from '~/store/selectors'

export const selectGetPractitionerStatusDefinitions = createSelector(selectEntities, ({ practitionerStatusDefinitions }) => {
    const byCode = keyBy(practitionerStatusDefinitions, 'code')

    return {
        byCode: (code: string): PractitionerStatusDefinition | null => byCode[code] ?? null,
    }
})
