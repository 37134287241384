import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { WaitingListItem } from '~/app/pages/WaitingList/shared/columns'
import { DipsSchemas } from '~/clients/dips-client'

import { day } from '../extendedDayjs'

export type MinimalScheduledSurgery = DipsSchemas['MinimalScheduledSurgeryResult']

function getDayjsDuration(duration: string | undefined): Duration {
    return duration ? dayjs.duration(duration) : dayjs.duration(0, 'minutes')
}

export function getRoomDuration(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedDuration)
}

export function getKnifeTime(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedProcedureDuration)
}

export const sortByOrderedDate = (surgery1: WaitingListItem, surgery2: WaitingListItem) =>
    day(surgery1.surgeryOrderDetails?.orderedDate)?.unix() - day(surgery2.surgeryOrderDetails?.orderedDate).unix()
