import clsx from 'clsx'
import { motion } from 'framer-motion'

import { ExclamationCircleOutlined } from '~/icons'

type Props = {
    label: string
    error?: string
    defaultValue?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    id: string
    disabled?: boolean
}

export const BookingFormInput = ({ label, error, defaultValue, onChange, id, disabled }: Props) => (
    <div className="relative h-14">
        <label className="grid max-w-[332px] grid-cols-[1fr_132px] items-center justify-between gap-4 text-gray-500">
            {label}
            <input
                type="time"
                id={id}
                name={id}
                defaultValue={defaultValue}
                data-test={id}
                className={clsx('rounded-lg border p-1 font-normal outline-none', {
                    'border-red-500': error,
                    'bg-gray-200': disabled,
                })}
                onChange={onChange}
                disabled={disabled}
            />
        </label>
        {error && (
            <motion.p
                initial={{ opacity: 0, y: -16 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: 'linear' }}
                className="absolute bottom-0 right-0 flex gap-0.5 text-xs text-red-500"
            >
                <ExclamationCircleOutlined height={20} />
                {error}
            </motion.p>
        )}
    </div>
)
