import { Button, HeroDialog } from '~/components'
import { deletePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'

type Props = {
    id: number
    open: boolean
    onClose: () => void
}

export function DeleteDialog({ id, open, onClose }: Props) {
    const addToast = useStore(state => state.toaster.actions.addToast)
    const planningPeriod = useStore(selectPlanningPeriods).byId(id)

    async function submit() {
        await deletePlanningPeriod(id)
        addToast({ type: 'success', message: 'Periode slettet' })
        onClose()
    }

    return (
        <HeroDialog isOpen={open} onCloseDialog={onClose} title={`Fjern ${planningPeriod?.name || 'periode'}`}>
            <div className="flex flex-col gap-4">
                <p className="text-md max-w-sm">
                    Er du sikker? Dette vil slette perioden og skjule periodens planer fra planleggingsssiden. Planene blir ikke slettet fra kapasitet og kan
                    legges til i en annen periode senere.
                </p>
                <div className="flex items-center justify-end gap-4">
                    <Button color="white" onClick={onClose} data-test="cancel-deletion-button">
                        Avbryt
                    </Button>
                    <Button color="delete" onClick={submit} data-test="confirm-deletion-button">
                        Slett
                    </Button>
                </div>
            </div>
        </HeroDialog>
    )
}
