import isEqual from 'lodash/isEqual'
import { KeyboardEvent, MouseEvent, useState } from 'react'

import { isNullish } from '~/utils/guards'

import { ArrowKey, fromCellId, Id, isArrowKey, toCellId } from '../utils'

export type Selections = 'none' | 'single' | 'multiple'

type KeyboardNavigationProps<Row extends { id: Id }, Col extends { id: Id }> = {
    rows: Row[]
    cols: Col[]
    selection?: Selections
}

export type Cursor = {
    row: number
    col: number
}

export type SelectedCells = Set<string>

export const initialCursor: Cursor = { row: -1, col: -1 } as const

export function useCreateKeyboardNavigation<Row extends { id: Id }, Col extends { id: Id }>({
    rows,
    cols,
    selection = 'none',
}: KeyboardNavigationProps<Row, Col>) {
    const [cursor, setCursor] = useState<Cursor>(initialCursor)
    const [selectedCells, setSelectedCells] = useState(() => new Set<string>())

    const setDefaultCursor = () => setCursor(initialCursor)
    const setDefaultSelectedCells = () => setSelectedCells(new Set())

    function setDefault() {
        if (isEqual(cursor, initialCursor) && selectedCells.size === 0) return

        setDefaultCursor()
        setDefaultSelectedCells()
    }

    function onMouseDown(event: MouseEvent, rowIndex: number, colIndex: number) {
        if (selection === 'none') return

        const isMetaKeyPressed = event.metaKey || event.ctrlKey
        const isShiftKeyPressed = event.shiftKey
        const cell = toCellId({ rowIndex, colIndex })
        const multipleSelection = selection === 'multiple'

        if (!multipleSelection || (!isShiftKeyPressed && !isMetaKeyPressed)) {
            setSelectedCells(new Set([cell]))
        } else if (isShiftKeyPressed) {
            setSelectedCells(prev => {
                // if last clicked cell and selected are on the same row then select all cells between them
                // if last clicked cell and selected are on the same column then not select anything
                // if last clicked cell and selected are on different rows and columns then select just move cursor. We don't need to select anything
                // 2 last behaviours are intentional
                const newSelectedCells = new Set(prev)
                const lastCell = Array.from(newSelectedCells).at(-1)

                if (newSelectedCells.size === 0 || !lastCell) {
                    return new Set([cell])
                }

                const { rowIndex: lastRowIndex, colIndex: lastColIndex } = fromCellId(lastCell)

                if (isNullish(lastRowIndex) || isNullish(lastColIndex) || lastRowIndex !== rowIndex) {
                    return new Set([cell])
                }

                for (let i = Math.min(lastColIndex, colIndex); i <= Math.max(lastColIndex, colIndex); i++) {
                    newSelectedCells.add(toCellId({ rowIndex, colIndex: i }))
                }

                return newSelectedCells
            })
        } else if (isMetaKeyPressed) {
            setSelectedCells(prev => {
                const newSelectedCells = new Set(prev)
                newSelectedCells.has(cell) ? newSelectedCells.delete(cell) : newSelectedCells.add(cell)
                return newSelectedCells
            })
        }

        setCursor({ row: rowIndex, col: colIndex })
    }

    function onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
        if (selection === 'none') return

        const { key, metaKey, ctrlKey, shiftKey } = event
        const isShiftKeyPressed = shiftKey
        const isCombinedMetaKeyPressed = metaKey || ctrlKey
        const multipleSelection = selection === 'multiple'

        const keyActions: Record<ArrowKey, () => Cursor> = {
            ArrowUp: () => ({
                row: cursor.row > 0 ? cursor.row - 1 : 0,
                col: cursor.col,
            }),
            ArrowDown: () => ({
                row: cursor.row < rows.length - 1 ? cursor.row + 1 : rows.length - 1,
                col: cursor.col,
            }),
            ArrowLeft: () => ({
                row: cursor.row,
                col: isCombinedMetaKeyPressed ? 0 : cursor.col > 0 ? cursor.col - 1 : 0,
            }),
            ArrowRight: () => ({
                row: cursor.row,
                col: isCombinedMetaKeyPressed ? cols.length - 1 : cursor.col < cols.length - 1 ? cursor.col + 1 : cols.length - 1,
            }),
        }

        if (isArrowKey(key)) {
            event.preventDefault()

            const newCursor = keyActions[key]()
            const newCell = toCellId({ rowIndex: newCursor.row, colIndex: newCursor.col })

            setCursor(newCursor)
            setSelectedCells(prev => {
                if (isShiftKeyPressed && multipleSelection) {
                    const newSelectedCells = new Set(prev)
                    newSelectedCells.add(newCell)
                    return newSelectedCells
                }

                return new Set([newCell])
            })
        }
    }

    return { cursor, selectedCells, setDefault, onMouseDown, onKeyDown } as const
}
