import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import zod from 'zod'

import { useStore } from '~/store/store'
import { cluster } from '~/utils/array'

import { entityTypeImportMap } from './entityTypeImportMap'

export const WebSocketEventSchema = zod.object({
    entity_type: zod.string(),
    id: zod.number(),
    event_type: zod.union([zod.literal('delete'), zod.literal('create'), zod.literal('update')]),
})
type WebSocketEvent = zod.infer<typeof WebSocketEventSchema>

const WebSocketEventsSchema = zod.array(WebSocketEventSchema)

function isEntityEventLike(object: unknown): object is { entity_type: string; id: number } {
    return typeof object === 'object' && object !== null && 'entity_type' in object && 'id' in object
}

function fetchAndApplyUpdatesToStore(events: WebSocketEvent[]) {
    const byEventType = groupBy(events, 'event_type')

    if (byEventType['delete'] && byEventType['delete'].length > 0) {
        useStore.getState().di.actions.removeEntitiesByWebsocketEvent(byEventType['delete'])
    }

    const entitiesToLoad = [...(byEventType['update'] ?? []), ...(byEventType['create'] ?? [])]

    if (entitiesToLoad.length > 0) {
        const entitiesByType = groupBy(entitiesToLoad, 'entity_type')

        const promises = map(entitiesByType, (events, entityType) => {
            const ids = events.map(event => event.id)
            const importCall = entityTypeImportMap[entityType as keyof typeof entityTypeImportMap]

            if (!importCall) {
                console.error(`No import function found for entity type: ${entityType}`)
                return Promise.resolve
            }
            return importCall({ 'id:in': cluster(ids) })
        })

        Promise.all(promises).catch(error => {
            console.error('Failed to update entities:', error)
        })
    }
}

export function addEvents(eventString: string) {
    let event: unknown
    try {
        event = JSON.parse(eventString)
    } catch (error) {
        console.error('Expected JSON for WebSocket event, received:', error)
        return
    }

    // Ignore any events that are completely something else, like the PING/PONG messages
    const validEvents = (Array.isArray(event) ? event : [event]).filter(isEntityEventLike)

    // We do a more thorough runtime check of the event to ensure it matches our schema
    const result = WebSocketEventsSchema.safeParse(validEvents)

    if (result.success === false) {
        console.error(`Invalid WebSocket event:`, event)
        return
    }

    fetchAndApplyUpdatesToStore(result.data)
}
