import { ReactNode } from 'react'

import { useCopyToClipBoard } from '~/hooks/useCopyToClipBoard'

type Props = {
    copyText: string
    children: ReactNode
}

export const CopyToClipboard = ({ copyText, children }: Props) => {
    const { refs, copyToClipboard, CopyNotice } = useCopyToClipBoard({ copyText })

    return (
        <>
            <div ref={refs.setReference} onClick={copyToClipboard} className="cursor-pointer">
                {children}
            </div>

            {CopyNotice}
        </>
    )
}
