/* eslint-disable no-use-before-define */
import { startSpan } from '@sentry/browser'
import { createSelector } from 'reselect'

import { schema } from '../schemas/entityRelationsSchema'
import { DipsEntityKey } from '../slices/dipsSlice'
import { DiEntityKey, StoreState } from '../store'
import { denormalize } from '../utils/denormalize'

const selectNormalizedEntities = createSelector(
    (state: StoreState) => state.dips.entities,
    (state: StoreState) => state.di.entities,
    (dipsEntities, diEntities) => {
        return {
            // DIPS
            dipsDepartments: Object.values(dipsEntities.dipsDepartments.byId),
            dipsResources: Object.values(dipsEntities.dipsResources.byId),
            dipsSurgeryTypes: Object.values(dipsEntities.dipsSurgeryTypes.byId),
            scheduledSurgeries: Object.values(dipsEntities.scheduledSurgeries.byId),
            unScheduledSurgeries: Object.values(dipsEntities.unScheduledSurgeries.byId),

            // Deepinsight
            ageGroups: Object.values(diEntities.ageGroups.byId),
            blockLocks: Object.values(diEntities.blockLocks.byId),
            blockSchedules: Object.values(diEntities.blockSchedules.byId),
            departmentLocationAssignments: Object.values(diEntities.departmentLocationAssignments.byId),
            departmentPractitionerAssignments: Object.values(diEntities.departmentPractitionerAssignments.byId),
            departments: Object.values(diEntities.departments.byId),
            hospitalSurgeryTypeGroupAssociations: Object.values(diEntities.hospitalSurgeryTypeGroupAssociations.byId),
            hospitalSurgeryTypes: Object.values(diEntities.hospitalSurgeryTypes.byId),
            locations: Object.values(diEntities.locations.byId),
            locationSchedules: Object.values(diEntities.locationSchedules.byId),
            planningPeriods: Object.values(diEntities.planningPeriods.byId),
            practitioners: Object.values(diEntities.practitioners.byId),
            practitionerScheduleLocations: Object.values(diEntities.practitionerScheduleLocations.byId),
            practitionerSchedules: Object.values(diEntities.practitionerSchedules.byId),
            practitionerScheduleStatuses: Object.values(diEntities.practitionerScheduleStatuses.byId),
            practitionerServicePeriods: Object.values(diEntities.practitionerServicePeriods.byId),
            practitionerStatusDefinitions: Object.values(diEntities.practitionerStatusDefinitions.byId),
            ruleDefinitions: Object.values(diEntities.ruleDefinitions.byId),
            sections: Object.values(diEntities.sections.byId),
            specialities: Object.values(diEntities.specialities.byId),
            surgeryMetadata: Object.values(diEntities.surgeryMetadata.byId),
            surgeryTypeGroupAgeRestrictions: Object.values(diEntities.surgeryTypeGroupAgeRestrictions.byId),
            surgeryTypeGroupHierarchies: Object.values(diEntities.surgeryTypeGroupHierarchies.byId),
            surgeryTypeGroups: Object.values(diEntities.surgeryTypeGroups.byId),
            surgeryTypeGroupSpecialities: Object.values(diEntities.surgeryTypeGroupSpecialities.byId),
        } satisfies Record<DiEntityKey | DipsEntityKey, unknown>
    }
)

export const selectEntities = createSelector(selectNormalizedEntities, entities => {
    return startSpan({ name: 'selectDenormalizedEntities' }, () => denormalize(schema, entities))
})

export type DenormalizedEntities = ReturnType<typeof selectEntities>

// DIPS
export type DipsSurgeryType = DenormalizedEntities['dipsSurgeryTypes'][number]
export type ScheduledSurgery = DenormalizedEntities['scheduledSurgeries'][number]
export type UnScheduledSurgery = DenormalizedEntities['unScheduledSurgeries'][number]

// Deepinsight
export type AgeGroup = DenormalizedEntities['ageGroups'][number]
export type BlockLock = DenormalizedEntities['blockLocks'][number]
export type BlockSchedule = DenormalizedEntities['blockSchedules'][number]
export type Department = DenormalizedEntities['departments'][number]
export type DepartmentLocationAssignment = DenormalizedEntities['departmentLocationAssignments'][number]
export type DepartmentPractitionerAssignment = DenormalizedEntities['departmentPractitionerAssignments'][number]
export type HospitalSurgeryType = DenormalizedEntities['hospitalSurgeryTypes'][number]
export type HospitalSurgeryTypeGroupAssociation = DenormalizedEntities['hospitalSurgeryTypeGroupAssociations'][number]
export type Location = DenormalizedEntities['locations'][number]
export type LocationSchedule = DenormalizedEntities['locationSchedules'][number]
export type PlanningPeriod = DenormalizedEntities['planningPeriods'][number]
export type Practitioner = DenormalizedEntities['practitioners'][number]
export type PractitionerSchedule = DenormalizedEntities['practitionerSchedules'][number]
export type PractitionerScheduleLocation = DenormalizedEntities['practitionerScheduleLocations'][number]
export type PractitionerScheduleStatus = DenormalizedEntities['practitionerScheduleStatuses'][number]
export type PractitionerStatusDefinition = DenormalizedEntities['practitionerStatusDefinitions'][number]
export type RuleDefinition = DenormalizedEntities['ruleDefinitions'][number]
export type Section = DenormalizedEntities['sections'][number]
export type Speciality = DenormalizedEntities['specialities'][number]
export type SurgeryMetadata = DenormalizedEntities['surgeryMetadata'][number]
export type SurgeryTypeGroup = DenormalizedEntities['surgeryTypeGroups'][number]
export type SurgeryTypeGroupAgeRestriction = DenormalizedEntities['surgeryTypeGroupAgeRestrictions'][number]
export type SurgeryTypeGroupHierarchy = DenormalizedEntities['surgeryTypeGroupHierarchies'][number]
export type SurgeryTypeGroupSpeciality = DenormalizedEntities['surgeryTypeGroupSpecialities'][number]
