import { Button } from '~/components'
import { HeroDropdownItem, HeroDropdownMenu } from '~/hero-ui/stories/HeroDropdownMenu/HeroDropdownMenu'
import { ArrowBackUp, ArrowUp, CheckOutlined, DownOutlined, EditOutlined } from '~/icons'
import { updatePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'

const iconStyles = 'w-4 h-4'

export const PublishPeriodDropdown = () => {
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const period = useStore(selectPlanningPeriods).byDate(selectedDate)
    const addToast = useStore(state => state.toaster.actions.addToast)

    if (!period) return null

    async function togglePublish() {
        if (!period) return

        const is_published = !period.is_published

        await updatePlanningPeriod(period.id, { ...period, is_published })
        addToast({ type: 'success', message: is_published ? 'Perioden er publisert' : 'Perioden er avpublisert' })
    }

    const DropdownContent = () => (
        <div className="w-40">
            <HeroDropdownItem
                option={{
                    title: 'Publiser',
                    isHidden: !!period.is_published,
                    icon: () => <ArrowUp className={iconStyles} />,
                    action: togglePublish,
                }}
            />
            <HeroDropdownItem
                option={{
                    title: 'Avpubliser',
                    isHidden: !period.is_published,
                    icon: () => <ArrowBackUp className={iconStyles} />,
                    action: togglePublish,
                    color: 'diRed-500',
                }}
            />
        </div>
    )

    return (
        <HeroDropdownMenu align="start" className="mt-1 border" dropdownContent={<DropdownContent />}>
            <Button color={period.is_published ? 'primary' : 'white'}>
                <div className="flex items-center gap-2">
                    {period.is_published ? <CheckOutlined className={iconStyles} /> : <EditOutlined className={iconStyles} />}
                    <p>{period.is_published ? 'Publisert' : 'Utkast'}</p>
                    <DownOutlined className="h-4 w-4" />
                </div>
            </Button>
        </HeroDropdownMenu>
    )
}
