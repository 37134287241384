import { ReactNode } from 'react'

import { CheckCircleOutlined, InProgressCircleOutlined, PendingCircleOutlined } from '~/icons'
import { PlanningPeriod } from '~/store/selectors/entities'

import { getProgressStatus, ProgressStatus } from './utils'

const icon: Record<ProgressStatus, ReactNode> = {
    completed: <CheckCircleOutlined className="text-[#30A46C]" />,
    current: <InProgressCircleOutlined className="text-[#1EA7FF]" />,
    future: <PendingCircleOutlined className="text-[#80838D]" />,
}

type Props = {
    planningPeriod: PlanningPeriod
}

export function ProgressIcon({ planningPeriod }: Props) {
    return icon[getProgressStatus(planningPeriod)] ?? null
}
