import { ReactNode } from 'react'

type Props = {
    label: string
    count: number
    maxCount: number
    children?: ReactNode
}

export const ModalElement = ({ label, count, maxCount, children }: Props) => (
    <div className="pointer-events-auto border-b px-3 pb-3 pt-2">
        <div className="flex items-center justify-between">
            <span>{label}</span>
            <span className="text-gray-500">
                {count}/{maxCount}
            </span>
        </div>

        <div className="mt-3 grid grid-cols-3 gap-2">{children}</div>
    </div>
)
