export default function LoadingScreen({ message }: { message: string }) {
    return (
        <div className="di-loader">
            <div className="di-loader__progress-bar">
                <div className="di-loader__circle di-loader__border" />
            </div>
            <p className="di-loader__text">{message}</p>
        </div>
    )
}
