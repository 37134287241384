/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import {
    MaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxCountRuleCreate,
    MaxDurationAndCountRuleCreate,
    MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxDurationOfSurgeriesPerSurgeryTypeGroup,
    MaxDurationRuleCreate,
    MaxKnifeDurationAndCountRuleCreate,
    MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup,
    MaxKnifeDurationRuleCreate,
    MaxWeightOfSurgeriesPerSurgeryTypeGroup,
    MaxWeightRuleCreate,
} from '~/clients/derived-api-types'
import { isNullish } from '~/utils/guards'

export type RuleDefinitionId =
    | 'max_count_of_surgeries_per_patient_group'
    | 'max_weight_of_surgeries_per_patient_group'
    | 'max_duration_of_surgeries_per_patient_group'
    | 'max_duration_and_max_count_of_surgeries_per_patient_group'
    | 'max_knife_time_of_surgeries_per_patient_group'
    | 'max_knife_time_and_max_count_of_surgeries_per_patient_group'

export function isRuleDefinitionId(ruleId: string | undefined | null): ruleId is RuleDefinitionId {
    if (isNullish(ruleId)) {
        return false
    }

    return [
        'max_count_of_surgeries_per_patient_group',
        'max_weight_of_surgeries_per_patient_group',
        'max_duration_of_surgeries_per_patient_group',
        'max_duration_and_max_count_of_surgeries_per_patient_group',
        'max_knife_time_of_surgeries_per_patient_group',
        'max_knife_time_and_max_count_of_surgeries_per_patient_group',
    ].includes(ruleId)
}

type RuleInstanceCreateParameters =
    | MaxCountOfSurgeriesPerSurgeryTypeGroup
    | MaxWeightOfSurgeriesPerSurgeryTypeGroup
    | MaxDurationOfSurgeriesPerSurgeryTypeGroup
    | MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup
    | MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup
    | MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup

export type RuleInstanceParameters = RuleInstanceCreateParameters | undefined | null

export type RuleInstanceCreate =
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | undefined
    | null

export function createRuleInstance(localRuleId: string, ruleParameters: RuleInstanceCreateParameters): RuleInstanceCreate | null {
    switch (localRuleId) {
        case 'max_count_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxCountRuleCreate
        case 'max_weight_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxWeightRuleCreate
        case 'max_duration_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxDurationRuleCreate
        case 'max_duration_and_max_count_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxDurationAndCountRuleCreate
        case 'max_knife_time_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxKnifeDurationRuleCreate
        case 'max_knife_time_and_max_count_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxKnifeDurationAndCountRuleCreate
        default:
            return null
    }
}
