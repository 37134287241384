import { useStore } from '~/store/store'

import Department from './Department'

function useActiveDepartments() {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const departmentsList = useStore(state => state.di.entities.departments.allIds.map(id => state.di.entities.departments.byId[id]).filter(Boolean))

    return departmentsList.filter(department => departmentKey === 'all' || department.id === departmentKey)
}

const Departments = () => {
    const activeDepartments = useActiveDepartments()

    return (
        <div className="flex flex-col gap-2">
            <ul className="flex gap-2">
                {activeDepartments.map(department => {
                    return (
                        <li key={department.id} className="">
                            <Department department={department} />
                        </li>
                    )
                })}
                <li key="uncategorized">
                    <Department department={'uncategorized'} />
                </li>
            </ul>
        </div>
    )
}

export default Departments
