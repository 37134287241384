import { Show } from '~/components'
import { HandshakeOutlined, PendingCircleOutlined, PersonAlertOutlined, PersonCancelOutlined, PersonCheckedOutlined } from '~/icons'

const iconStyles = 'h-4 w-4 shrink-0'

type ClearedStatus = 'notCompleted' | 'completed' | 'warning'
type ConfirmedStatus = 'notCompleted' | 'completed'

const clearedIconAndTooltip = {
    notCompleted: { IconComponent: PersonCancelOutlined, tooltip: 'Ikke klarert av planlegger' },
    completed: { IconComponent: PersonCheckedOutlined, tooltip: 'Klarert av planlegger' },
    warning: { IconComponent: PersonAlertOutlined, tooltip: 'Ikke klarert av planlegger. Trenger oppfølging.' },
}

const confirmedIconAndTooltip = {
    notCompleted: { IconComponent: PendingCircleOutlined, tooltip: 'Pasient ikke bekreftet oppmøte' },
    completed: { IconComponent: HandshakeOutlined, tooltip: 'Pasient bekreftet oppmøte' },
}

const colors = {
    unselected: { backgroundColor: 'bg-neutral-100', lineColor: 'bg-neutral-300', iconColor: 'fill-stone-400' },
    notCompleted: { backgroundColor: 'bg-neutral-100', lineColor: 'bg-neutral-300', iconColor: 'fill-black' },
    completed: { backgroundColor: 'bg-green-100', lineColor: 'bg-green-300', iconColor: 'fill-black' },
    warning: { backgroundColor: 'bg-orange-100', lineColor: 'bg-orange-300', iconColor: 'fill-orange-500' },
}

function getColors(status: ClearedStatus, isSelected: boolean) {
    if (!isSelected) return colors['unselected']
    return colors[status]
}

export type Status = { type: 'cleared'; value: ClearedStatus } | { type: 'confirmed'; value: ConfirmedStatus }

type StatusProps = {
    status: Status
    isSelected?: boolean
    showLine?: boolean
}

export function Status({ status, isSelected = true, showLine = false }: StatusProps) {
    const { type, value } = status
    const { backgroundColor, lineColor, iconColor } = getColors(value, isSelected)

    let result
    if (type === 'cleared') {
        result = clearedIconAndTooltip[value]
    } else {
        result = confirmedIconAndTooltip[value]
    }

    const { IconComponent, tooltip } = result

    return (
        <div className="flex shrink-0 items-center" data-tooltip={tooltip}>
            <div className={`flex h-6 w-6 items-center justify-center rounded-full p-1 ${backgroundColor}`}>
                <IconComponent className={`${iconStyles} ${iconColor}`} />
            </div>
            {showLine && <div className={`h-[1px] w-[2px] ${lineColor}`} />}
        </div>
    )
}

export const getCompletedStatus = (completed: boolean) => (completed ? 'completed' : 'notCompleted')

export type FormattedStatus = 'ny' | 'klarert' | 'bekreftet' // Norwegian to be searchable in the table

type StatusesProps = {
    statuses: FormattedStatus[]
    isScheduledItem: boolean
}

export const Statuses = ({ statuses, isScheduledItem }: StatusesProps) => {
    const isNew = statuses.includes('ny')
    const isCleared = statuses.includes('klarert')
    const isConfirmed = statuses.includes('bekreftet')

    const clearedStatus = isScheduledItem && !isCleared ? 'warning' : getCompletedStatus(isCleared)
    const confirmedStatus = getCompletedStatus(isConfirmed)

    return isNew ? (
        <p className="w-fit px-0.5" data-tooltip="Ny pasient på ventelista">
            Ny
        </p>
    ) : (
        <div className="flex flex-row">
            <Status status={{ type: 'cleared', value: clearedStatus }} showLine={isScheduledItem} />
            <Show condition={isScheduledItem}>
                <Status status={{ type: 'confirmed', value: confirmedStatus }} isSelected={isConfirmed} />
            </Show>
        </div>
    )
}
