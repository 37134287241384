import clsx from 'clsx'
import { memo } from 'react'

import { PractitionerSchedule, selectGetOrderedPractitionerScheduleStatuses, selectGetPractitionerStatusDefinitions } from '~/store/selectors'
import { selectStatusValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'

type Props = {
    schedule: PractitionerSchedule | null
}

const CellScheduleStatusesComponent = ({ schedule }: Props) => {
    const gap = Number(schedule?.statuses.length) >= 4 ? '-space-x-4' : '-space-x-4 2xl:gap-x-0.5 2xl:space-x-0'

    const getStatusDefinitions = useStore(selectGetPractitionerStatusDefinitions)
    const getOrderedScheduleStatuses = useStore(selectGetOrderedPractitionerScheduleStatuses)
    const filteredStatuses = useStore(selectStatusValues)

    const statuses = getOrderedScheduleStatuses.byPractitionerScheduleId(schedule?.id ?? 0)

    return (
        <div className={clsx('flex items-stretch gap-x-0', gap)}>
            {statuses.map(({ status_code, updated_by, updated_at }) => {
                const status = getStatusDefinitions.byCode(status_code)
                if (!status) return null

                const editInfo = `Sist endret av ${updated_by} ${humanizeDate(day(updated_at))}`
                const tooltip = `${status.title}\n${editInfo}`

                return (
                    <ButtonTag
                        key={status.code}
                        data-tooltip={tooltip}
                        tabIndex={-1}
                        colorType={status.color ?? 'gray'}
                        fillType="all"
                        className={clsx('!min-w-[20px] truncate border border-white', {
                            'opacity-30': filteredStatuses.length > 0 && !filteredStatuses.includes(status.code),
                        })}
                    >
                        {status.label}
                    </ButtonTag>
                )
            })}
        </div>
    )
}

export const CellScheduleStatuses = memo(CellScheduleStatusesComponent)
