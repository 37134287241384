import { PillsSelect } from '~/components'
import { Option, selectShortNoticeValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const shortNoticeOptions: Option[] = [{ label: 'Kort varsel', value: 'Kort varsel' }]

export const ShortNoticeFilter = () => {
    const shortNoticeValues = useStore(selectShortNoticeValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'kort-varsel', value, label })
        setSelectedPage(1)
    }

    return <PillsSelect values={shortNoticeValues} options={shortNoticeOptions} onChange={({ value, label }) => handleOnChange(value, label)} />
}
