import { Middleware } from 'openapi-fetch'

import env from '~/env'
import type { useStore as UseStore } from '~/store/store'
import { isNotNullish } from '~/utils/guards'

// load `useStore` lazily because of circular imports
let useStore: typeof UseStore | undefined = undefined
async function getStore(): Promise<typeof UseStore> {
    if (useStore === undefined) {
        useStore = (await import('~/store/store')).useStore
    }
    return useStore
}

export const dipsSessionRevocationMiddleware: Middleware = {
    async onResponse(response) {
        if (response.status === 401) {
            const hasDipsCredentials = (await getStore()).getState().user.dipsApiAccessToken !== null
            if (hasDipsCredentials) {
                const diApiRevocationURL = new URL(env.VITE_API_BASE_URL + '/oauth/revocation')

                try {
                    await fetch(diApiRevocationURL.toString(), {
                        mode: 'cors',
                        credentials: 'include',
                    })
                } catch (err) {
                    console.error(err)
                } finally {
                    window.location.reload()
                }
            }
        }
        return response
    },
}

export const apiRefreshingMiddleware: Middleware = {
    async onResponse(response) {
        if (response.status === 401) {
            const isAuthenticated = isNotNullish((await getStore()).getState().user.apiAccessToken)
            if (isAuthenticated) {
                window.location.reload()
            }
            return response
        }
    },
}
