import { Dayjs, ManipulateType } from 'dayjs'

/** Custom add and subtract functions to handle utc offsets correctly when changing
 * to/from CEST/CET times (i.e. summer time and standard time). Currently, dayjs keeps
 * the utc offset as is when adding/subtracting time, which is incorrect. As a result
 * day.isoWeek() returns the wrong week number.
 */

const cache: Map<string, Dayjs> = new Map()

/**
 * Custom function to fix utc offset bug.
 *
 * Returns a cloned Day.js object with a specified amount of time added.
 * ```
 * dayjs().add(7, 'day')// => Dayjs
 * ```
 * Units are case insensitive, and support plural and short forms.
 *
 * Docs: https://day.js.org/docs/en/manipulate/add
 */
export function add(date: Dayjs, value: number, unit?: ManipulateType): Dayjs {
    const key = `add_${date.valueOf()}_${value}_${unit}`
    const existing = cache.get(key)

    if (existing) {
        return existing
    }

    const newDate = date.add(value, unit)
    const newUtcOffset = newDate.tz().utcOffset()
    const result = newDate.utcOffset(newUtcOffset, true)

    cache.set(key, result)

    return result
}

/**
 * Custom function to fix utc offset bug.
 *
 * Returns a cloned Day.js object with a specified amount of time subtracted.
 * ```
 * dayjs().subtract(7, 'year')// => Dayjs
 * ```
 * Units are case insensitive, and support plural and short forms.
 *
 * Docs: https://day.js.org/docs/en/manipulate/subtract
 */
export function subtract(date: Dayjs, value: number, unit?: ManipulateType): Dayjs {
    const key = `subtract_${date.valueOf()}_${value}_${unit}`
    const existing = cache.get(key)

    if (existing) {
        return existing
    }

    const newDate = date.subtract(value, unit)
    const newUtcOffset = newDate.tz().utcOffset()
    const result = newDate.utcOffset(newUtcOffset, true)

    cache.set(key, result)

    return result
}
