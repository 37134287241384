import { isValidElement, ReactNode } from 'react'

type Props = { label: string; children?: ReactNode | string }

export const BookingInfo = ({ label, children }: Props) => {
    return (
        <div className="grid grid-cols-[120px_1fr]">
            <div className="text-gray-500">{label}</div>
            {isValidElement(children) ? children : <div>{children}</div>}
        </div>
    )
}
