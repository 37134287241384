import { ResolvedPatientGroup } from '~/store/selectors'
import { isNullish } from '~/utils/guards'

export function formatSurgeryTypeGroup(patientGroup?: ResolvedPatientGroup | null): string {
    if (isNullish(patientGroup?.surgeryTypeGroup)) return ''

    return patientGroup.surgeryTypeGroup.display_name
}

// Compare 2 patient groups by alphabetical order of their displayed names
export function compareSurgeryTypeGroups(a?: ResolvedPatientGroup | null, b?: ResolvedPatientGroup | null): number {
    if (isNullish(a) && isNullish(b)) {
        return 0
    }
    if (isNullish(a)) {
        return -1
    }
    if (isNullish(b)) {
        return 1
    }
    // Sort by patient group name. It could be changed later to sort by priority
    return formatSurgeryTypeGroup(a).localeCompare(formatSurgeryTypeGroup(b))
}
