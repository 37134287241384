import { CrossIcon } from '@storybook/icons'
import cn from 'clsx'
import { useEffect, useMemo, useState } from 'react'

import optimusClient from '~/clients/optimus-client'
import { Button, Spinner } from '~/components'
import { CheckOutlined, SparklesOutlined } from '~/icons'
import { selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'

const jobIdKey = 'surgeon-scheduler-job-id'

export const MagicButton = () => {
    const [jobId, setJobId] = useState<string | null>(() => localStorage.getItem(jobIdKey) ?? null)
    const [status, setStatus] = useState<string | null>(null)

    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    // 2025 march is hardcoded in the backend
    const isCorrectDateForMagicButton = selectedDate.year() === 2025 && selectedDate.month() === 2
    const isSingleDepartmentSelected = departmentKey !== 'all'
    // If the magic button was applied once, it should be disabled (even if user edited the schedule)
    const wasAppliedOnce = useMemo(() => {
        const practitionerSchedules = getPractitionerSchedules.byMonth(selectedDate)

        return practitionerSchedules.some(
            schedule =>
                schedule.statuses.some(status => status.generated && status.updated_by === 'hero') ||
                schedule.locations.some(status => status.generated && status.updated_by === 'hero')
        )
    }, [getPractitionerSchedules, selectedDate])

    // on page refresh
    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const { data } = await optimusClient.getSurgeonAssignmentSuggestionStatus(jobId ?? '')
                const newStatus = data?.status ?? null
                setStatus(newStatus)
            } catch (error) {
                console.error('Error fetching status:', error)
            }
        }

        void fetchStatus()
    }, [jobId, selectedDate])

    useEffect(() => {
        let timerId: NodeJS.Timeout

        const pollingCallback = async () => {
            try {
                const { data } = await optimusClient.getSurgeonAssignmentSuggestionStatus(jobId ?? '')
                const newStatus = data?.status ?? null
                setStatus(newStatus)

                if (newStatus === 'failure' || newStatus === 'success') {
                    clearInterval(timerId)
                }
            } catch (error) {
                console.error('Error fetching status:', error)
                clearInterval(timerId)
            }
        }

        if (status === 'pending' || status === 'running') {
            timerId = setInterval(pollingCallback, 1000)
        }

        return () => clearInterval(timerId)
    }, [jobId, selectedDate, status])

    async function requestSuggestion() {
        const { data } = await optimusClient.createSurgeonAssignmentSuggestion({
            year: selectedDate.year(),
            month: selectedDate.month() + 1,
            department_id: departmentKey === 'all' ? null : departmentKey,
        })
        setStatus(data?.status ?? null)
        setJobId(data?.job_id ?? null)
        localStorage.setItem(jobIdKey, data?.job_id ?? '')
    }

    async function applyResults() {
        await optimusClient.getSurgeonAssignmentSuggestionResult(jobId ?? '')
    }

    return (
        <>
            {isCorrectDateForMagicButton && isSingleDepartmentSelected && (
                <Button
                    color="custom"
                    data-test="surgeon-scheduler-magic-wand-button"
                    className={cn('flex items-center justify-between gap-1', {
                        'bg-[#804EFD] pl-2 text-white hover:bg-[#7646ED]': status === 'success' || status === 'pending' || status === null,
                        'bg-[#F4F0FE] pl-2 text-[#804EFD] hover:bg-[#F4F0FE]': status === 'running',
                        'bg-diRed-400 pl-2 text-white hover:bg-diRed-300': status === 'failure',
                    })}
                    disabled={wasAppliedOnce}
                    onClick={async () => {
                        if (status === 'success') {
                            await applyResults()
                            return
                        }
                        await requestSuggestion()
                    }}
                >
                    {(status === null || status === 'pending') && (
                        <>
                            <SparklesOutlined className="h-4 w-4" />
                            Gi forslag
                        </>
                    )}

                    {status === 'running' && (
                        <>
                            <Spinner className="h-4 w-4 fill-[#804EFD]" />
                            <span>Foreslår...</span>
                        </>
                    )}

                    {status === 'success' && (
                        <>
                            <CheckOutlined className="h-4 w-4" />
                            Legg til
                        </>
                    )}

                    {status === 'failure' && (
                        <>
                            <CrossIcon className="mr-0.5 h-3 w-3" />
                            Prøv igjen
                        </>
                    )}
                </Button>
            )}
        </>
    )
}
