import { PillsSelect } from '~/components'
import { selectStatusValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

export const StatusFilter = () => {
    const statusValues = useStore(selectStatusValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const statuses = useStore(state => state.di.entities.practitionerStatusDefinitions)
    const statusOptions = Object.entries(statuses.allIds).map(([, value]) => {
        const status = statuses.byId[value]
        return {
            label: `${status?.title} (${status?.label})`,
            value: `${status?.code}`,
        }
    })

    return (
        <>
            <div className="hidden" data-test="status-filter" />
            <PillsSelect
                title="Aktiviteter"
                values={statusValues}
                options={statusOptions}
                onChange={({ value, label }) => toggleFilteredBy({ type: 'status', value, label })}
            />
        </>
    )
}
