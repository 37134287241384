import * as RadixDialog from '@radix-ui/react-dialog'
import { motion } from 'framer-motion'

import { CloseOutlined } from '~/icons'

export type HeroDialogProps = {
    children: React.ReactNode
    isOpen: boolean
    onCloseDialog: () => void
    title: string
} & RadixDialog.DialogContentProps

// Generic dialog component that can be used to display any content in a dialog
export const HeroDialog = ({ children, isOpen, onCloseDialog, title, ...props }: HeroDialogProps) => {
    return (
        <RadixDialog.Root open={isOpen}>
            <RadixDialog.Portal>
                <RadixDialog.Overlay className="fixed inset-0 bg-black/50" />
                <RadixDialog.Content
                    aria-describedby={title}
                    aria-labelledby={title}
                    onEscapeKeyDown={onCloseDialog}
                    className="fixed left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col rounded bg-white"
                    onPointerDownOutside={onCloseDialog}
                    asChild
                    {...props}
                >
                    <motion.div transition={{ type: 'linear' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} data-test="dialog">
                        {/* // needed for screen readers */}
                        <RadixDialog.Description id={title} hidden />
                        <RadixDialog.DialogTitle hidden>{title}</RadixDialog.DialogTitle>
                        <div className="flex justify-between border-b p-4">
                            <p className="text-base font-bold">{title}</p>
                            <button onClick={onCloseDialog}>
                                <CloseOutlined />
                            </button>
                        </div>
                        <div className="p-4">{children}</div>
                    </motion.div>
                </RadixDialog.Content>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    )
}
