import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { HeroSideBar } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'
import { HeroMainNavContent } from '~/hero-ui/stories/HeroSideNav/HeroSideNavContent'
import { HeroSideBarHeader } from '~/hero-ui/stories/HeroSideNav/HeroSideNavHeader'
import { DILogoCompact, DiLogoOutlined } from '~/icons'
import { selectNavLinks } from '~/store/selectors/selectNavLinks'
import { landingPagesOrdered } from '~/store/slices/featureFlagSlice'
import { useStore } from '~/store/store'
import { analytics } from '~/utils/analytics'

import { getPageKeyByPath } from '../routes'
import { UserMenu } from './UserMenu'

const navHeader = {
    title: 'Home',
    url: '/',
    Icon: DiLogoOutlined,
    CollapsedIcon: DILogoCompact,
}

export const Navigation = () => {
    const navLinks = useStore(selectNavLinks)

    const setActiveViews = useStore(state => state.app.actions.setActiveViews)

    const location = useLocation()

    useEffect(() => {
        // Put location.pathname into the store
        const pageKey = getPageKeyByPath(location.pathname)
        if (pageKey) {
            setActiveViews({ [pageKey]: location.pathname })
            void analytics?.page(pageKey)
        }
    }, [location, setActiveViews])

    const navItems = useMemo(() => {
        return landingPagesOrdered.map(page => {
            return {
                ...navLinks[page],
                isActive: navLinks[page].url === location.pathname,
                items: navLinks[page].items?.map(item => ({ ...item, isActive: item.url === location.pathname })),
            }
        })
    }, [location.pathname, navLinks])

    return (
        <HeroSideBar data-test="navigation-bar">
            <HeroSideBarHeader {...navHeader} />
            <HeroMainNavContent navItems={navItems} />
            <UserMenu />
        </HeroSideBar>
    )
}
