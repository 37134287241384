import { PillsSelect } from '~/components'
import { selectGetLocations } from '~/store/selectors'
import { selectLocationValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

export const LocationFilter = () => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getLocations = useStore(selectGetLocations)
    const locationOptions = getLocations.byDepartment(departmentKey).map(({ id, room_code }) => ({ label: room_code, value: String(id) }))
    const locationValues = useStore(selectLocationValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)

    return (
        <>
            <div className="hidden" data-test="location-filter" />
            <PillsSelect
                title="Stuer"
                values={locationValues}
                options={locationOptions}
                onChange={({ value, label }) => toggleFilteredBy({ type: 'location', value, label })}
            />
        </>
    )
}
