import { createSelector } from 'reselect'

import { AgeGroupCode } from '~/clients/derived-api-types'

import { selectGetAgeGroups } from './ageGroups'
import { AgeGroup } from './entities'
import { ResolvedSurgeryTypeGroup, selectSurgeryTypeGroup } from './surgeryTypeGroups'

export type PatientGroupConstraints = {
    patient_group_id?: string
    age_group?: AgeGroupCode | null
}

export type ResolvedPatientGroup = {
    surgeryTypeGroup: ResolvedSurgeryTypeGroup | null
    ageGroup: AgeGroup | null
}

export function makePatientGroupKey(patientGroup: ResolvedPatientGroup): string {
    return `${patientGroup.surgeryTypeGroup?.code ?? ''}-${patientGroup.ageGroup?.age_group_code ?? ''}`
}

export const selectGetResolvedPatientGroups = createSelector(selectSurgeryTypeGroup, selectGetAgeGroups, (getSurgeryTypeGroup, getAgeGroups) => {
    return {
        byConstraints: (constraints: PatientGroupConstraints): ResolvedPatientGroup | null => {
            const surgeryTypeGroup = constraints.patient_group_id ? getSurgeryTypeGroup.byCode(constraints.patient_group_id) : null
            const ageGroup = getAgeGroups.byCode(constraints.age_group)

            if (!surgeryTypeGroup && !ageGroup) {
                return null
            }

            return { surgeryTypeGroup, ageGroup }
        },
    }
})

export type GetResolvedPatientGroups = ReturnType<typeof selectGetResolvedPatientGroups>
