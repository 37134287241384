import { memo } from 'react'

import { Id } from '../utils'
import { CellRender } from './types'

type BodyCellProps<Row extends { id: Id }, Col extends { id: Id }> = {
    row: Row
    col: Col
    cellRender: CellRender<Row, Col>
}

function BodyCellComponent<Row extends { id: Id }, Col extends { id: Id }>({ cellRender, col, row }: BodyCellProps<Row, Col>) {
    return cellRender(row, col)
}

export const BodyCell = memo(BodyCellComponent) as typeof BodyCellComponent
