import { PractitionerScheduleStatus, PractitionerStatusDefinition, selectGetPractitionerStatusDefinitions } from '~/store/selectors'
import { StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'
import { createGetStatusStateFn, useToggleStatuses } from '../hooks'
import { DatePractitionerCell } from '../utils'

type Props = {
    selectedCells: DatePractitionerCell[]
    scheduleStatuses: PractitionerScheduleStatus[]
    statusCode: StatusCode
    autoFocus?: boolean
}

export const StatusTag = ({ selectedCells, scheduleStatuses, statusCode, autoFocus = false }: Props) => {
    const getStatusState = createGetStatusStateFn(selectedCells, scheduleStatuses)
    const { toggleStatus, isLoading } = useToggleStatuses(selectedCells, scheduleStatuses)
    const getStatus = useStore(selectGetPractitionerStatusDefinitions)
    const status = getStatus.byCode(statusCode)

    function getTooltip(status: PractitionerStatusDefinition | null) {
        if (selectedCells.length !== 1) return status?.title

        const scheduleStatus = scheduleStatuses.find(scheduleStatus => scheduleStatus.status_code === status?.code)
        if (!scheduleStatus) return status?.title

        const editInfo = `Sist endret av ${scheduleStatus.updated_by} ${humanizeDate(day(scheduleStatus.updated_at))}`
        return `${status?.title}\n${editInfo}`
    }

    return (
        <ButtonTag
            autoFocus={autoFocus}
            isLoading={isLoading}
            colorType={status?.color ?? 'gray'}
            fillType={getStatusState(statusCode)}
            onClick={() => toggleStatus(statusCode)}
            data-tooltip={getTooltip(status)}
            className="transition-all"
        >
            {status?.label}
        </ButtonTag>
    )
}
