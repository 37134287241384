import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type MenuItem = {
    /**
     * The text to display for the dropdown item. This is required.
     */
    title: string

    /**
     * An optional React functional component for displaying an icon alongside the title.
     */
    icon?: React.FC

    /**
     * A function that gets executed when the dropdown item is clicked.
     */
    action: () => void

    /**
     * Determines if the dropdown item is hidden.
     * If `true`, the item will not be visible.
     */
    isHidden?: boolean

    /**
     * Indicates if the dropdown item is currently active or selected.
     */
    isActive?: boolean
    /**
     * An optional color to apply to the text of the dropdown item.
     */
    color?: string
    /**
     * An optional ref to the HTML div element for advanced DOM manipulation or access.
     */
    ref?: React.LegacyRef<HTMLDivElement>
}

export const HeroDropdownMenu = ({
    dropdownContent,
    children,
    className,
    ...contentProps
}: { dropdownContent: React.ReactNode; children: React.ReactNode } & DropdownMenu.DropdownMenuContentProps & ComponentProps<'div'>) => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
            <DropdownMenu.Content {...contentProps} className={twMerge('z-priority w-fit rounded-lg bg-sidebar p-2 shadow-lg', className)}>
                {dropdownContent}
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    )
}

export const HeroDropdownItem = ({ option, dataTest }: { option: MenuItem; dataTest?: string }) => {
    return (
        <DropdownMenu.Item
            ref={option.ref}
            data-test={dataTest}
            onClick={option.action}
            className={twMerge(
                'flex gap-1 rounded p-1 text-sm outline-none hover:cursor-pointer hover:bg-sidebar-accent',
                option.isHidden ? 'hidden' : '',
                option.isActive ? 'bg-sidebar-accent text-sidebar-accent-foreground' : '',
                option.color ? `text-${option.color}` : ''
            )}
        >
            {option.icon && <option.icon />}
            {option.title}
        </DropdownMenu.Item>
    )
}
