import client from '~/clients/api-client'

export const entityNames = [
    'age_groups',
    'block_locks',
    'block_schedules',
    'department_location_assignments',
    'department_practitioner_assignments',
    'departments',
    'hospital_surgery_type_group_associations',
    'hospital_surgery_types',
    'location_schedules',
    'locations',
    'practitioner_schedule_locations',
    'practitioner_schedule_statuses',
    'practitioner_schedules',
    'practitioner_service_period',
    'practitioner_status_definitions',
    'practitioners',
    'rule_definitions',
    'sections',
    'specialties',
    'surgery_metadatum',
    'surgery_type_group_age_restrictions',
    'surgery_type_group_hierarchies',
    'surgery_type_group_specialities',
    'surgery_type_groups',
] as const
export type EntityName = (typeof entityNames)[number]
export function isEntityName(value: unknown): value is EntityName {
    return typeof value === 'string' && entityNames.includes(value)
}

export const entities = {
    age_groups: {
        fetchEntity: client.fetchAgeGroups,
        deleteEntity: client.deleteAgeGroup,
    },
    block_locks: {
        fetchEntity: client.fetchBlockLocks,
        deleteEntity: client.deleteBlockLock,
    },
    block_schedules: {
        fetchEntity: client.fetchBlockSchedules,
        deleteEntity: client.deleteBlockSchedule,
    },
    department_location_assignments: {
        fetchEntity: client.fetchDepartmentLocationAssignments,
        deleteEntity: client.deleteDepartmentLocationAssignment,
    },
    department_practitioner_assignments: {
        fetchEntity: client.fetchDepartmentPractitionerAssignments,
        deleteEntity: client.deleteDepartmentPractitionerAssignment,
    },
    departments: {
        fetchEntity: client.fetchDepartments,
        deleteEntity: client.deleteDepartment,
    },
    hospital_surgery_type_group_associations: {
        fetchEntity: client.fetchHospitalSurgeryTypeGroupAssociations,
        deleteEntity: client.deleteHospitalSurgeryTypeGroupAssociation,
    },
    hospital_surgery_types: {
        fetchEntity: client.fetchHospitalSurgeryTypes,
        deleteEntity: client.deleteHospitalSurgeryType,
    },
    location_schedules: {
        fetchEntity: client.fetchLocationSchedules,
        deleteEntity: client.deleteLocationSchedule,
    },
    locations: {
        fetchEntity: client.fetchLocations,
        deleteEntity: client.deleteLocation,
    },
    practitioner_schedule_locations: {
        fetchEntity: client.fetchPractitionerScheduleLocations,
        deleteEntity: client.deletePractitionerScheduleLocation,
    },
    practitioner_schedule_statuses: {
        fetchEntity: client.fetchPractitionerScheduleStatuses,
        deleteEntity: client.deletePractitionerScheduleStatus,
    },
    practitioner_schedules: {
        fetchEntity: client.fetchPractitionerSchedules,
        deleteEntity: client.deletePractitionerSchedule,
    },
    practitioner_service_period: {
        fetchEntity: client.fetchPractitionerServicePeriods,
    },
    practitioner_status_definitions: {
        fetchEntity: client.fetchPractitionerStatusDefinitions,
    },
    practitioners: {
        fetchEntity: client.fetchPractitioners,
        deleteEntity: client.deletePractitioner,
    },
    rule_definitions: {
        fetchEntity: client.fetchRuleDefinitions,
        deleteEntity: client.deleteRuleDefinition,
    },
    sections: {
        fetchEntity: client.fetchSections,
        deleteEntity: client.deleteSection,
    },
    specialties: {
        fetchEntity: client.fetchSpecialities,
        deleteEntity: client.deleteSpeciality,
    },
    surgery_metadatum: {
        fetchEntity: client.fetchSurgeryMetadata,
        deleteEntity: client.deleteSurgeryMetadata,
    },
    surgery_type_group_age_restrictions: {
        fetchEntity: client.fetchSurgeryTypeGroupAgeRestrictions,
        deleteEntity: client.deleteSurgeryTypeGroupAgeRestriction,
    },
    surgery_type_group_hierarchies: {
        fetchEntity: client.fetchSurgeryTypeGroupHierarchies,
        deleteEntity: client.deleteSurgeryTypeGroupHierarchy,
    },
    surgery_type_group_specialities: {
        fetchEntity: client.fetchSurgeryTypeGroupSpecialities,
        deleteEntity: client.deleteSurgeryTypeGroupSpeciality,
    },
    surgery_type_groups: {
        fetchEntity: client.fetchSurgeryTypeGroups,
        deleteEntity: client.deleteSurgeryTypeGroup,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<EntityName, { fetchEntity: unknown; deleteEntity?: unknown }> as any

export const dateHeaders = ['start_time', 'end_time', 'created_at', 'updated_at']
export const metaHeaders = ['created_at', 'updated_at', 'is_active']
