import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Bar, Button, DIDatePicker } from '~/components'
import PageLayout from '~/components/PageLayout'
import { HeroBreadcrumbs } from '~/hero-ui/stories/HeroBreadcrumbs/HeroBreadcrumbs'
import { HeroSideNavTrigger } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'
import { PanelRightClose } from '~/icons/PanelRightClose'
import { PanelRightOpen } from '~/icons/PanelRightOpen'
import { importBlockSchedules, importPlanningPeriods, importPractitionerSchedules } from '~/store/di-entity.api'
import { selectTimeArgs } from '~/store/selectors'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { Filter } from './Header/Filter'
import { MultipleCreate } from './Header/MultipleCreate'
import { PublishPeriodDropdown } from './Header/PublishPeriodDropdown/PublishPeriodDropdown'
import { Sider } from './Sider/Sider'
import { WeeklyTable } from './WeeklyTable/WeeklyTable'

export default function PeriodCalendarPage() {
    const timeArgs = useStore(selectTimeArgs)
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const breadcrumbs = useStore(state => state.app.breadcrumbs)
    const { setBreadcrumbs } = useStore(state => state.app.actions)
    const location = useLocation()
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const currentPeriod = useStore(selectPlanningPeriods).byDate(selectedDate)

    const { isLoading: isLoadingEntities } = useImportEntities(
        () => [importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true }), importBlockSchedules({ ...timeArgs }), importPlanningPeriods({})],
        [timeArgs]
    )

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Kapasitet', href: '#' },
            { label: 'Kalender', href: location.pathname },
            { label: currentPeriod ? currentPeriod.name : `Uke ${selectedDate.isoWeek()}`, href: '#' },
        ])
    }, [location.pathname, setBreadcrumbs, selectedDate, currentPeriod])

    const isLoading = isLoadingEntities

    return (
        <PageLayout
            content={<WeeklyTable isLoading={isLoading} />}
            header={
                <Bar
                    left={
                        <div className="flex items-center gap-4">
                            <HeroSideNavTrigger />
                            <HeroBreadcrumbs options={breadcrumbs} />
                            <PublishPeriodDropdown />
                        </div>
                    }
                    center={<DIDatePicker unit="week" />}
                    right={
                        <div className="flex gap-2">
                            <MultipleCreate />
                            <Filter />
                            <Button size="sm" color="white" onClick={() => setIsSidebarOpen(prev => !prev)} className="flex items-center gap-1">
                                {isSidebarOpen ? <PanelRightClose width={16} /> : <PanelRightOpen width={16} />}
                                {`${isSidebarOpen ? 'Lukk' : 'Åpne'} sidepanel`}
                            </Button>
                        </div>
                    }
                />
            }
            sidebar={<Sider />}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
        />
    )
}
