import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import optimusClient from '~/clients/optimus-client'
import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { IconHourglassEmptyOutlined } from '~/icons'
import { Location, selectGetBlockSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { formatDuration } from '~/utils/extendedDayjs'

type Props = {
    date: Dayjs
    location: Location
}

export const Duration = ({ date, location }: Props) => {
    const getBlockSchedules = useStore(selectGetBlockSchedules)

    const blockSchedule = getBlockSchedules.byDateAndLocationId(date, location.id)

    const [durationData, setDurationData] = useState<{
        description?: string | undefined
        duration: string
    }>()

    useEffect(() => {
        if (!blockSchedule || blockSchedule.rule_instance?.rule_definition_id !== 'max_count_of_surgeries_per_patient_group') {
            setDurationData(undefined)
            return
        }
        const fetchStatus = async () => {
            try {
                const { data } = await optimusClient.getBlockScheduleDuration(blockSchedule.id)
                setDurationData(data)
            } catch (error) {
                // awaiting design for error handling
            }
        }

        void fetchStatus()
    }, [blockSchedule])

    return (
        durationData && (
            <HeroTooltip side="top" tooltipContent={<p>{durationData.description}</p>} className="w-48">
                <div className="mb-1 flex w-fit items-center rounded border border-slate-200 p-1" data-test="duration-chart-loaded">
                    <IconHourglassEmptyOutlined height={16} className="text-slate-500" />
                    <p className="text-nowrap">{formatDuration(durationData.duration)}</p>
                </div>
            </HeroTooltip>
        )
    )
}
