import { Dayjs } from 'dayjs'

import { add } from './addAndSubtract'
import { getDaysBetween } from './getDaysBetween'
import { getFridayInWeek } from './getFridayInWeek'
import { WeekObject } from './getGridMonth'
import { isBefore } from './isBefore'
import { isSameDate } from './isSameDate'
import { isWeekend } from './isWeekend'

const cache: Map<number, Map<number, WeekObject[]>> = new Map()

/**
 * Calculates the weekdays between two dates inclusively and orders them by year and week number.
 *
 * @param {Dayjs} start - The start date.
 * @param {Dayjs} end - The end date.
 * @returns {WeekObject[]} - An array of weeks (each containing year, week number, and weekdays) between the start date and end date (including both start and end weeks).
 */
export function getWeekdaysWithYearAndWeeknumberBetween(start: Dayjs, end: Dayjs): WeekObject[] {
    const startDate = isWeekend(start) ? add(start, 1, 'week').startOf('isoWeek') : start
    const endDate = isWeekend(end) ? getFridayInWeek(end) : end

    if (isBefore(endDate, startDate, 'day')) return []

    const cachedStart = cache.get(startDate.valueOf())
    const cached = cachedStart?.get(endDate.valueOf())

    if (cached) {
        return cached
    }

    const days = getDaysBetween(startDate, endDate)
    let weekdays: Dayjs[] = []

    const weeks = days.reduce((weeks: WeekObject[], day) => {
        if (!isWeekend(day)) {
            weekdays.push(day)
        }

        if (isSameDate(day, getFridayInWeek(day)) || isSameDate(day, endDate)) {
            weeks.push({ year: day.isoWeekYear(), weekNumber: day.isoWeek(), weekdays: weekdays })
            weekdays = []
        }

        return weeks
    }, [])

    cache.set(startDate.valueOf(), new Map([[endDate.valueOf(), weeks]]))

    return weeks
}
