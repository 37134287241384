import env from '~/env'
import { importBlockLocks, importBlockSchedules, importLocationSchedules, importPractitionerSchedules } from '~/store/di-entity.api'
import { importFullScheduledSurgeries } from '~/store/dips-entity.api'
import { useImportEntities } from '~/store/useImportEntities'
import { useAutomaticBlockLockRelease } from '~/utils/blockLock'
import { getEntityTimeArgs } from '~/utils/utils'

export function useImportOccupancyEntities(startTime: string, endTime: string) {
    const args = getEntityTimeArgs(startTime, endTime)
    const treatmentLocationId = Number(env.VITE_DIPS_TREATMENT_LOCATION_ID)

    const common = useImportEntities(
        () => [
            importBlockSchedules({ ...args }),
            importBlockLocks({ ...args }),
            importPractitionerSchedules({ ...args, exclude_practitioner: true }),
            importLocationSchedules({ ...args, exclude_location: true }),
        ],
        [args]
    )

    const dips = useImportEntities(() => [importFullScheduledSurgeries(startTime, endTime, treatmentLocationId)], [startTime, endTime, treatmentLocationId], {
        refetchInterval: 1000 * env.VITE_REFETCH_INTERVAL_S,
    })

    useAutomaticBlockLockRelease({
        skip: !common.isSuccess || !dips.isSuccess,
    })

    return {
        isLoading: common.isLoading || dips.isLoading,
        isError: common.isError || dips.isError,
        isSuccess: common.isSuccess && dips.isSuccess,
    }
}
