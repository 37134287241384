import { PagePaths } from '~/app/routes'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { useImportEntities } from '~/store/useImportEntities'

import { useImportFutureScheduledEntities } from './useImportFutureScheduledEntities'

function useImportUnScheduledEntities(): { isLoading: boolean } {
    return useImportEntities(() => [importUnScheduledSurgeries(), importSurgeryMetadata({})], [])
}

function useImportBoth() {
    const { isLoading: isUnscheduledLoading } = useImportUnScheduledEntities()
    const { isLoading: isScheduledLoading } = useImportFutureScheduledEntities()

    return { isLoading: isUnscheduledLoading || isScheduledLoading }
}

export function useImportWaitingListEntities(view: PagePaths['WAITING_LIST']): { isLoading: boolean } {
    return {
        '/patients/unscheduled': useImportUnScheduledEntities(),
        '/patients/scheduled': useImportFutureScheduledEntities(),
        '/patients/all': useImportBoth(),
    }[view]
}
