import { motion } from 'framer-motion'
import { type ReactNode } from 'react'

import { CloseOutlined } from '~/icons'

function PageLayout({
    content,
    header,
    sidebar,
    isSidebarOpen,
    setIsSidebarOpen,
}: {
    content: ReactNode
    header: ReactNode
    sidebar?: ReactNode
    isSidebarOpen?: boolean
    setIsSidebarOpen?: (isOpen: boolean) => void
}) {
    return (
        <div className="flex h-full w-full flex-col">
            <div className="shrink p-2">{header}</div>
            <div className="flex grow overflow-hidden p-2 pt-0">
                <div className="h-full w-full overflow-y-auto">{content}</div>
                {sidebar && setIsSidebarOpen && (
                    <motion.div
                        initial={false /** Reduces motion when switching between pages. */}
                        animate={
                            isSidebarOpen
                                ? { width: 'fit-content', opacity: 1, display: 'flex' }
                                : { width: 0, opacity: 0, transitionEnd: { display: 'hidden' } }
                        }
                        transition={{ duration: 0.2 }}
                        className="relative h-full"
                    >
                        {sidebar}
                        <div onClick={() => setIsSidebarOpen(false)} className="absolute right-3 top-2 cursor-pointer rounded-full hover:bg-slate-300">
                            <CloseOutlined />
                        </div>
                    </motion.div>
                )}
            </div>
        </div>
    )
}

export default PageLayout
