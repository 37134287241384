import { z } from 'zod'

import { getEntries } from './utils/object'

function toBoolean(value: unknown): boolean {
    return value === true || String(value).trim() === 'true'
}

function bool(defaultValue: boolean) {
    return z
        .union([z.literal('true'), z.literal('false'), z.boolean()])
        .default(defaultValue)
        .transform(toBoolean)
}

// Note: Don't use .coerce.boolean() because it will coerce 'false' to true
// Handle all default values here, rather than where the env is used!
// Please keep this list sorted alphabetically
const envSchema = z.object({
    DEV: bool(false), // vite sets this to NODE_ENV !== 'production'. Is true in CI
    VITE_API_BASE_URL: z.string(),
    VITE_API_OAUTH_CLIENT: z.string(),
    VITE_CLIENT_BASE_URL: z.string(),
    VITE_DIPS_BASE_URL: z.string(),
    VITE_DIPS_CLIENT_ID: z.string(),
    VITE_DIPS_SUBSCRIPTION_KEY: z.string().optional(),
    VITE_DIPS_TREATMENT_LOCATION_ID: z.string(),
    VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_API: bool(true),
    VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_DIPS: bool(false),
    VITE_ENABLE_SENTRY_USER_FEEDBACK: bool(false),
    VITE_FF_ENTITY_EDITOR_IN_NAVIGATION: bool(false),
    VITE_FF_MASTER_SURGERY_SCHEDULER: bool(true),
    VITE_FF_OPERATIONAL_PLANNER: bool(true),
    VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON: bool(false),
    VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER: bool(false),
    VITE_FF_OPERATIONAL_PLANNER_BOOKING_SEARCH_SURGERIES: bool(false),
    VITE_FF_PLANNING_PERIODS_TAB: bool(false),
    VITE_FF_REFRESH_TOKEN: bool(true),
    VITE_FF_SURGEON_SCHEDULER: bool(true),
    VITE_FF_SURGEON_SCHEDULER_MAGIC_WAND_BUTTON: bool(false),
    VITE_FF_WAITING_LIST: bool(true),
    VITE_FF_WAITING_LIST_MUTABLE_COMMENTS: bool(false),
    VITE_GIT_SHA: z.string().optional(),
    VITE_GIT_TAG: z.string().optional(),
    VITE_NO_UNSTABLE_EFFECTS: bool(false),
    VITE_PKCE_AUTHORIZE_URL: z.string().optional().default(''),
    VITE_PKCE_CLIENT_ID: z.string().optional().default(''),
    VITE_PKCE_FOR_DEVS: bool(false), // if false, PKCE flow is skipped for devs
    VITE_PKCE_LOGOUT_URL: z.string().optional().default(''),
    VITE_PKCE_REDIRECT_URL: z.string().optional().default(''),
    VITE_PKCE_SCOPES: z.string().optional().default(''),
    VITE_PKCE_TOKEN_URL: z.string().optional().default(''),
    VITE_PUBLIC_DIR: z.string().optional(),
    VITE_REFETCH_INTERVAL_S: z.coerce.number().default(30), // 0 disables refetching
    VITE_REMOVE_BOOKING_TEST_FLAGS: bool(false),
    VITE_SEGMENT_HOSTNAME: z.string().optional(),
    VITE_SEGMENT_TENANT_NAME: z.string().optional(),
    VITE_SEGMENT_WRITE_KEY: z.string().optional(),
    VITE_SENTRY_DSN: z.string().optional(),
    VITE_SENTRY_ENVIRONMENT: z.string().optional(),
    VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE: z.coerce.number().optional(),
    VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE: z.coerce.number().optional(),
    VITE_SENTRY_TRACES_SAMPLE_RATE: z.coerce.number().optional(),
    VITE_SKIP_VERSION_CHECK: bool(false),
    VITE_STRICT_MODE: bool(true),
    VITE_TODAY: z.string().optional(),
    VITE_WAITINGLIST_HIDE_ASA_FOR_DEPARTMENT_IDS: z.string().optional(),
    VITE_WAITING_LIST_ITEMS_PER_PAGE: z.coerce.number().default(25),
    VITE_HINT_WEBSOCKET: bool(true),
    VITE_SCHEDULED_SURGERY_API_VERSION: z.union([z.literal('v1'), z.literal('v2')]).default('v1'),
})

// eslint-disable-next-line local-rules/disallow-import-meta-env
const _originalEnv = envSchema.parse(import.meta.env)

type Env = z.infer<typeof envSchema>
export type EnvKey = keyof Env

function retrieveEnv() {
    const storedEnvString = localStorage.getItem('env-override')

    if (storedEnvString) {
        try {
            const storedValues = storedEnvString ? JSON.parse(storedEnvString) : {}
            const storedPartialEnv = envSchema.partial().parse(storedValues)
            return storedPartialEnv
        } catch (error) {
            console.error('Failed to parse stored env, removing storage:', error)
            localStorage.removeItem('env-override')
        }
    }

    return {}
}

function getCurrentEnv() {
    return { ..._originalEnv, ...retrieveEnv() }
}

export function getEnvVarDiff(compareToEnv: Partial<Env> = getCurrentEnv()) {
    return Object.fromEntries(getEntries(compareToEnv).filter(([key, value]) => _originalEnv[key] !== value))
}

export function storeEnv(envChange: Partial<Env>) {
    const envChangeParsed = envSchema.partial().parse(envChange)
    const newEnv = { ...getCurrentEnv(), ...envChangeParsed }
    const storedEnv = getEnvVarDiff(newEnv)

    localStorage.setItem('env-override', JSON.stringify(storedEnv))
}

const env = getCurrentEnv()

export default env
