import { useCallback, useEffect } from 'react'

import { ConfirmFn } from '~/components'
import { selectGetPractitionerScheduleLocations, selectGetPractitionerSchedules, selectGetPractitionerScheduleStatuses } from '~/store/selectors'
import { useStore } from '~/store/store'

import { useDeleteStatusesAndLocations, useToggleStatuses } from '../hooks'
import { DatePractitionerCell } from '../utils'

type Props = {
    selectedCells: DatePractitionerCell[]
    confirm: ConfirmFn
}

export const useCreateKeyboardShortcuts = ({ selectedCells, confirm }: Props) => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)
    const statuses = useStore(state => state.di.entities.practitionerStatusDefinitions)

    const practitionerSchedules = selectedCells.map(({ date, practitionerId }) => getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId))

    const allScheduleStatusesByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule?.id ?? 0))
    const allScheduleLocationsByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleLocations.byPractitionerScheduleId(schedule?.id ?? 0))

    const { toggleStatus } = useToggleStatuses(selectedCells, allScheduleStatusesByCell)
    const { deleteStatusesAndLocations } = useDeleteStatusesAndLocations(allScheduleStatusesByCell, allScheduleLocationsByCell, confirm)

    const shortcuts = useCallback(
        (keyCode: string, isMetaKeyPressed: boolean, isShiftKeyPressed: boolean) => {
            if (keyCode === 'Backspace' || keyCode === 'Delete') {
                void deleteStatusesAndLocations()
                return
            }

            statuses.allIds.forEach(statusCode => {
                const status = statuses.byId[statusCode]
                const keyboard_shortcut = status?.keyboard_shortcut
                const hasShift = status?.keyboard_has_shift ?? false
                const hasMeta = status?.keyboard_has_meta_key ?? false

                if (!keyboard_shortcut) return
                if (`Key${keyboard_shortcut}` === keyCode && hasMeta === isMetaKeyPressed && hasShift === isShiftKeyPressed) {
                    void toggleStatus(status.code)
                }
            })
        },
        [deleteStatusesAndLocations, statuses.allIds, statuses.byId, toggleStatus]
    )

    useEffect(() => {
        function listener(e: KeyboardEvent) {
            const keyCode = e.code
            if (selectedCells.length === 0) return

            const isMetaKeyPressed = e.metaKey || e.ctrlKey
            const isShiftKeyPressed = e.shiftKey

            shortcuts(keyCode, isMetaKeyPressed, isShiftKeyPressed)
        }

        document.addEventListener('keyup', listener)

        return () => {
            document.removeEventListener('keyup', listener)
        }
    }, [selectedCells.length, shortcuts, toggleStatus])
}
