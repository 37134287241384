import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { Show } from '~/components'
import { Location, selectGetNeedleMatch } from '~/store/selectors'
import { useStore } from '~/store/store'

import { CellBlock } from './CellBlock/CellBlock'
import { ThreeDotsMenu } from './ThreeDotsMenu/ThreeDotsMenu'

type Props = {
    date: Dayjs
    location: Location
}

export const Cell = ({ date, location }: Props) => {
    const [showButton, setShowButton] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const freeTextFilter = useStore(state => state.operationalPlanner.needle)
    const getNeedleMatch = useStore(selectGetNeedleMatch)
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)

    const isSelected = drawerProps?.locationId === location.id && drawerProps?.unixDate === date.unix()

    const isMatch = getNeedleMatch.byAllPractitioners(date, location, freeTextFilter)
    if (!isMatch) return null

    function onMouseOver() {
        setShowButton(true)
    }

    function onMouseLeave() {
        if (!showDropdown) setShowButton(false)
    }

    return (
        <div
            className={`grid h-full min-h-8 p-2 ${isSelected ? 'bg-diBlue-50 shadow-selectedCell' : ''}`}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            <CellBlock date={date} location={location} />
            <Show condition={showButton}>
                <ThreeDotsMenu date={date} location={location} showDropdown={showDropdown} setShowDropdown={setShowDropdown} setShowButton={setShowButton} />
            </Show>
        </div>
    )
}
