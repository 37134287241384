import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { pages } from '~/app/routes'
import { HeroListItem } from '~/hero-ui/stories/HeroListItem/HeroListItem'
import { ArrowBackUp, ArrowUp, CheckOutlined, DeleteOutlined, EditOutlined } from '~/icons'
import { updatePlanningPeriod } from '~/store/di-entity.api'
import { PlanningPeriod } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day } from '~/utils/extendedDayjs'

import { CreatePeriodDialog } from '../../../shared/CreatePeriodDialog'
import { DeleteDialog } from '../../../shared/DeleteDialog'
import { ProgressIcon } from '../../../shared/ProgressIcon'

type Props = {
    planningPeriod: PlanningPeriod
}

const iconStyles = 'w-4 h-4'

export default function PlanningPeriodListItem({ planningPeriod }: Props) {
    const [editOpen, setEditOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)
    const { setSelectedDate } = useStore(state => state.appFilters.actions)
    const navigate = useNavigate()

    async function onPublish() {
        await updatePlanningPeriod(planningPeriod.id, { ...planningPeriod, is_published: true })
        addToast({ type: 'success', message: 'Perioden er publisert' })
    }

    async function onUnpublish() {
        await updatePlanningPeriod(planningPeriod.id, { ...planningPeriod, is_published: false })
        addToast({ type: 'success', message: 'Perioden er avpublisert' })
    }

    const isPublished = !!planningPeriod.is_published

    const menuOptions = [
        {
            label: 'Rediger',
            action: () => setEditOpen(true),
            icon: <EditOutlined className={iconStyles} />,
        },
        {
            label: 'Slett',
            action: () => setDeleteOpen(true),
            icon: <DeleteOutlined className={iconStyles} />,
            variant: 'delete',
        },
    ]

    if (isPublished) {
        menuOptions.unshift({
            label: 'Avpubliser',
            action: () => onUnpublish(),
            icon: <ArrowBackUp className={iconStyles} />,
        })
    }

    const Content = () => <></>

    return (
        <>
            <HeroListItem
                mainAction={{
                    label: isPublished ? 'Publisert' : 'Publiser',
                    action: () => onPublish(),
                    disabled: isPublished,
                    icon: isPublished ? <CheckOutlined className={iconStyles} /> : <ArrowUp className={iconStyles} />,
                }}
                cardAction={{
                    tooltip: 'Kalender for perioden',
                    action: () => {
                        setSelectedDate(day(planningPeriod.start_date)), navigate(pages.MASTER_SURGERY_SCHEDULER.views['period-calendar'].path)
                    },
                }}
                menuOptions={menuOptions}
                titleSlot={
                    <div className="flex items-center gap-1">
                        <ProgressIcon planningPeriod={planningPeriod} />
                        <p className="text-ellipsis text-nowrap text-base">{planningPeriod.name}</p>
                        <p className="text-nowrap text-xs">{`Uke ${day(planningPeriod.start_date).isoWeek()} - Uke ${day(planningPeriod.end_date).isoWeek()}`}</p>
                    </div>
                }
                contentSlot={<Content />}
            />
            <CreatePeriodDialog id={planningPeriod.id} open={editOpen} onClose={() => setEditOpen(false)} />
            <DeleteDialog id={planningPeriod.id} open={deleteOpen} onClose={() => setDeleteOpen(false)} />
        </>
    )
}
