import dayjs, { Dayjs } from 'dayjs'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { getToday } from '~/utils/extendedDayjs'

import { PlanningPeriod, selectEntities } from './entities'

export const selectPlanningPeriods = createSelector(selectEntities, ({ planningPeriods }) => {
    const today = getToday()

    const byId = keyBy(planningPeriods, 'id')
    const descendingPlanningPeriods = sortBy(planningPeriods, planningPeriod => planningPeriod.start_date).reverse()

    return {
        byId: (id: number): PlanningPeriod | null => byId[id] ?? null,
        byDate: (date: Dayjs): PlanningPeriod | null =>
            descendingPlanningPeriods.find(period => date.isBetween(period.start_date, period.end_date, 'day', '[]')) ?? null,
        all: () => descendingPlanningPeriods,
        completed: () => descendingPlanningPeriods.filter(period => today.isAfter(period.end_date)),
        current: () => descendingPlanningPeriods.filter(period => today.isBetween(period.start_date, period.end_date, 'day', '[]')),
        future: () => descendingPlanningPeriods.filter(period => today.isBefore(period.start_date)),
    }
})

export const selectUnavailableDates = createSelector(selectEntities, ({ planningPeriods }) => {
    function getDatesBetween(startDate: string, endDate: string) {
        const start = dayjs(startDate)
        const end = dayjs(endDate)

        const daysInRange = end.diff(start, 'day') + 1

        return Array.from({ length: daysInRange }, (_, index) => {
            return start.add(index, 'day').toDate()
        })
    }

    const all = planningPeriods.reduce((acc: Date[], period) => {
        return [...acc, ...getDatesBetween(period.start_date, period.end_date)]
    }, [])

    return {
        all: () => all,
        excludeById: (id: number) => {
            const period = planningPeriods.find(period => period.id === id)

            if (!period) return all

            return all.filter(date => !dayjs(date).isBetween(period.start_date, period.end_date, null, '[]'))
        },
    }
})
