import { ComponentProps, FC } from 'react'

export const ListIllustration: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="342" height="320" viewBox="0 0 342 320" fill="none" {...props}>
        <path
            d="M106 18V302M38 18V302M242 18V302M310 18V302M174 18V302M0 52H342M0 95H342M0 138H342M0 181H342M0 224H342M0 266H342"
            stroke="url(#paint0_radial_5741_21220)"
            strokeOpacity="0.6"
            strokeMiterlimit="10"
        />
        <path
            d="M233 109.024H115C110.029 109.024 106 113.053 106 118.024V146.048C106 151.018 110.029 155.048 115 155.048H233C237.971 155.048 242 151.018 242 146.048V118.024C242 113.053 237.971 109.024 233 109.024Z"
            fill="white"
            stroke="#99A2AF"
            strokeWidth="0.75"
            strokeMiterlimit="10"
        />
        <path
            opacity="0.4"
            d="M230.479 114.503H117.479C114.165 114.503 111.479 117.189 111.479 120.503V143.569C111.479 146.882 114.165 149.569 117.479 149.569H230.479C233.792 149.569 236.479 146.882 236.479 143.569V120.503C236.479 117.189 233.792 114.503 230.479 114.503Z"
            stroke="#99A2AF"
            strokeWidth="0.75"
            strokeMiterlimit="10"
        />
        <path
            opacity="0.2"
            d="M220.512 129H148.189C146.676 129 145.449 130.227 145.449 131.74C145.449 133.253 146.676 134.479 148.189 134.479H220.512C222.025 134.479 223.251 133.253 223.251 131.74C223.251 130.227 222.025 129 220.512 129Z"
            fill="#99A2AF"
        />
        <path
            d="M233 164H115C110.029 164 106 168.029 106 173V201.024C106 205.994 110.029 210.024 115 210.024H233C237.971 210.024 242 205.994 242 201.024V173C242 168.029 237.971 164 233 164Z"
            fill="white"
        />
        <g opacity="0.6">
            <path
                d="M233 164H115C110.029 164 106 168.029 106 173V201.024C106 205.994 110.029 210.024 115 210.024H233C237.971 210.024 242 205.994 242 201.024V173C242 168.029 237.971 164 233 164Z"
                fill="white"
                stroke="#99A2AF"
                strokeWidth="0.75"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.4"
                d="M230.479 169.479H117.479C114.165 169.479 111.479 172.165 111.479 175.479V198.545C111.479 201.859 114.165 204.545 117.479 204.545H230.479C233.792 204.545 236.479 201.859 236.479 198.545V175.479C236.479 172.165 233.792 169.479 230.479 169.479Z"
                stroke="#99A2AF"
                strokeWidth="0.75"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.2"
                d="M220.512 184H148.189C146.676 184 145.449 185.227 145.449 186.74C145.449 188.253 146.676 189.479 148.189 189.479H220.512C222.025 189.479 223.251 188.253 223.251 186.74C223.251 185.227 222.025 184 220.512 184Z"
                fill="#99A2AF"
            />
        </g>
        <path
            d="M233 220H115C110.029 220 106 224.029 106 229V257.024C106 261.994 110.029 266.024 115 266.024H233C237.971 266.024 242 261.994 242 257.024V229C242 224.029 237.971 220 233 220Z"
            fill="white"
        />
        <g opacity="0.4">
            <path
                d="M233 220H115C110.029 220 106 224.029 106 229V257.024C106 261.994 110.029 266.024 115 266.024H233C237.971 266.024 242 261.994 242 257.024V229C242 224.029 237.971 220 233 220Z"
                fill="white"
                stroke="#99A2AF"
                strokeWidth="0.75"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.4"
                d="M230.479 225.479H117.479C114.165 225.479 111.479 228.165 111.479 231.479V254.545C111.479 257.859 114.165 260.545 117.479 260.545H230.479C233.792 260.545 236.479 257.859 236.479 254.545V231.479C236.479 228.165 233.792 225.479 230.479 225.479Z"
                stroke="#99A2AF"
                strokeWidth="0.75"
                strokeMiterlimit="10"
            />
            <path
                opacity="0.2"
                d="M220.512 240H148.189C146.676 240 145.449 241.227 145.449 242.74C145.449 244.253 146.676 245.479 148.189 245.479H220.512C222.025 245.479 223.251 244.253 223.251 242.74C223.251 241.227 222.025 240 220.512 240Z"
                fill="#99A2AF"
            />
        </g>
        <path
            d="M106 67.4C106 62.3595 106 59.8393 106.981 57.9141C107.844 56.2206 109.221 54.8438 110.914 53.9809C112.839 53 115.36 53 120.4 53H230.8C234.72 53 236.681 53 238.178 53.763C239.495 54.4341 240.566 55.5049 241.237 56.8221C242 58.3194 242 60.2796 242 64.2V84.6238C242 89.6643 242 92.1845 241.019 94.1097C240.156 95.8032 238.779 97.18 237.086 98.0429C235.161 99.0238 232.64 99.0238 227.6 99.0238H120.4C115.36 99.0238 112.839 99.0238 110.914 98.0429C109.221 97.18 107.844 95.8032 106.981 94.1097C106 92.1845 106 89.6643 106 84.6238V67.4Z"
            fill="white"
        />
        <path
            opacity="0.7"
            d="M106 67.4C106 62.3595 106 59.8393 106.981 57.9141C107.844 56.2206 109.221 54.8438 110.914 53.9809C112.839 53 115.36 53 120.4 53H230.8C234.72 53 236.681 53 238.178 53.763C239.495 54.4341 240.566 55.5049 241.237 56.8221C242 58.3194 242 60.2796 242 64.2V84.6238C242 89.6643 242 92.1845 241.019 94.1097C240.156 95.8032 238.779 97.18 237.086 98.0429C235.161 99.0238 232.64 99.0238 227.6 99.0238H120.4C115.36 99.0238 112.839 99.0238 110.914 98.0429C109.221 97.18 107.844 95.8032 106.981 94.1097C106 92.1845 106 89.6643 106 84.6238V67.4Z"
            stroke="#0056D6"
            strokeWidth="0.75"
        />
        <path
            opacity="0.4"
            d="M230.479 58.479H117.479C114.165 58.479 111.479 61.1653 111.479 64.479V87.5448C111.479 90.8585 114.165 93.5448 117.479 93.5448H230.479C233.792 93.5448 236.479 90.8585 236.479 87.5448V64.479C236.479 61.1653 233.792 58.479 230.479 58.479Z"
            stroke="#136EF6"
            strokeWidth="0.75"
        />
        <path
            d="M220.512 73H148.189C146.676 73 145.449 74.2265 145.449 75.7395V75.7395C145.449 77.2525 146.676 78.479 148.189 78.479H220.512C222.025 78.479 223.251 77.2525 223.251 75.7395C223.251 74.2265 222.025 73 220.512 73Z"
            fill="#EBEFFE"
        />
        <path d="M242.721 50.6362V54.9998H241.931V51.4053H241.905L240.885 52.0573V51.3329L241.969 50.6362H242.721Z" fill="white" />
        <circle cx="129" cy="76" r="11" fill="#EBEFFE" fillOpacity="0.5" />
        <circle cx="129" cy="132" r="11" fill="#EBECEF" />
        <circle cx="129" cy="187" r="11" fill="#F3F4F5" />
        <circle cx="129" cy="243" r="11" fill="#F7F7F9" />
        <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.25 70.3876C126.36 69.6459 127.665 69.25 129 69.25C129.414 69.25 129.75 69.5858 129.75 70C129.75 70.4142 129.414 70.75 129 70.75C127.962 70.75 126.947 71.0579 126.083 71.6348C125.22 72.2117 124.547 73.0316 124.15 73.9909C123.752 74.9502 123.648 76.0058 123.851 77.0242C124.053 78.0426 124.553 78.9781 125.288 79.7123C126.022 80.4465 126.957 80.9466 127.976 81.1491C128.994 81.3517 130.05 81.2477 131.009 80.8504C131.968 80.453 132.788 79.7801 133.365 78.9167C133.942 78.0534 134.25 77.0384 134.25 76C134.25 75.5858 134.586 75.25 135 75.25C135.414 75.25 135.75 75.5858 135.75 76C135.75 77.335 135.354 78.6401 134.612 79.7501C133.871 80.8601 132.817 81.7253 131.583 82.2362C130.35 82.7471 128.993 82.8808 127.683 82.6203C126.374 82.3599 125.171 81.717 124.227 80.773C123.283 79.829 122.64 78.6262 122.38 77.3169C122.119 76.0075 122.253 74.6503 122.764 73.4169C123.275 72.1835 124.14 71.1293 125.25 70.3876Z"
            fill="#5151CD"
        />
        <g opacity="0.7">
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M125.25 126.388C126.36 125.646 127.665 125.25 129 125.25C129.414 125.25 129.75 125.586 129.75 126C129.75 126.414 129.414 126.75 129 126.75C127.962 126.75 126.947 127.058 126.083 127.635C125.22 128.212 124.547 129.032 124.15 129.991C123.752 130.95 123.648 132.006 123.851 133.024C124.053 134.043 124.553 134.978 125.288 135.712C126.022 136.447 126.957 136.947 127.976 137.149C128.994 137.352 130.05 137.248 131.009 136.85C131.968 136.453 132.788 135.78 133.365 134.917C133.942 134.053 134.25 133.038 134.25 132C134.25 131.586 134.586 131.25 135 131.25C135.414 131.25 135.75 131.586 135.75 132C135.75 133.335 135.354 134.64 134.612 135.75C133.871 136.86 132.817 137.725 131.583 138.236C130.35 138.747 128.993 138.881 127.683 138.62C126.374 138.36 125.171 137.717 124.227 136.773C123.283 135.829 122.64 134.626 122.38 133.317C122.119 132.007 122.253 130.65 122.764 129.417C123.275 128.183 124.14 127.129 125.25 126.388Z"
                fill="#B9BBC6"
            />
        </g>
        <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.25 181.388C126.36 180.646 127.665 180.25 129 180.25C129.414 180.25 129.75 180.586 129.75 181C129.75 181.414 129.414 181.75 129 181.75C127.962 181.75 126.947 182.058 126.083 182.635C125.22 183.212 124.547 184.032 124.15 184.991C123.752 185.95 123.648 187.006 123.851 188.024C124.053 189.043 124.553 189.978 125.288 190.712C126.022 191.447 126.957 191.947 127.976 192.149C128.994 192.352 130.05 192.248 131.009 191.85C131.968 191.453 132.788 190.78 133.365 189.917C133.942 189.053 134.25 188.038 134.25 187C134.25 186.586 134.586 186.25 135 186.25C135.414 186.25 135.75 186.586 135.75 187C135.75 188.335 135.354 189.64 134.612 190.75C133.871 191.86 132.817 192.725 131.583 193.236C130.35 193.747 128.993 193.881 127.683 193.62C126.374 193.36 125.171 192.717 124.227 191.773C123.283 190.829 122.64 189.626 122.38 188.317C122.119 187.007 122.253 185.65 122.764 184.417C123.275 183.183 124.14 182.129 125.25 181.388Z"
            fill="#D9D9E0"
        />
        <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.25 237.388C126.36 236.646 127.665 236.25 129 236.25C129.414 236.25 129.75 236.586 129.75 237C129.75 237.414 129.414 237.75 129 237.75C127.962 237.75 126.947 238.058 126.083 238.635C125.22 239.212 124.547 240.032 124.15 240.991C123.752 241.95 123.648 243.006 123.851 244.024C124.053 245.043 124.553 245.978 125.288 246.712C126.022 247.447 126.957 247.947 127.976 248.149C128.994 248.352 130.05 248.248 131.009 247.85C131.968 247.453 132.788 246.78 133.365 245.917C133.942 245.053 134.25 244.038 134.25 243C134.25 242.586 134.586 242.25 135 242.25C135.414 242.25 135.75 242.586 135.75 243C135.75 244.335 135.354 245.64 134.612 246.75C133.871 247.86 132.817 248.725 131.583 249.236C130.35 249.747 128.993 249.881 127.683 249.62C126.374 249.36 125.171 248.717 124.227 247.773C123.283 246.829 122.64 245.626 122.38 244.317C122.119 243.007 122.253 241.65 122.764 240.417C123.275 239.183 124.14 238.129 125.25 237.388Z"
            fill="#F0F0F3"
        />
        <defs>
            <radialGradient
                id="paint0_radial_5741_21220"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(171 160) rotate(90) scale(162 188.914)"
            >
                <stop stopColor="#BCC3CE" />
                <stop offset="1" stopColor="#BCC3CE" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
)
