import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react'

import { Id, toCellId } from './utils'

export type BodyProps<Row extends { id: Id }, HeaderRow extends { id: Id }> = {
    rows: Row[]
    rowRender: (row: Row) => ReactNode
    rowClassName?: (row: Row) => string

    cellRender: (row: Row, headerRow: HeaderRow) => ReactNode
    cellClassName?: (row: Row, headerRow: HeaderRow) => string
}

type Props<Row extends { id: Id }, Cell extends { id: Id }> = BodyProps<Row, Cell> & {
    headerRow: Cell[]
    selectable: boolean

    selectedCells: Set<string>
    setSelectedCells: Dispatch<SetStateAction<Set<string>>>
    showModal: () => void
    hideModal: () => void
}

export function Body<Row extends { id: Id }, Column extends { id: Id }>({
    selectable,
    rows,
    rowRender,
    rowClassName,
    headerRow,
    cellRender,
    cellClassName,
    setSelectedCells,
    showModal,
    hideModal,
    selectedCells,
}: Props<Row, Column>) {
    function handleCellMouseDown(cell: string) {
        if (!selectable) return

        return (event: MouseEvent) => {
            const isPresented = selectedCells.has(cell)
            const isCombinedSelectionKey = event.metaKey || event.ctrlKey

            setSelectedCells(prev => {
                const newSet = !isCombinedSelectionKey ? new Set<string>() : new Set<string>(prev)

                if (!isPresented) {
                    newSet.add(cell)
                } else if (isCombinedSelectionKey) {
                    newSet.delete(cell)
                }

                return newSet
            })

            hideModal()
        }
    }

    function handleMouseUp() {
        if (selectedCells.size > 0) {
            showModal()
        }
    }

    function handleCellMouseMove(cell: string) {
        if (!selectable) return

        return debounce((event: MouseEvent) => {
            const isMouseDown = event.buttons === 1
            const isPresented = selectedCells.has(cell)

            if (isMouseDown && !isPresented) {
                setSelectedCells(prev => {
                    const newSet = new Set<string>(prev)
                    newSet.add(cell)
                    return newSet
                })
            }
        }, 10)
    }

    return (
        <>
            {rows.map(row => (
                <tr key={row.id}>
                    <td
                        data-test={`${row.id}`}
                        className={clsx(rowClassName?.(row), 'sticky left-0 z-elevated border-b border-r border-r-[#8391c3] bg-white p-2')}
                    >
                        {rowRender(row)}
                    </td>

                    {headerRow.map(cell => {
                        const dataCell = toCellId(cell.id, row.id)

                        return (
                            <td
                                key={dataCell}
                                data-cell={dataCell}
                                onMouseUp={handleMouseUp}
                                onMouseDown={handleCellMouseDown(dataCell)}
                                onMouseMove={handleCellMouseMove(dataCell)}
                                className={clsx(cellClassName?.(row, cell), 'border-b border-r bg-white last:border-r-0', {
                                    'relative bg-blue-50 outline outline-1 outline-blue-500': selectedCells.has(dataCell),
                                    'cursor-pointer': selectable,
                                })}
                            >
                                {cellRender(row, cell)}
                            </td>
                        )
                    })}
                </tr>
            ))}
        </>
    )
}
