import { DepartmentOut } from '~/clients/derived-api-types'

import LocationsList from './LocationsList'
import PractitionersList from './PractitionersList'
import SectionsList from './SectionsList'

type Props = {
    department: DepartmentOut | 'uncategorized'
}

const Department = ({ department }: Props) => {
    const departmentId = department === 'uncategorized' ? 'uncategorized' : department.id

    return (
        <div className="flex flex-col gap-2">
            <div className="text-lg">{department === 'uncategorized' ? 'Uncategorized items' : department.name}</div>

            <LocationsList departmentId={departmentId} />
            <PractitionersList departmentId={departmentId} />
            <SectionsList departmentId={departmentId} />
        </div>
    )
}

export default Department
