import clsx from 'clsx'
import { memo, ReactNode, RefObject } from 'react'

import { ExtraRow, Id } from '../utils'

type HeaderProps<Cell extends { id: Id }> = {
    gridTemplateColumns: string
    headerRef: RefObject<HTMLDivElement>
    rowHeader: () => ReactNode
    cells: Cell[]
    cellRender: (header: Cell) => ReactNode
    cellClassName?: (header: Cell) => string

    extraHeaders?: ExtraRow<Cell>[]
}

export type ExternalHeaderProps<Cell extends { id: Id }> = Omit<HeaderProps<Cell>, 'gridTemplateColumns' | 'headerRef'>

function HeaderComponent<Cell extends { id: Id }>({
    rowHeader,
    cells,
    cellClassName,
    cellRender,
    gridTemplateColumns,
    headerRef,
    extraHeaders,
}: HeaderProps<Cell>) {
    return (
        <div
            ref={headerRef}
            className="sticky top-0 z-priority grid whitespace-nowrap border-b border-[#8391c3]"
            style={{
                gridTemplateColumns,
                gridColumn: `1 / span ${cells.length + 1}`,
            }}
        >
            <div className="grid" style={{ gridTemplateColumns, gridColumn: `1 / span ${cells.length + 1}` }}>
                <div className="sticky left-0 border-b border-r border-[#8391c3] bg-white p-1">{rowHeader()}</div>
                {cells.map(cell => (
                    <div key={cell.id} className={clsx('border-b border-r border-b-[#8391c3] bg-white p-1 text-center last:border-r-0', cellClassName?.(cell))}>
                        {cellRender(cell)}
                    </div>
                ))}
            </div>

            {extraHeaders?.map(({ key, rowHeader, cellRender, cellClassName }, index) => (
                <div key={key} className="grid" style={{ gridTemplateColumns, gridColumn: `1 / span ${cells.length + 1}` }}>
                    <div
                        className={clsx('sticky left-0 border-r border-r-[#8391c3] bg-white', {
                            'border-b': index !== extraHeaders.length - 1,
                        })}
                    >
                        {rowHeader()}
                    </div>
                    {cells.map(cell => (
                        <div
                            key={cell.id}
                            className={clsx('border-b border-r bg-white text-center last:border-r-0', cellClassName?.(cell), {
                                'border-b-0': index === extraHeaders.length - 1,
                            })}
                        >
                            {cellRender(cell)}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export const Header = memo(HeaderComponent) as typeof HeaderComponent
