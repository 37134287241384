import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { RuleEvaluation, selectGetLocations } from '~/store/selectors'
import { getSurgeonsAndAssistants, MinimalPractitioner } from '~/utils/dips'
import { add, format } from '~/utils/extendedDayjs'
import { isOccupancyBookable } from '~/utils/occupancy/occupancy'

import { DepartmentKey } from '../slices/filterSlice'
import { selectGetBlockSchedules } from './blockSchedules'
import { BlockSchedule, Location, ScheduledSurgery } from './entities'
import { evaluateBlock, findMismatchedSurgeries } from './internal/blockEvaluations/blockEvaluation'
import { selectGetResolvedPatientGroups } from './resolvedPatientGroups'
import { selectGetScheduledSurgeries } from './scheduledSurgeries'

export type OccupancyData = {
    date: Dayjs
    location: Location
    blockSchedule: BlockSchedule | null
    evaluations: RuleEvaluation[]
    bookedSurgeries: ScheduledSurgery[]
    bookedPractitioners: MinimalPractitioner[]
    mismatchedSurgeries: ScheduledSurgery[]
}

function getCacheKey(date: Dayjs, location: Location) {
    return `${format(date, 'YYYY-MM-DD')}-${location.id}`
}

export const selectGetOccupancies = createSelector(
    selectGetResolvedPatientGroups,
    selectGetBlockSchedules,
    selectGetScheduledSurgeries,
    selectGetLocations,
    (getResolvedPatientGroups, getBlockSchedules, getScheduledSurgeries, getLocations) => {
        const cache: Record<string, OccupancyData> = {}

        const getOccupancyDataByDateAndLocation = (date: Dayjs, location: Location) => {
            const key = getCacheKey(date, location)
            const cached = cache[key]
            if (cached) {
                return cached
            }

            const locationId = location.id
            const blockSchedule = getBlockSchedules.byDateAndLocationId(date, locationId)
            const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)
            const allSurgeryResources = bookedSurgeries.flatMap(surgery => surgery.surgeryResources)

            const bookedPractitioners = getSurgeonsAndAssistants(allSurgeryResources)

            const evaluations = evaluateBlock(blockSchedule, bookedSurgeries, getResolvedPatientGroups)
            const mismatchedSurgeries = findMismatchedSurgeries(bookedSurgeries, blockSchedule, getResolvedPatientGroups)
            const occupancyData = {
                date,
                location,
                blockSchedule,
                evaluations,
                bookedSurgeries,
                bookedPractitioners,
                mismatchedSurgeries,
            }

            cache[key] = occupancyData

            return occupancyData
        }

        return {
            byMonth: (date: Dayjs, departmentKey: DepartmentKey, availableOnly: boolean, surgeryTypeId: number): Record<string, OccupancyData[]> => {
                const departmentLocations = getLocations.byDepartment(departmentKey)
                const daysInMonth = date.daysInMonth()

                const occupancyDataByMonth: Record<string, OccupancyData[]> = {}
                for (let i = 1; i <= daysInMonth; i++) {
                    const currentDate = add(date, i, 'day')

                    occupancyDataByMonth[format(currentDate, 'YYYY-MM-DD')] = departmentLocations
                        .map(location => {
                            const occ = getOccupancyDataByDateAndLocation(currentDate, location)
                            return occ
                        })
                        .filter(occ => (availableOnly ? isOccupancyBookable({ occupancy: occ, matchPatientGroup: { surgeryTypeId } }) : true))
                }
                return occupancyDataByMonth
            },
            byDate: (date: Dayjs, departmentKey: DepartmentKey, availableOnly: boolean): OccupancyData[] => {
                const departmentLocations = getLocations.byDepartment(departmentKey)
                // loop through all locations and get the occupancy data for the given date
                return departmentLocations
                    .map(location => {
                        return getOccupancyDataByDateAndLocation(date, location)
                    })
                    .filter(occupancyData => {
                        return availableOnly ? occupancyData.evaluations.some(evaluation => evaluation.status === 'Available') : true
                    })
            },
            byDateAndLocation: (date: Dayjs, location: Location): OccupancyData => {
                return getOccupancyDataByDateAndLocation(date, location)
            },
        }
    }
)
