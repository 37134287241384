import { Duration } from 'dayjs/plugin/duration'

import {
    MaxCountRuleCreate,
    MaxCountRuleOut,
    MaxDurationAndCountRuleCreate,
    MaxDurationAndCountRuleOut,
    MaxDurationRuleCreate,
    MaxDurationRuleOut,
    MaxKnifeDurationAndCountRuleCreate,
    MaxKnifeDurationAndCountRuleOut,
    MaxKnifeDurationRuleCreate,
    MaxKnifeDurationRuleOut,
    MaxWeightRuleCreate,
    MaxWeightRuleOut,
    RuleDefinitionOut,
} from '~/clients/derived-api-types'

import { ScheduledSurgery } from './entities'
import { ResolvedPatientGroup } from './resolvedPatientGroups'

export const OccupancyStatus = {
    Available: 'Available',
    Filled: 'Filled',
    Overbooked: 'Overbooked' /** There are more surgeries booked in this block than there are slots available */,
} as const

export type OccupancyStatusType = (typeof OccupancyStatus)[keyof typeof OccupancyStatus] // 'Available' | 'Filled' | 'Overbooked'

export const CapacityRuleTypes = {
    CountBased: 'CountBased',
    RoomDurationBased: 'RoomDurationBased',
    KnifeTimeBased: 'KnifeTimeBased',
} as const
export type CapacityRuleType = (typeof CapacityRuleTypes)[keyof typeof CapacityRuleTypes]

export type CapacityRuleBase<T, Z, D extends CapacityRuleType> = {
    type: D // discriminator field
    patientGroupFilter: ResolvedPatientGroup | null
    getSurgeryOccupancy: (surgery: ScheduledSurgery, patientGroup: ResolvedPatientGroup | null) => T
    accumulate(a: T, b: T): T
    subtract(a: Z, b: T): Z
    evaluate(a: T): OccupancyStatusType
    filledBlockThreshold: Z | null
    zeroValue: T
}

// surgeryCounts includes weighted surgeries
type BookingId = string

export type SurgeryCounts = Map<string, { patientGroup: ResolvedPatientGroup; bookingIds: (BookingId | undefined)[]; value: number }>

export type CountBasedRule = CapacityRuleBase<SurgeryCounts, number, typeof CapacityRuleTypes.CountBased>
export type DurationBasedRule = CapacityRuleBase<Duration, Duration, typeof CapacityRuleTypes.RoomDurationBased | typeof CapacityRuleTypes.KnifeTimeBased>
export type CapacityRule = CountBasedRule | DurationBasedRule

export function isCountBasedRule(rule: CapacityRule): rule is CountBasedRule {
    return rule.type === CapacityRuleTypes.CountBased
}
export function isDurationBasedRule(rule: CapacityRule): rule is DurationBasedRule {
    return rule.type === CapacityRuleTypes.KnifeTimeBased || rule.type === CapacityRuleTypes.RoomDurationBased
}
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type BlockRulesOut =
    | (MaxCountRuleOut & { definition: RuleDefinitionOut })
    | (MaxWeightRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationAndCountRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | (MaxKnifeDurationRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-duplicate-type-constituents
    | (MaxKnifeDurationAndCountRuleOut & { definition: RuleDefinitionOut })

type BlockRulesCreate =
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | undefined
    | null

export type BlockRules = BlockRulesOut | BlockRulesCreate

type MaxCountBlockRules = MaxCountRuleOut | MaxCountRuleCreate
type MaxWeightBlockRules = MaxWeightRuleOut | MaxWeightRuleCreate
type MaxDurationBlockRules = MaxDurationRuleOut | MaxDurationRuleCreate
type MaxDurationAndCountBlockRules = MaxDurationAndCountRuleOut | MaxDurationAndCountRuleCreate
type MaxKnifeDurationBlockRules = MaxKnifeDurationRuleOut | MaxKnifeDurationRuleCreate
type MaxKnifeDurationAndCountBlockRules = MaxKnifeDurationAndCountRuleOut | MaxKnifeDurationAndCountRuleCreate

export function isMaxCountBlockRules(blockRules: BlockRules): blockRules is MaxCountBlockRules {
    return blockRules?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'
}

export function isMaxWeightBlockRules(blockRules: BlockRules): blockRules is MaxWeightBlockRules {
    return blockRules?.rule_definition_id === 'max_weight_of_surgeries_per_patient_group'
}

export function isMaxDurationBlockRules(blockRules: BlockRules): blockRules is MaxDurationBlockRules {
    return blockRules?.rule_definition_id === 'max_duration_of_surgeries_per_patient_group'
}

export function isMaxDurationAndCountBlockRules(blockRules: BlockRules): blockRules is MaxDurationAndCountBlockRules {
    return blockRules?.rule_definition_id === 'max_duration_and_max_count_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationBlockRules(blockRules: BlockRules): blockRules is MaxKnifeDurationBlockRules {
    return blockRules?.rule_definition_id === 'max_knife_time_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationAndCountBlockRules(blockRules: BlockRules): blockRules is MaxKnifeDurationAndCountBlockRules {
    return blockRules?.rule_definition_id === 'max_knife_time_and_max_count_of_surgeries_per_patient_group'
}

type RuleEvaluationBase<T, Z, D extends CapacityRuleType> = {
    type: D // discriminator field
    value: T
    remaining: Z | null
    maxValue: Z | null
    filteredByPatientGroup: ResolvedPatientGroup | null
    bookingIds: string[]
    status: OccupancyStatusType
}

type RoomDurationRuleEvaluation = RuleEvaluationBase<Duration, Duration, typeof CapacityRuleTypes.RoomDurationBased>
type KnifeTimeRuleEvaluation = RuleEvaluationBase<Duration, Duration, typeof CapacityRuleTypes.KnifeTimeBased>

export type DurationRuleEvaluation = RuleEvaluationBase<
    Duration,
    Duration,
    typeof CapacityRuleTypes.RoomDurationBased | typeof CapacityRuleTypes.KnifeTimeBased
>
export type CountRuleEvaluation = RuleEvaluationBase<SurgeryCounts, number, typeof CapacityRuleTypes.CountBased>
export type RuleEvaluation = CountRuleEvaluation | DurationRuleEvaluation

export function isCountBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is CountRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.CountBased
}

export function isRoomDurationBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is RoomDurationRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.RoomDurationBased
}

export function isKnifeTimeBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is KnifeTimeRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.KnifeTimeBased
}

export function isDurationRuleEvaluation(evaluation: RuleEvaluation): evaluation is DurationRuleEvaluation {
    return isRoomDurationBasedRuleEvaluation(evaluation) || isKnifeTimeBasedRuleEvaluation(evaluation)
}

export function hasAvailability(evaluation: RuleEvaluation): boolean {
    return evaluation?.status === OccupancyStatus.Available
}

export function isFilled(evaluation: RuleEvaluation): boolean {
    return evaluation.status === OccupancyStatus.Filled
}

export function isOverbooked(evaluation: RuleEvaluation): boolean {
    return evaluation.status === OccupancyStatus.Overbooked
}
