import { motion } from 'framer-motion'
import { forwardRef } from 'react'

interface ButtonProps {
    /**
     * Is this the principal call to action on the page?
     */
    type?: 'primary' | 'secondary' | 'danger'
    /**
     * How large should the button be?
     */
    size?: 'small' | 'medium' | 'large'
    /**
     * Button contents
     */
    children: React.ReactNode
    /**
     * Starting Icon
     */
    startIcon?: React.ReactNode
    /**
     * Ending Icon
     */
    endIcon?: React.ReactNode
    /**
     * is the button disabled?
     */
    disabled?: boolean
    /**
     * Gives the button the maximum width of its parent container
     */
    fullWidth?: boolean
    /**
     * Optional click handler
     */
    onClick?: () => void
    'aria-label'?: string
}

const baseStyles = 'flex flex-row items-center justify-center gap-1 disabled:opacity-50 disabled:cursor-not-allowed text-sm'
const typeStyles = {
    primary: 'bg-diBlue-500 text-white rounded',
    secondary: 'bg-slate-100 text-gray-800 rounded',
    danger: 'bg-diRed-500 text-white rounded',
}

const sizeStyles = { small: 'py-1 px-2', medium: 'py-2 px-4', large: 'py-3 px-6' }

/**
 * Primary UI component for user interaction
 *
 * DO NOT USE THIS COMPONENT
 */
export const HeroButton = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ type = 'primary', size = 'medium', children, startIcon, endIcon, disabled = false, ...props }, ref) => {
        return (
            <motion.button
                // just an example of how to handle micro interactions with framer-motion
                // I feel framer offers more flexiblity and control over animations
                // while also making the animation code more readable
                // seems it does conflict with tailwind state selectors, will need to investigate further
                whileTap={{ scale: 0.95 }}
                whileHover={{ opacity: 0.9 }}
                transition={{ type: 'linear' }}
                disabled={disabled}
                type="button"
                ref={ref}
                className={`${baseStyles} ${typeStyles[type]} ${sizeStyles[size]} ${props.fullWidth && 'w-full'}`}
                {...props}
            >
                {startIcon}
                {children}
                {endIcon}
            </motion.button>
        )
    }
)
