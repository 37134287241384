import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { selectGetOccupancies, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'

export function useOccupancyData(surgery: UnScheduledSurgery, isLoading: boolean, selectedMonth: Dayjs) {
    const getOccupancies = useStore(selectGetOccupancies)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const surgeryTypeId = surgery?.bookingId ? (surgery.surgeryType?.id ?? 0) : 0

    const occupancyData = useMemo(() => {
        return isLoading ? {} : getOccupancies.byMonth(selectedMonth.startOf('month'), departmentKey, true, surgeryTypeId)
    }, [isLoading, getOccupancies, selectedMonth, departmentKey, surgeryTypeId])

    return occupancyData
}
