import { MouseEventHandler, ReactNode } from 'react'

type Props = {
    label: string | ReactNode
    onClick?: MouseEventHandler
}

export const TableHeaderCell = ({ label, onClick }: Props) => (
    <th scope="col" className={`sticky top-0 select-none whitespace-nowrap bg-white p-2 text-left font-semibold text-[#4138C2]`} onClick={onClick}>
        <span className="cursor-pointer hover:text-[#231AA2]">{label}</span>
    </th>
)
