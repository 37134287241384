import { useMemo } from 'react'

import { selectGetScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'

import { FormattedWaitingListItem, WaitingListItem } from '../shared/columns'

export const useGetWaitingListSurgery = (item: FormattedWaitingListItem | undefined) => {
    const getUnScheduledSurgeries = useStore(selectGetUnScheduledSurgeries)
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)

    const bookingId = item?.BookingId
    const isUnscheduledItem = item?.BookedStatus !== 'Scheduled'

    const surgery: WaitingListItem | null = useMemo(() => {
        if (!bookingId) return null

        return isUnscheduledItem ? getUnScheduledSurgeries.byBookingId(bookingId) : getScheduledSurgeries.byBookingId(bookingId)
    }, [bookingId, getScheduledSurgeries, getUnScheduledSurgeries, isUnscheduledItem])

    return surgery
}
