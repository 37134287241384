import {
    BlockRules,
    CapacityRule,
    GetResolvedPatientGroups,
    isMaxCountBlockRules,
    isMaxDurationAndCountBlockRules,
    isMaxDurationBlockRules,
    isMaxKnifeDurationAndCountBlockRules,
    isMaxKnifeDurationBlockRules,
    isMaxWeightBlockRules,
    PatientGroupConstraints,
} from '~/store/selectors'
import { isNullish } from '~/utils/guards'

import {
    getMaxCountBlockRules,
    getMaxDurationAndMaxCountBlockRules,
    getMaxDurationBlockRules,
    getMaxKnifeTimeAndMaxCountBlockRules,
    getMaxKnifeTimeBlockRules,
    getMaxWeightBlockRules,
} from './implementations'

export function getAllPatientGroupConstraints(blockRule: BlockRules): PatientGroupConstraints[] {
    if (isNullish(blockRule)) {
        return []
    }

    if (isMaxCountBlockRules(blockRule) || isMaxWeightBlockRules(blockRule)) {
        return blockRule.parameters.constraints.map(constraint => constraint.patient_group)
    }

    return blockRule.parameters.constraints?.patient_groups?.map(constraint => constraint.patient_group) ?? []
}

/**
 * Returns the implementation of the block as a composition of rules
 * @param blockRule
 * @returns a list of rules
 */
export function getBlockRules(blockRule: BlockRules, selectGetPatientGroups: GetResolvedPatientGroups): CapacityRule[] {
    if (isMaxCountBlockRules(blockRule)) {
        return getMaxCountBlockRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxWeightBlockRules(blockRule)) {
        return getMaxWeightBlockRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxDurationBlockRules(blockRule)) {
        return getMaxDurationBlockRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxDurationAndCountBlockRules(blockRule)) {
        return getMaxDurationAndMaxCountBlockRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxKnifeDurationBlockRules(blockRule)) {
        return getMaxKnifeTimeBlockRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxKnifeDurationAndCountBlockRules(blockRule)) {
        return getMaxKnifeTimeAndMaxCountBlockRules(blockRule.parameters, selectGetPatientGroups)
    }

    return []
}
