import { ComponentProps, FC } from 'react'

export const DiLogoOutlined: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="19" viewBox="0 0 100 19" fill="currentColor" {...props}>
        <g clipPath="url(#clip0_3291_52629)">
            <path
                d="M7.38194 5.01285C7.38194 5.12848 7.26899 5.20554 7.17452 5.18627C6.57022 5.03211 5.92791 4.95505 5.26713 4.95505C3.18927 4.93579 0.734047 5.82219 0.734047 10.0037C0.734047 14.6092 3.45369 15.3029 5.07819 15.3029C8.87397 15.3029 9.15738 12.8171 9.15738 12.0656V1.6214C9.15738 1.52503 9.08191 1.44797 8.98744 1.44797H7.55241C7.45794 1.44797 7.38194 1.52503 7.38194 1.6214V5.01285ZM7.38194 12.1234C7.38194 12.4896 7.38194 13.6072 5.07819 13.6072C3.88805 13.6072 2.50949 13.2025 2.50949 10.0037C2.50949 7.63353 3.30274 6.6508 5.24813 6.6508C5.92791 6.6508 6.60769 6.76639 7.26899 7.01692C7.34447 7.03619 7.38194 7.11324 7.38194 7.19035V12.1234Z"
                fill="#3C3C3B"
            />
            <path
                d="M15.7863 4.91652C14.4647 4.91652 13.4255 5.30191 12.6893 6.05341C11.4237 7.34451 11.4427 9.3678 11.4427 10.1386V10.2349C11.4427 14.4165 13.8979 15.2837 15.9567 15.2837C17.2973 15.2837 18.4684 15.0524 19.4126 14.59L19.5261 14.5321V12.5859L19.2047 12.7786C18.4494 13.2411 17.4298 13.453 16.0887 13.453C14.4077 13.453 13.558 12.8171 13.2936 11.3334C13.2746 11.2177 13.35 11.1214 13.4635 11.1214H20.0739L20.0924 10.9287C20.0924 10.9094 20.1304 10.6011 20.1304 10.2349V10.1964C20.1304 9.38712 20.0169 7.32525 18.7519 6.03414C18.0151 5.30191 17.0139 4.91652 15.7863 4.91652ZM18.1285 9.48344H13.4445C13.35 9.48344 13.2556 9.38712 13.2746 9.29075C13.4635 7.36378 14.1433 6.67006 15.7863 6.67006C17.7887 6.67006 18.223 8.44287 18.2985 9.29075C18.3175 9.40638 18.2415 9.48344 18.1285 9.48344Z"
                fill="#3C3C3B"
            />
            <path
                d="M26.6273 4.91652C25.3053 4.91652 24.2666 5.30191 23.5298 6.05341C22.2642 7.34451 22.2832 9.3678 22.2832 10.1386V10.2349C22.2832 14.4165 24.7384 15.2837 26.7973 15.2837C28.1378 15.2837 29.309 15.0524 30.2532 14.59L30.3667 14.5321V12.5859L30.0458 12.7786C29.29 13.2411 28.2703 13.453 26.9292 13.453C25.2483 13.453 24.3985 12.8171 24.1341 11.3334C24.1151 11.2177 24.1906 11.1214 24.3041 11.1214H30.9145L30.933 10.9287C30.933 10.9094 30.971 10.6011 30.971 10.2349V10.1964C30.971 9.3678 30.8575 7.32525 29.5924 6.03414C28.8556 5.30191 27.855 4.91652 26.6273 4.91652ZM26.6273 6.67006C28.6292 6.67006 29.0446 8.44287 29.139 9.29075C29.158 9.38712 29.0636 9.48344 28.9691 9.48344H24.2851C24.1906 9.48344 24.0967 9.38712 24.1151 9.29075C24.2851 7.36378 24.9838 6.67006 26.6273 6.67006Z"
                fill="#3C3C3B"
            />
            <path
                d="M37.317 4.91652C33.5207 4.91652 33.2372 7.4023 33.2372 8.17307V18.5209C33.2372 18.6173 33.3127 18.6944 33.4072 18.6944H34.8427C34.9372 18.6944 35.0127 18.6173 35.0127 18.5209V15.2258C35.0127 15.1102 35.1262 15.0331 35.2201 15.0524C35.8624 15.2066 36.5047 15.2837 37.128 15.2837C39.1864 15.2837 41.6416 14.4165 41.6416 10.2349C41.6606 5.61023 38.9409 4.91652 37.317 4.91652ZM35.0317 8.13454C35.0317 7.76842 35.0317 6.63154 37.3354 6.63154C38.5256 6.63154 39.9041 7.03619 39.9041 10.2349C39.9041 12.6051 39.1109 13.5879 37.1655 13.5879C36.4477 13.5879 35.7679 13.4723 35.1446 13.2218C35.0692 13.2025 35.0317 13.1254 35.0317 13.0484V8.13454Z"
                fill="#3C3C3B"
            />
            <path
                d="M44.3802 15.1488H45.8153C45.9097 15.1488 45.9857 15.0717 45.9857 14.9753V5.28265C45.9857 5.18628 45.9097 5.10922 45.8153 5.10922H44.3802C44.2858 5.10922 44.2103 5.18628 44.2103 5.28265V14.9753C44.2103 15.0717 44.2858 15.1488 44.3802 15.1488Z"
                fill="#FF8700"
            />
            <path
                d="M53.2379 4.91652C51.217 4.91652 49.6115 5.64876 49.1582 5.87998L49.0452 5.93783V14.9561C49.0452 15.0524 49.1207 15.1295 49.2152 15.1295H50.6502C50.7447 15.1295 50.8201 15.0524 50.8201 14.9561V7.20961C50.8201 7.1325 50.8771 7.05545 50.9526 7.03618C51.6889 6.84349 52.4067 6.74712 53.0864 6.74712C55.1453 6.74712 55.4097 7.76842 55.4097 8.67409V14.9753C55.4097 15.0717 55.4852 15.1488 55.5797 15.1488H57.0147C57.1091 15.1488 57.1851 15.0717 57.1851 14.9753V8.67409C57.1851 6.32321 55.7116 4.91652 53.2379 4.91652Z"
                fill="#FF8700"
            />
            <path
                d="M63.1337 9.05953C62.0576 8.7512 61.3963 8.51993 61.3963 7.94185C61.3963 7.11324 62.0386 6.68933 63.3227 6.68933C64.0595 6.68933 64.7962 6.90128 65.476 7.30593L65.7969 7.49868V5.59097L65.6834 5.53313C64.8337 5.10921 64.0215 4.91652 63.1717 4.91652C60.5466 4.91652 59.6214 6.53516 59.6214 7.92259C59.6214 9.88808 61.1889 10.3313 62.5484 10.736C63.6816 11.0636 64.6638 11.3526 64.6638 12.3932C64.6638 13.1254 64.003 13.5108 62.7184 13.5108C61.8307 13.5108 60.8675 13.2218 60.0367 12.7015L59.7158 12.4896V14.4743L59.8288 14.5321C60.7355 15.0524 61.6987 15.2837 62.7754 15.2837C65.0416 15.2837 66.4392 14.166 66.4392 12.3932C66.4582 10.023 64.5318 9.46417 63.1337 9.05953Z"
                fill="#FF8700"
            />
            <path
                d="M68.9319 15.1488H70.3675C70.4619 15.1488 70.5374 15.0717 70.5374 14.9753V5.28265C70.5374 5.18628 70.4619 5.10922 70.3675 5.10922H68.9319C68.8374 5.10922 68.762 5.18628 68.762 5.28265V14.9753C68.762 15.0717 68.8374 15.1488 68.9319 15.1488Z"
                fill="#FF8700"
            />
            <path
                d="M77.2043 4.91652C75.9387 4.91652 72.9926 5.41749 72.9926 10.0615C72.9926 14.1082 75.3724 14.9561 77.3742 14.9561C77.9785 14.9561 78.5829 14.879 79.1497 14.7441C79.2626 14.7248 79.3571 14.8019 79.3571 14.9175V15.3607C79.3571 16.2664 79.0362 17.1143 76.6755 17.1143C75.5988 17.1143 74.7491 16.9216 74.2012 16.7674L73.9368 16.6903V18.4632L74.1068 18.5017C75.0325 18.733 75.9387 18.8485 76.8454 18.8485C80.4148 18.8485 81.1515 16.9216 81.1515 15.3222V8.07675C81.1515 7.34451 80.8681 4.91652 77.2043 4.91652ZM77.2043 6.63154C79.3761 6.63154 79.3761 7.69137 79.3761 8.03822V12.7593C79.3761 12.8364 79.3381 12.8942 79.2626 12.9327C78.6583 13.1447 78.0165 13.2603 77.3742 13.2603C76.0332 13.2603 74.7681 12.8749 74.7681 10.0808C74.7865 7.01692 76.0712 6.63154 77.2043 6.63154Z"
                fill="#FF8700"
            />
            <path
                d="M88.2148 4.91653C87.2896 4.91653 86.4393 5.12848 85.7976 5.34044C85.6841 5.37897 85.5706 5.30191 85.5706 5.16701V1.60213C85.5706 1.50576 85.4952 1.42871 85.4007 1.42871H83.9656C83.8712 1.42871 83.7952 1.50576 83.7952 1.60213V14.9561C83.7952 15.0524 83.8712 15.1295 83.9656 15.1295H85.4007C85.4952 15.1295 85.5706 15.0524 85.5706 14.9561V7.36378C85.5706 7.28667 85.6086 7.22888 85.6841 7.19035C86.4773 6.88202 87.2896 6.72786 88.0638 6.72786C89.5933 6.72786 90.2731 7.49868 90.2731 9.21369V14.9561C90.2731 15.0524 90.3491 15.1295 90.4436 15.1295H91.8786C91.9731 15.1295 92.0486 15.0524 92.0486 14.9561V9.21369C92.0486 6.49664 90.651 4.91653 88.2148 4.91653Z"
                fill="#FF8700"
            />
            <path
                d="M99.5467 6.63154V5.28265C99.5467 5.18633 99.4707 5.10922 99.3762 5.10922H97.2799C97.186 5.10922 97.11 5.03216 97.11 4.93579V2.56559C97.11 2.46927 97.0345 2.39217 96.94 2.39217H95.505C95.4105 2.39217 95.3351 2.46927 95.3351 2.56559V4.93579C95.3351 5.03216 95.2596 5.10922 95.1651 5.10922H93.8615C93.7675 5.10922 93.6915 5.18633 93.6915 5.28265V6.63154C93.6915 6.72786 93.7675 6.80497 93.8615 6.80497H95.1651C95.2596 6.80497 95.3351 6.88203 95.3351 6.9784V12.3547C95.3351 14.9946 97.2614 15.2837 98.0922 15.2837C98.5455 15.2837 98.9799 15.2066 99.4142 15.0332L99.5467 14.9753V13.164L99.2443 13.2989C98.9419 13.4338 98.621 13.5109 98.2621 13.5109C97.3744 13.5109 97.11 13.2603 97.11 12.3932V6.93987C97.11 6.8435 97.186 6.76644 97.2799 6.76644H99.3762C99.4707 6.80497 99.5467 6.72786 99.5467 6.63154Z"
                fill="#FF8700"
            />
            <path
                d="M45.098 0.1954C44.4367 0.1954 43.8889 0.754264 43.8889 1.42871C43.8889 2.10316 44.4367 2.66197 45.098 2.66197C45.7588 2.66197 46.3066 2.10316 46.3066 1.42871C46.3066 0.754264 45.7778 0.1954 45.098 0.1954Z"
                fill="#FF8700"
            />
            <path
                d="M69.6497 0.1954C68.9889 0.1954 68.4411 0.754264 68.4411 1.42871C68.4411 2.10316 68.9889 2.66197 69.6497 2.66197C70.311 2.66197 70.8583 2.10316 70.8583 1.42871C70.8773 0.754264 70.3295 0.1954 69.6497 0.1954Z"
                fill="#FF8700"
            />
        </g>
        <defs>
            <clipPath id="clip0_3291_52629">
                <rect width="99.2222" height="19" fill="white" transform="translate(0.388885)" />
            </clipPath>
        </defs>
    </svg>
)
