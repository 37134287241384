import { MultipleSelectWithTitle } from '~/components'
import { selectGetPractitioners } from '~/store/selectors'
import { selectPractitionerValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

export const PractitionerFilter = () => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getPractitioners = useStore(selectGetPractitioners)
    const practitionerOptions = getPractitioners.byDepartmentKey(departmentKey).map(({ short_name }) => ({ label: short_name, value: short_name }))
    const practitionerValues = useStore(selectPractitionerValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const removeAllFilterByType = useStore(state => state.appFilters.actions.removeAllFilterByType)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'practitioner', value, label })
        setSelectedPage(1)
    }

    const handleEmptyFilter = () => {
        removeAllFilterByType('practitioner')
        setSelectedPage(1)
    }

    return (
        <>
            <div className="hidden" data-test="kirurger-filter" />
            <MultipleSelectWithTitle
                title="Kirurger"
                values={practitionerValues}
                options={practitionerOptions}
                onChange={({ value, label }) => handleOnChange(value, label)}
                emptyFilter={handleEmptyFilter}
            />
        </>
    )
}
