import keyBy from 'lodash/keyBy'

import { ExternalSurgeryTypeId } from '../hospitalSurgeryTypes'
import { ResolvedSurgeryTypeGroup, ResolvedSurgeryTypeGroupMetadata, SurgeryTypeGroupCode, SurgeryTypeGroupId, SurgeryTypeMetadata } from '../surgeryTypeGroups'
import { SurgeryTypeGroup, SurgeryTypeGroupHierarchy } from './../entities'

export function getSurgeryTypeGroupOutParentChildKey(parentId: SurgeryTypeGroupId, childId: SurgeryTypeGroupId): string {
    return `${parentId}::${childId}`
}

function getUniqueSurgeryTypeWeights(metadatas: SurgeryTypeMetadata[]) {
    const uniqueWeights = new Set<number>(metadatas.map(metadata => metadata?.weight ?? 1.0))
    return [...uniqueWeights].sort((a, b) => a - b) // sort with the lowest first.
}

/**
 * Resolves the weight of each surgery type in the group, taking into account that the hierarchy may override the weight.
 */
function resolveSurgeryTypeGroup(
    surgeryTypeGroup: SurgeryTypeGroup,
    hierarchy: Record<string, SurgeryTypeGroupHierarchy>,
    weight?: number | null
): ResolvedSurgeryTypeGroupMetadata {
    const resolvedSurgeryTypes: Record<ExternalSurgeryTypeId, SurgeryTypeMetadata> = {}

    for (const child of surgeryTypeGroup.subgroups) {
        // Check if the hierarchy overrides the weight
        const key = getSurgeryTypeGroupOutParentChildKey(surgeryTypeGroup.id, child.id)
        const child_weight = hierarchy[key]?.weight ?? weight

        // Recursively resolve the child
        const childResults = resolveSurgeryTypeGroup(child, hierarchy, child_weight)
        for (const externalSurgeryTypeId in childResults.resolvedSurgeryTypes) {
            const metadata = childResults.resolvedSurgeryTypes[Number(externalSurgeryTypeId)]
            if (metadata) {
                resolvedSurgeryTypes[Number(externalSurgeryTypeId)] = metadata
            }
        }
    }

    surgeryTypeGroup.surgeryTypes.reduce((acc, surgeryType) => {
        // Check if the weight was overridden in the hierarchy
        acc[surgeryType.external_surgery_type_id] = { weight: weight ?? surgeryType.weight }
        return acc
    }, resolvedSurgeryTypes)

    return {
        resolvedSurgeryTypes,
        sortedUniqueSurgeryWeights: getUniqueSurgeryTypeWeights(Object.values(resolvedSurgeryTypes)),
    }
}

type ResolvedSurgeryTypeGroupsById = Record<SurgeryTypeGroupId, ResolvedSurgeryTypeGroup>
type ResolvedSurgeryTypeGroupsByCode = Record<SurgeryTypeGroupCode, ResolvedSurgeryTypeGroup>
type ResolvedSurgeryTypeGroupsByHospitalId = Record<ExternalSurgeryTypeId, ResolvedSurgeryTypeGroup[]>

/**
 * Resolves the dips surgery types + metadata of each surgery type group by their hierarchy
 */
export function resolveSurgeryTypeGroups(surgeryTypeGroupHierarchies: SurgeryTypeGroupHierarchy[], surgeryTypeGroups: SurgeryTypeGroup[]) {
    const resolvedSurgeryTypeGroupsById: ResolvedSurgeryTypeGroupsById = {}
    const resolvedSurgeryTypeGroupsByCode: ResolvedSurgeryTypeGroupsByCode = {}
    const resolvedSurgeryTypeGroupsByHospitalId: ResolvedSurgeryTypeGroupsByHospitalId = {}

    const hierarchy = keyBy(surgeryTypeGroupHierarchies, hierarchy =>
        getSurgeryTypeGroupOutParentChildKey(hierarchy.parent_surgery_type_group_id, hierarchy.child_surgery_type_group_id)
    )

    // resolvedSurgeryTypeGroupsById
    surgeryTypeGroups.reduce((resolved, surgeryTypeGroup) => {
        resolved[surgeryTypeGroup.id] = {
            ...surgeryTypeGroup,
            ...resolveSurgeryTypeGroup(surgeryTypeGroup, hierarchy),
            resolvedChildren: [],
        }
        return resolved
    }, resolvedSurgeryTypeGroupsById)

    // add the resolved children
    for (const surgeryTypeGroup of Object.values(resolvedSurgeryTypeGroupsById)) {
        surgeryTypeGroup.resolvedChildren = surgeryTypeGroup.subgroups.map(child => resolvedSurgeryTypeGroupsById[child.id]).filter(Boolean)
    }

    for (const surgeryTypeGroupId in resolvedSurgeryTypeGroupsById) {
        const surgeryTypeGroup = resolvedSurgeryTypeGroupsById[surgeryTypeGroupId]

        // resolvedSurgeryTypeGroupsByCode
        if (surgeryTypeGroup?.code) {
            resolvedSurgeryTypeGroupsByCode[surgeryTypeGroup.code] = surgeryTypeGroup
        }

        // resolvedSurgeryTypeGroupsByHospitalId
        for (const _hospitalId in surgeryTypeGroup?.resolvedSurgeryTypes) {
            const hospitalId = Number(_hospitalId)
            ;(resolvedSurgeryTypeGroupsByHospitalId[hospitalId] ??= []).push(surgeryTypeGroup)
        }
    }

    return { resolvedSurgeryTypeGroupsById, resolvedSurgeryTypeGroupsByCode, resolvedSurgeryTypeGroupsByHospitalId }
}
