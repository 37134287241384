import { FormEvent, useEffect, useState } from 'react'

import { Button, Show, Spinner } from '~/components'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { isNullish } from '~/utils/guards'

import { useGetWaitingListSurgery } from '../../hooks/useGetWaitingListSurgery'
import { FormattedWaitingListItem } from '../../shared/columns'
import { saveSurgeryMetadata } from '../../shared/saveSurgeryMetadata'
import { NotShownInDIPSHint, NotShownInDIPSWarning } from '../Comment/NotShownInDIPSWarning'
import { OriginalComment } from './OriginalComment'
import { TextArea } from './TextArea'

let timeout: NodeJS.Timeout | undefined = undefined

type Props = {
    item: FormattedWaitingListItem
}

export const Comment = ({ item }: Props) => {
    const { isLoading } = useImportEntities(() => [importSurgeryMetadata({ booking_id: item.BookingId })], [item.BookingId])

    const waitingListMutableComments_FF = useStore(state => state.featureFlags.waitingListMutableComments)

    const [comment, setComment] = useState(item.Comment)
    const [isWritingNewComment, setIsWritingNewComment] = useState(false)

    const surgery = useGetWaitingListSurgery(item)

    const diComment = surgery?.surgeryMetadata?.comment
    const deepinsightHasComment = !isNullish(diComment)
    const dipsHasComment = item.DIPSComment.trim() !== ''
    const autosave = isWritingNewComment || deepinsightHasComment || !dipsHasComment // this means that we're in a state that's syncing to our api
    const title = isWritingNewComment ? 'Ny kommentar' : dipsHasComment && !deepinsightHasComment ? 'Oppmøtekommentar fra DIPS' : 'Kommentar'

    useEffect(() => {
        if (!isLoading && deepinsightHasComment) {
            setComment(diComment)
        }
    }, [isLoading, diComment, deepinsightHasComment])

    function handleNewCommentClick() {
        setComment('')
        setIsWritingNewComment(true)
    }

    function saveComment(comment: string) {
        clearTimeout(timeout)
        timeout = setTimeout(() => saveSurgeryMetadata(surgery?.surgeryMetadata, { booking_id: item.BookingId, comment: comment.trim() }), 200)
    }
    function handleSaveClick() {
        clearTimeout(timeout)
        setIsWritingNewComment(false)
        saveSurgeryMetadata(surgery?.surgeryMetadata, { booking_id: item.BookingId, comment })
    }

    function handleCancelClick() {
        setComment(item.Comment)
        setIsWritingNewComment(false)
    }

    function handleInput(e: FormEvent<HTMLTextAreaElement>) {
        setComment(e.currentTarget.value)

        if (!isWritingNewComment) {
            saveComment(e.currentTarget.value)
        }
    }

    return (
        <div className="flex flex-col gap-2" data-test="waiting-list-comment">
            <p className="font-bold">{title}</p>
            <div className="relative">
                <TextArea value={comment} onInput={handleInput} disabled={!autosave || isLoading} />
                <Show condition={isLoading} fallback={<div hidden data-test="waiting-list-comment-loaded" />}>
                    <div className="absolute right-2 top-2">
                        <Spinner size="sm" />
                    </div>
                </Show>
            </div>
            <Show condition={!autosave && waitingListMutableComments_FF}>
                <Button onClick={handleNewCommentClick} data-test="new-comment-button" className="w-fit">
                    Skriv ny kommentar
                </Button>
            </Show>
            <Show condition={isWritingNewComment}>
                <NotShownInDIPSWarning />
                <div className="flex justify-end gap-2">
                    <Button onClick={handleSaveClick} data-test="save-button">
                        Lagre
                    </Button>
                    <Button onClick={handleCancelClick} color="secondary" data-test="abort-button">
                        Avbryt
                    </Button>
                </div>
            </Show>
            <Show condition={dipsHasComment && deepinsightHasComment && !isWritingNewComment}>
                <NotShownInDIPSHint />
            </Show>
            <Show condition={dipsHasComment && autosave}>
                <OriginalComment value={item.DIPSComment} />
            </Show>
        </div>
    )
}
