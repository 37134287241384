import { Show } from '~/components'
import { getCheckInTime } from '~/utils/transformations/surgeryInfo'

import { columns, FormattedWaitingListItem, WaitingListItem } from '../../shared/columns'

type InfoWithLabelProps = {
    label: string
    value: string
}

const InfoWithLabel = ({ label, value }: InfoWithLabelProps) => (
    <div className="flex flex-col">
        <p className="font-semibold">{label}</p>
        <p className="first-letter:capitalize">{value}</p>
    </div>
)

type PatientInfoProps = {
    item: FormattedWaitingListItem
    isUnscheduledItem: boolean
    surgery: WaitingListItem
}

export const PatientInfo = ({ item, isUnscheduledItem, surgery }: PatientInfoProps) => {
    const dateLabel = isUnscheduledItem ? columns.TentativeDate.label : columns.OperationDate.label
    const dateValue = isUnscheduledItem ? item.TentativeDate : item.OperationDate

    const checkInTime = getCheckInTime(surgery)

    return (
        <div className="flex flex-row gap-8 rounded-s bg-gray-50 px-2 py-4">
            <InfoWithLabel label="Ventet" value={`${item.WaitingTime} dager`} />
            <InfoWithLabel label={dateLabel} value={dateValue || '—'} />
            <Show condition={!isUnscheduledItem}>
                <InfoWithLabel label="Oppmøtetid" value={checkInTime} />
            </Show>
        </div>
    )
}
