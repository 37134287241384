import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import { Button, CustomForm, DISelect, Spinner } from '~/components'
import { Location, selectGetBlockSchedules, selectGetRuleDefinitions, selectRuleDefinitionOptions } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isRuleDefinitionId, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/blockEvaluation'
import { getRuleId } from '~/utils/utils'

import { useChangeBlockSchedule } from './useChangeBlockSchedule'

type Props = {
    date: Dayjs
    location: Location

    onSubmit?: () => void
    onRemove?: () => void
}

export const BlockScheduleEditor = ({ date, location, onSubmit, onRemove }: Props) => {
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    const blockSchedule = useStore(selectGetBlockSchedules).byDateAndLocationId(date, location.id)
    const getRuleDefinitions = useStore(selectGetRuleDefinitions)
    const ruleDefinitionOptions = useStore(selectRuleDefinitionOptions)

    const [parameters, setParameters] = useState<RuleInstanceParameters>(blockSchedule?.rule_instance?.parameters)
    const [ruleId, setRuledId] = useState<RuleDefinitionId>(getRuleId(blockSchedule))

    const ruleSchema = getRuleDefinitions.byRuleId(ruleId)?.param_schema
    const { isLoading, saveBlockSchedule, removeBlockSchedule } = useChangeBlockSchedule(date, location, ruleId, parameters)

    function handleRuleSchemaChange(val: string | null) {
        if (!isRuleDefinitionId(val)) return
        setRuledId(val)
        setParameters(null)
    }

    async function handleSubmit() {
        await saveBlockSchedule()
        onSubmit?.()
    }

    function handleRemove() {
        void removeBlockSchedule()
        onRemove?.()
    }

    return (
        <div className="w-[450px] cursor-auto">
            <div className="flex items-center justify-between gap-x-6 border-b p-2">
                Kapasitet
                <div className="w-full">
                    <DISelect
                        name="regler"
                        size="sm"
                        isSearchable
                        value={ruleDefinitionOptions.find(option => option.value === ruleId)}
                        options={ruleDefinitionOptions}
                        onChange={val => {
                            if (!val) return
                            handleRuleSchemaChange(val.value)
                        }}
                    />
                </div>
            </div>

            <div className="border-b p-2">
                <CustomForm schema={ruleSchema} formData={parameters} onChange={setParameters} onSubmit={handleSubmit}>
                    <button ref={submitButtonRef} type="submit" hidden />
                </CustomForm>
            </div>

            <div className="flex justify-end gap-x-2 p-2">
                {blockSchedule && (
                    <Button data-test="delete-block" disabled={isLoading} onClick={handleRemove} color="delete">
                        {isLoading ? <Spinner size="sm" /> : 'Fjern'}
                    </Button>
                )}
                <Button disabled={isLoading} onClick={() => submitButtonRef.current?.click()} type="submit">
                    {isLoading ? <Spinner size="sm" /> : 'Lagre'}
                </Button>
            </div>
        </div>
    )
}
