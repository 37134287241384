import clsx from 'clsx'
import { memo, RefObject } from 'react'

import { ExtraRow, Id } from '../utils'

export type FootersProps<Cell extends { id: Id }> = {
    cols: Cell[]
    gridTemplateColumns: string
    footerRef?: RefObject<HTMLDivElement>
    footers?: ExtraRow<Cell>[]
}

function FootersComponent<Header extends { id: Id }>({ footers, cols, gridTemplateColumns, footerRef }: FootersProps<Header>) {
    return (
        <div
            ref={footerRef}
            className="sticky -bottom-px grid whitespace-nowrap border-t border-t-[#8391c3] bg-white"
            style={{
                gridTemplateColumns,
                gridColumn: `1 / span ${cols.length + 1}`,
            }}
        >
            {footers?.map((footer, idx) => (
                <div key={footer.key} className="grid" style={{ gridTemplateColumns, gridColumn: `1 / span ${cols.length + 1}` }}>
                    <div
                        className={clsx('sticky left-0 truncate border-r border-r-[#8391c3] bg-white p-1', {
                            'border-t': idx !== 0,
                        })}
                    >
                        {footer.rowHeader()}
                    </div>

                    {cols.map(header => (
                        <div key={header.id} className={clsx('border-r border-t bg-white p-1 last:border-r-0', footer.cellClassName?.(header))}>
                            {footer.cellRender(header)}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export const Footers = memo(FootersComponent) as typeof FootersComponent
