import { Dayjs } from 'dayjs'
import { motion } from 'framer-motion'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { AgeGroup, Button, PractitionerTag, Spinner, Tag } from '~/components'
import { CheckOutlined, ExclamationCircleOutlined, PhoneIconOutlined } from '~/icons'
import { type AgeGroup as AgeGroupType } from '~/store/selectors'
import { UnScheduledSurgery } from '~/store/selectors'
import { getSurgeonsAndAssistants, surgeryResourceNames } from '~/utils/dips'
import { getNewDateWithTime } from '~/utils/extendedDayjs'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

import { BookingFormInput } from './BookingFormInput'
import { BookingInfo } from './BookingInfo'

interface Props {
    waitingListItem: UnScheduledSurgery
    isLoading: boolean
    defaultCheckInTime: string
    defaultKnifeTime: string
    defaultError?: string
    onSubmit: (checkInTime: string, startTime: string, mainSurgeonShortName: string) => void
    onClose: () => void
    bookingDate: Dayjs
    bookingId?: string
    operation: string
    location: string
    displayDate: string
    comment?: string
    asa?: string | null
    dayOvernight?: { label: string; icon: JSX.Element } | null
    patientAgeGroup?: AgeGroupType
    isShortNotice?: boolean
    isSuccess?: boolean
}

const labelInputStyles = 'grid max-w-[332px] grid-cols-[1fr_132px] items-center justify-between text-gray-500 gap-4'

const errorStyles =
    'absolute bottom-[-36px] flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white'

export const BookingForm = ({
    waitingListItem,
    operation,
    location,
    bookingDate,
    comment,
    displayDate,
    defaultCheckInTime,
    defaultKnifeTime,
    isLoading,
    isSuccess,
    patientAgeGroup,
    isShortNotice,
    defaultError,
    onSubmit,
    onClose,
}: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>(defaultCheckInTime)
    const [checkInTimeError, setCheckInTimeError] = useState<string | undefined>()
    const [startTime, setStartTime] = useState<string>()
    const [hasInitialFormData, setHasInitialFormData] = useState(false)
    const [startTimeError, setStartTimeError] = useState<string | undefined>()
    const [multiInputError, setMultiInputError] = useState<string | undefined>(defaultError)
    const [mainSurgeonShortName, setMainSurgeonShortName] = useState<string | undefined>(
        waitingListItem.surgeryResources?.find(resource => resource.typeId === surgeryResourceNames.Surgeon)?.shortName ?? undefined
    )
    const { patientNameDetailed, asa, dayOvernight } = useMemo(() => getFormattedSurgery(waitingListItem), [waitingListItem])

    const hasWard = Boolean(waitingListItem.contact?.ward)
    const disabled = isLoading || isSuccess || !hasWard

    useEffect(() => {
        const initialFormData = defaultCheckInTime || defaultKnifeTime

        if (!hasInitialFormData && initialFormData) {
            setCheckInTime(defaultCheckInTime)
            setHasInitialFormData(true)
        }
    }, [hasInitialFormData, defaultCheckInTime, defaultKnifeTime])

    const resetErrors = () => {
        setCheckInTimeError(undefined)
        setStartTimeError(undefined)
        setMultiInputError(undefined)
    }

    const handleSubmit = () => {
        if (multiInputError) {
            return
        }

        if (!checkInTime || !startTime) {
            setMultiInputError('Stue inn og Oppmøtetid må fylles ut')
            return
        }

        if (!mainSurgeonShortName) {
            setMultiInputError('Kirurg må velges')
            return
        }

        onSubmit(checkInTime, startTime, mainSurgeonShortName)
    }

    const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value)

        const checkin = getNewDateWithTime(bookingDate, checkInTime)
        const start = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setStartTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    const handleCheckinTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckInTime(e.target.value)

        if (!startTime) {
            return
        }

        const start = getNewDateWithTime(bookingDate, startTime ?? '00:00')

        const checkin = getNewDateWithTime(bookingDate, e.target.value)

        if (start.isBefore(checkin)) {
            setCheckInTimeError('Stue inn kan ikke være før oppmøtetid')
            return
        }
        resetErrors()
    }

    return (
        <>
            {!hasWard && (
                <div className="rounded bg-red-200 px-3 py-2">
                    Denne pasienten mangler sengepost. <br /> Dette må endres i DIPS før pasienten kan settes opp til operasjon i Hero.
                </div>
            )}

            <p className="font-semibold">Om pasienten og inngrepet</p>
            <div className="relative mb-5 grid w-[585px] justify-between gap-6">
                <BookingInfo label="Operasjon">{operation}</BookingInfo>
                <BookingInfo label="Merknad">
                    <div className="flex gap-2">
                        {asa && (
                            <Tag color="ASA" textSize="sm">
                                {asa}
                            </Tag>
                        )}
                        {dayOvernight && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                {dayOvernight.icon}
                                <p>{dayOvernight.label}</p>
                            </div>
                        )}
                        {isShortNotice && (
                            <div className="flex items-center gap-1 rounded border px-2 py-0.5">
                                <PhoneIconOutlined />
                                <p>Kort Varsel</p>
                            </div>
                        )}
                        {patientAgeGroup && patientAgeGroup.age_group_code === 'child' && <AgeGroup ageGroupCode={patientAgeGroup?.age_group_code} />}
                    </div>
                </BookingInfo>
                <BookingInfo label="Pasient">{patientNameDetailed}</BookingInfo>
                <BookingInfo label="Kirurg">
                    <div className="flex gap-2">
                        {getSurgeonsAndAssistants(waitingListItem.surgeryResources).map(practitioner => {
                            const isMainSurgeon = practitioner.short_name === mainSurgeonShortName

                            return (
                                <Button
                                    key={practitioner.short_name}
                                    color={isMainSurgeon ? 'primary' : 'white'}
                                    onClick={() => setMainSurgeonShortName(practitioner.short_name)}
                                >
                                    <PractitionerTag
                                        shortName={practitioner.short_name}
                                        size="md"
                                        color={isMainSurgeon ? 'practitioner-white' : 'practitioner'}
                                    />
                                </Button>
                            )
                        })}
                    </div>
                </BookingInfo>
                <BookingInfo label="Stue">{location}</BookingInfo>
                <BookingInfo label="Dato">
                    <div className="capitalize">{displayDate}</div>
                </BookingInfo>
                <BookingInfo label="Kommentar">{comment}</BookingInfo>
                <div className="grid grid-cols-[120px_1fr]">
                    <p className="text-gray-500">Tider</p>
                    <div className="flex flex-col gap-1">
                        <BookingFormInput
                            label="Oppmøtetid (klokkeslett)"
                            defaultValue={checkInTime}
                            onChange={handleCheckinTimeChange}
                            error={checkInTimeError}
                            id="check-in-time"
                            disabled={disabled}
                        />
                        <BookingFormInput
                            label="Stue inn (klokkeslett)"
                            defaultValue={startTime}
                            onChange={handleStartTimeChange}
                            error={startTimeError}
                            id="start-time"
                            disabled={disabled}
                        />
                        <div className={labelInputStyles}>
                            Knivtid
                            <div>&nbsp;{defaultKnifeTime}</div>
                        </div>
                    </div>
                </div>
                {multiInputError && (
                    <motion.p initial={{ opacity: 0, y: 16 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }} className={errorStyles}>
                        <ExclamationCircleOutlined height={20} />
                        {multiInputError}
                    </motion.p>
                )}
            </div>

            <div className="mt-2 flex justify-end gap-2">
                {isSuccess ? (
                    <>
                        <div className="flex items-center gap-2 text-green-700">
                            Operasjon planlagt. <CheckOutlined />
                        </div>
                        <Button color="white" onClick={onClose} data-test="success-close">
                            Lukk
                        </Button>
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <>
                                <Button disabled={true} className="flex gap-2">
                                    <Spinner size="sm" /> Venter på DIPS
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button color="delete" onClick={onClose}>
                                    Avbryt
                                </Button>
                                <Button onClick={handleSubmit} data-test="confirm-button" disabled={disabled}>
                                    Planlegg operasjon
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
