import { PillsSelect } from '~/components'
import { selectWeekdayValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const weekdaysMap = {
    1: 'Mandag',
    2: 'Tirsdag',
    3: 'Onsdag',
    4: 'Torsdag',
    5: 'Fredag',
} as const

const weekdayOptions = Object.entries(weekdaysMap).map(([key, value]) => ({ label: value, value: key }))

export const WeekdayFilter = () => {
    const weekdayValues = useStore(selectWeekdayValues)
    const { toggleFilteredBy } = useStore(state => state.appFilters.actions)

    return (
        <PillsSelect
            title="Ukedager"
            values={weekdayValues}
            options={weekdayOptions}
            onChange={({ value, label }) => toggleFilteredBy({ type: 'weekday', value, label })}
        />
    )
}
