import { PillsSelect } from '~/components'
import { Option, selectConfirmedValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const confirmedOptions: Option[] = [
    { label: 'Bekreftet', value: 'Bekreftet' },
    { label: 'Ikke bekreftet', value: 'Ikke bekreftet' },
]

export const ConfirmedFilter = () => {
    const confirmedValues = useStore(selectConfirmedValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'confirmed', value, label })
        setSelectedPage(1)
    }

    return <PillsSelect values={confirmedValues} options={confirmedOptions} onChange={({ value, label }) => handleOnChange(value, label)} />
}
