import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { AnimatePresence } from 'framer-motion'

import { DataGrid, HolidayTag, Show } from '~/components'
import { Practitioner, selectGetPractitioners, selectGetPractitionerSchedules, selectMonthIdDates } from '~/store/selectors'
import { selectPractitionerValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, format, getToday, isHoliday, isSameDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'
import { getHolidayStyles } from '~/utils/utils'

import { getFridayStyles } from '../shared/utils'
import { CellScheduleLocations } from './Cell/CellScheduleLocations'
import { CellScheduleStatuses } from './Cell/CellScheduleStatuses'
import { Popover } from './KeyboardInteraction/Popover'
import { PractitionerScheduleModal } from './Modal/PractitionerScheduleModal'
import { useCreateInteractiveRows } from './useCreateInteractiveRows/useCreateInteractiveRows'

type Props = {
    isLoading: boolean
}

export const PractitionerTable = ({ isLoading }: Props) => {
    const { createCountableRow, createEditableRow } = useCreateInteractiveRows()

    const showLocations = useStore(state => state.surgeonScheduler.showLocations)
    const departmentKey = useStore(state => state.appFilters.departmentKey)

    const monthIdDates = useStore(selectMonthIdDates)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    function getCommentStyle(practitioner: Practitioner, date: Dayjs) {
        if (isHoliday(date)) return false

        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id)
        if (!schedule) return false

        const condition = isNotNullish(schedule.comment) && schedule.comment !== ''

        return condition ? 'bg-emerald-300/10  small-triangle-bookmark' : ''
    }

    const weekInfoLabel = `${monthIdDates.at(0)?.date.isoWeek()}-${monthIdDates.at(-1)?.date.isoWeek()}`

    return (
        <DataGrid
            data-test={isLoading ? '' : 'practitioner-table'}
            isLoading={isLoading}
            selection="multiple"
            header={{
                cells: monthIdDates,
                rowHeader: () => <div className="w-full truncate">{weekInfoLabel}</div>,
                cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date), { 'calendar-today': isSameDate(date, getToday()) }),
                cellRender: ({ date }) => {
                    const dateIsHoliday = isHoliday(date)
                    return (
                        <div className="flex h-full flex-col gap-1">
                            <span className="hidden first-letter:capitalize xl:block">{format(date, 'dd D')}</span>
                            <span className="first-letter:capitalize xl:hidden">
                                {format(date, 'dd').at(0)} {format(date, 'D')}
                            </span>
                            <Show condition={dateIsHoliday}>
                                <HolidayTag />
                            </Show>
                        </div>
                    )
                },
                extraHeaders: [createEditableRow('Ox', 'ON-CALL')],
            }}
            body={{
                rows: activePractitioners,
                rowRender: practitioner => <p className="m-0.5 w-full rounded px-1 py-2 transition group-hover:bg-gray-200/80">{practitioner.short_name}</p>,
                cellClassName: (practitioner, { date }) => clsx(getCommentStyle(practitioner, date), getFridayStyles(date), getHolidayStyles(date)),
                cellHeight: showLocations ? 'tall' : 'short',
                cellRender: (practitioner, { date }) => {
                    if (isHoliday(date)) return null

                    const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id)
                    const gap = Number(schedule?.locationAssignments.length) > 0 && Number(schedule?.statuses.length) > 0 ? ' gap-y-1' : ''

                    return (
                        <div className={`flex flex-col ${gap}`}>
                            <CellScheduleStatuses schedule={schedule} />
                            {showLocations && <CellScheduleLocations schedule={schedule} />}
                        </div>
                    )
                },
            }}
            footers={[
                createCountableRow('Operasjon', ['SURGERY']),
                createCountableRow('Dagkirurgi', ['DAY_SURGERY']),
                createCountableRow('Poliklinikk', ['POLICLINIC']),
                createCountableRow('Kveldspoliklinikk', ['EVENING_POLYCLINIC', 'EVENING_POLICLINIC']),
            ]}
            modal={cells => (
                <PractitionerScheduleModal
                    selectedCells={cells.map(({ rowId, colId }) => ({
                        date: day(colId),
                        practitionerId: Number(rowId),
                    }))}
                />
            )}
            keyboardInteractionComponent={(isModalOpen, setIsModalOpen, cells, clearSelection) => (
                <AnimatePresence>
                    <Show condition={cells.length > 0 && !isModalOpen}>
                        <Popover
                            selectedCells={cells.map(({ rowId, colId }) => ({
                                date: day(colId),
                                practitionerId: Number(rowId),
                            }))}
                            clearSelection={clearSelection}
                            setIsOpen={setIsModalOpen}
                        />
                    </Show>
                </AnimatePresence>
            )}
        />
    )
}
