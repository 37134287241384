import { Schemas } from '~/clients/api-client'

import * as imports from '../../store/di-entity.api'

type ExtractEntityTypes<SCHEMAS extends Schemas> = {
    [K in keyof SCHEMAS]: SCHEMAS[K] extends { entity_type: string } ? SCHEMAS[K]['entity_type'] : never
}[keyof SCHEMAS]

type EntityType = ExtractEntityTypes<Schemas>

// Add entity types to this list that should NOT expect WebSocket event updates.
const ignoreList = [
    'block_exception', // Deprecated
    'on_call_schedule', // Deprecated
    'checklist_item_definition', // Doesn't have an import, yet
] satisfies EntityType[]

type IgnoredEntityType = (typeof ignoreList)[number]
type RequiredEntityType = Exclude<EntityType, IgnoredEntityType>

/**
 * A partial mapping of the entity_type field to the corresponding import function that hits the `GET /<entity_type>` endpoint.
 */
export const entityTypeImportMap = {
    age_group: imports.importAgeGroups,
    block_schedule: imports.importBlockSchedules,
    department_location_assignment: imports.importDepartmentLocationAssignments,
    department_practitioner_assignment: imports.importDepartmentPractitionerAssignments,
    department: imports.importDepartments,
    hospital_surgery_type_group_association: imports.importHospitalSurgeryTypeGroupAssociations,
    hospital_surgery_type: imports.importHospitalSurgeryTypes,
    location_schedule: imports.importLocationSchedules,
    location: imports.importLocations,
    planning_period: imports.importPlanningPeriods,
    practitioner_schedule_location: imports.importPractitionerScheduleLocations,
    practitioner_schedule_status: imports.importPractitionerScheduleStatuses,
    practitioner_schedule: imports.importPractitionerSchedules,
    practitioner: imports.importPractitioners,
    rule_definition: imports.importRuleDefinitions,
    section: imports.importSections,
    speciality: imports.importSpecialities,
    surgery_type_group_age_restriction: imports.importSurgeryTypeGroupAgeRestrictions,
    surgery_type_group_hierarchy: imports.importSurgeryTypeGroupHierarchies,
    surgery_type_group: imports.importSurgeryTypeGroups,
    surgery_metadatum: imports.importSurgeryMetadata,
    block_lock: imports.importBlockLocks,
    practitioner_service_period: imports.importPractitionerServicePeriods,
    practitioner_status_definition: imports.importPractitionerStatusDefinitions,
    surgery_type_group_speciality: imports.importSurgeryTypeGroupSpecialties,
} satisfies Record<RequiredEntityType, (typeof imports)[keyof typeof imports]>
