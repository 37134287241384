import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { getTheaterRoom } from '~/utils/dips'
import { AcceptedDayInput, day, getOnlyDate, getToday } from '~/utils/extendedDayjs'

import { DepartmentKey } from '../slices/filterSlice'
import { ScheduledSurgery, selectEntities } from './entities'

function makeKey(date: Dayjs, roomCode?: string | null): string {
    return `${getOnlyDate(date)}_${roomCode ?? 'UNSPECIFIED-OR'}`
}

function isNonCancelledSurgery<T extends ScheduledSurgery>(surgery: T) {
    const finishCode = surgery.finishInformation?.finishCode
    return !Boolean(finishCode && finishCode !== 'OR')
}

export const selectGetScheduledSurgeries = createSelector(selectEntities, ({ scheduledSurgeries }) => {
    const byBookingId = keyBy(scheduledSurgeries, 'id')

    const byDateAndLocation = groupBy(scheduledSurgeries, scheduledSurgery =>
        makeKey(day(scheduledSurgery.plannedTheatreInTime), getTheaterRoom(scheduledSurgery.surgeryResources)?.shortName)
    )

    return {
        byBookingId: (bookingId: ScheduledSurgery['id']): ScheduledSurgery | null => {
            return !bookingId ? null : (byBookingId[bookingId] ?? null)
        },
        byDateAndRoomCode: (date: AcceptedDayInput, roomCode: string, removeCancelled = true): ScheduledSurgery[] => {
            const surgeries = byDateAndLocation[makeKey(day(date), roomCode)] ?? []
            if (removeCancelled) {
                return surgeries?.filter(isNonCancelledSurgery)
            }
            return surgeries
        },
    }
})

export const selectGetFutureScheduledSurgeries = createSelector(selectEntities, ({ scheduledSurgeries }) => {
    const today = getToday()

    const futureScheduledSurgeries = scheduledSurgeries
        .filter(scheduledSurgery => {
            const checkInTime = scheduledSurgery.contact?.checkInTime
            return Boolean(checkInTime) ? day(checkInTime).isSameOrAfter(today, 'day') : true // Include if no checkInTime
        })
        .filter(isNonCancelledSurgery)

    const byDepartmentId = groupBy(futureScheduledSurgeries, scheduledSurgery => {
        return scheduledSurgery.section?.department?.id
    })

    return {
        // Intended to act as a selector to search for scheduled surgeries by a search term,
        // the specific logic here is subject to change based on the actual requirements
        bySearchTerm: (searchTerm: string): ScheduledSurgery[] => {
            if (!searchTerm) return []
            const searchTermLower = searchTerm.toLowerCase()
            const filtersSurgeries = futureScheduledSurgeries.filter(surgery => {
                return `${surgery.patient?.firstName?.toLocaleLowerCase()} ${surgery.patient?.lastName?.toLocaleLowerCase()} ${surgery.patient?.nationalId?.toLocaleLowerCase()}`.includes(
                    searchTermLower
                )
            })
            return filtersSurgeries
        },
        byDepartmentKey: (departmentKey: DepartmentKey) => {
            if (departmentKey === 'all') {
                return futureScheduledSurgeries
            }

            return byDepartmentId[departmentKey] ?? []
        },
    }
})
