import { useMemo, useState } from 'react'

import { HeroDialog, Show, TextInput } from '~/components'
import { selectGetFutureScheduledSurgeries, selectGetUnScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { sortByOrderedDate } from '~/utils/dips'

import { WaitingListItem } from '../../WaitingList/shared/columns'
import { SurgerySearchOption } from './SurgerySearchOption'

type Props = { isOpen: boolean; onClose: () => void; onClickSurgery: (surgery: WaitingListItem) => void }

export const SurgerySearchDialog = ({ isOpen, onClose, onClickSurgery }: Props) => {
    const [searchValue, setSearchValue] = useState<string>('')

    const getUnscheduledSurgery = useStore(selectGetUnScheduledSurgeries)
    const getFutureScheduledSurgery = useStore(selectGetFutureScheduledSurgeries)

    const searchResults: WaitingListItem[] = useMemo(() => {
        if (searchValue.length < 3) return []
        return [...getUnscheduledSurgery.bySearchTerm(searchValue).sort(sortByOrderedDate), ...getFutureScheduledSurgery.bySearchTerm(searchValue)]
    }, [getFutureScheduledSurgery, searchValue, getUnscheduledSurgery])

    const hasSearchValue = searchValue.length > 0
    const hasSearchResults = searchResults.length > 0

    return (
        <HeroDialog
            isOpen={isOpen}
            onCloseDialog={() => {
                setSearchValue('')
                onClose()
            }}
            title="Finn pasient til denne tiden"
        >
            <div className="flex h-[640px] w-[540px] flex-col gap-4">
                <label className="flex flex-col gap-2">
                    Søk etter navn eller personnummer
                    <TextInput
                        type="search"
                        aria-label="Søk etter navn eller personnummer"
                        placeholder="Søk etter navn eller personnummer"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                    />
                </label>
                <Show condition={hasSearchValue}>
                    {hasSearchResults ? (
                        <p className="font-medium">{`${searchResults.length} ${searchResults.length === 1 ? 'resultat' : 'resultater'}`}</p>
                    ) : (
                        <div className="flex rounded bg-gray-50 px-3 py-2">
                            <p className="font-medium">Ingen resultater</p>
                        </div>
                    )}
                </Show>

                <div className="flex flex-col gap-3 overflow-y-auto">
                    {searchResults.map((surgery, i) => (
                        <SurgerySearchOption key={`${surgery.patient?.lastName} ${i}`} surgery={surgery} onClick={onClickSurgery} />
                    ))}
                </div>
            </div>
        </HeroDialog>
    )
}
