import { useState } from 'react'

import { createBatchPractitionerScheduleStatus, deleteBatchPractitionerScheduleStatus } from '~/store/di-entity.api'
import { PractitionerScheduleStatus, selectGetPractitionerSchedules } from '~/store/selectors'
import { StatusCode } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

import { DatePractitionerCell } from '../utils'
import { useCreateMissingSchedules } from './useCreateMissingSchedules'

export function createGetStatusStateFn(selectedCells: DatePractitionerCell[], scheduleStatuses: PractitionerScheduleStatus[]) {
    return (status: StatusCode) => {
        const numCellsWithStatus = scheduleStatuses.filter(scheduleStatus => scheduleStatus.status_code === status).length
        return numCellsWithStatus === 0 ? 'none' : numCellsWithStatus === selectedCells.length ? 'all' : 'some'
    }
}

export const useToggleStatuses = (selectedCells: DatePractitionerCell[], scheduleStatuses: PractitionerScheduleStatus[]) => {
    const [isLoading, setIsLoading] = useState(false)
    const getStatusState = createGetStatusStateFn(selectedCells, scheduleStatuses)
    const createMissingSchedules = useCreateMissingSchedules(selectedCells)

    async function saveStatuses(statusCode: string) {
        await createMissingSchedules()

        const getSchedules = selectGetPractitionerSchedules(useStore.getState())
        const cellsWithoutStatus = selectedCells.filter(({ date, practitionerId }) => {
            const schedule = getSchedules.byDateAndPractitionerId(date, practitionerId)
            if (!schedule) return
            return schedule.statuses.every(status => status.status_code !== statusCode)
        })

        const schedulesToCreate = cellsWithoutStatus
            .map(({ date, practitionerId }) => {
                const schedule = getSchedules.byDateAndPractitionerId(date, practitionerId)
                if (!schedule) return
                return { practitioner_schedule_id: schedule.id, status_code: statusCode }
            })
            .filter(Boolean)

        await createBatchPractitionerScheduleStatus(schedulesToCreate)
        return schedulesToCreate.length
    }

    async function deleteStatuses(statusCode: string) {
        const statusesToDelete = scheduleStatuses.filter(scheduleStatus => scheduleStatus.status_code === statusCode)
        try {
            await deleteBatchPractitionerScheduleStatus(statusesToDelete.map(({ id }) => id))
        } catch (error) {
            console.error(error)
            return -1
        }
        return statusesToDelete.length
    }

    async function toggleStatus(activity: StatusCode) {
        const state = getStatusState(activity)

        setIsLoading(true)
        if (state === 'none' || state === 'some') {
            const numberOfEdits = await saveStatuses(activity)
            void trackUserEvent('SS_SURGEON_ACTIVITY_ADDED', { numberOfEdits, activity })
        }

        if (state === 'all') {
            const numberOfEdits = await deleteStatuses(activity)
            void trackUserEvent('SS_SURGEON_ACTIVITY_REMOVED', { numberOfEdits, activity })
        }
        setIsLoading(false)
    }

    return { isLoading, toggleStatus }
}
