import { ComponentProps, FC } from 'react'

export const ArrowBackUp: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.53 3.46967C6.8229 3.76256 6.8229 4.23744 6.53 4.53033L5.14367 5.91667H10.6663C11.5725 5.91667 12.4415 6.27664 13.0823 6.91739C13.723 7.55813 14.083 8.42718 14.083 9.33333C14.083 10.2395 13.723 11.1085 13.0823 11.7493C12.4415 12.39 11.5725 12.75 10.6663 12.75H9.99967C9.58546 12.75 9.24967 12.4142 9.24967 12C9.24967 11.5858 9.58546 11.25 9.99967 11.25H10.6663C11.1747 11.25 11.6622 11.0481 12.0216 10.6886C12.3811 10.3292 12.583 9.84166 12.583 9.33333C12.583 8.825 12.3811 8.33749 12.0216 7.97804C11.6622 7.6186 11.1747 7.41667 10.6663 7.41667H5.14367L6.53 8.803C6.8229 9.0959 6.8229 9.57077 6.53 9.86366C6.23711 10.1566 5.76224 10.1566 5.46934 9.86366L2.80268 7.197C2.50978 6.9041 2.50978 6.42923 2.80268 6.13634L5.46934 3.46967C5.76224 3.17678 6.23711 3.17678 6.53 3.46967Z"
            fill="currentColor"
        />
    </svg>
)
