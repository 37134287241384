import { AnimatePresence, motion } from 'framer-motion'
import { useCallback } from 'react'

import { DipsSchemas } from '~/clients/dips-client'
import { HeroDialog } from '~/components'
import env from '~/env'
import { ExclamationCircleOutlined } from '~/icons'
import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { selectGetDipsResources } from '~/store/selectors/dipsResources'
import { useStore } from '~/store/store'
import { getPatientAgeGroup } from '~/store/utils/patientHelpers'
import { isMainSurgeon, isTheaterRoom, toAssistant, toMainSurgeon } from '~/utils/dips'
import { day, format, getFormattedDuration } from '~/utils/extendedDayjs'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

import { zeroDurationToken } from '../SurgeryCard'
import { BookingForm } from './BookingForm'
import { useScheduleSurgerySaga } from './hooks/useScheduleSurgerySaga'

type Props = { waitingListItem: UnScheduledSurgery; occupancyData: OccupancyData; isOpen: boolean; onCloseDialog: () => void }

export const BookingDialog = ({ waitingListItem, isOpen, onCloseDialog, occupancyData }: Props) => {
    const { scheduleSurgery, isLoading, isError, errorMessage, isSuccess } = useScheduleSurgerySaga()
    const getDipsResources = useStore(selectGetDipsResources)
    const operatingRoom = occupancyData.location.dipsResource
    const ageGroups = useStore(state => state.di.entities.ageGroups)
    const patientAgeGroup = waitingListItem.patient && ageGroups ? getPatientAgeGroup(waitingListItem.patient, Object.values(ageGroups.byId)) : undefined
    const displayDate = formatDateToNorskReadableIdDate(occupancyData.date).label

    /** Replaces the time in the occupancyData date, leaving the day itself the same */
    const getUpdatedTime = useCallback(
        (time: string) => {
            const [hours, minutes] = time.split(':')
            return day(occupancyData.date).set('hour', Number(hours)).set('minute', Number(minutes))
        },
        [occupancyData.date]
    )

    return (
        <HeroDialog isOpen={isOpen} onCloseDialog={onCloseDialog} title="Operasjonsbestilling">
            <div className="relative grid gap-4">
                <AnimatePresence>
                    {isError && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ type: 'linear' }}
                            data-test="booking-request-error"
                            className="absolute top-0 flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white"
                        >
                            <ExclamationCircleOutlined height={20} />
                            {errorMessage}
                        </motion.div>
                    )}
                </AnimatePresence>

                <BookingForm
                    waitingListItem={waitingListItem}
                    displayDate={displayDate}
                    bookingId={waitingListItem.bookingId}
                    defaultKnifeTime={getFormattedDuration(waitingListItem.plannedProcedureDuration ?? zeroDurationToken, true)}
                    defaultCheckInTime={format(day(waitingListItem.contact?.checkInTime), 'HH:mm')}
                    comment={waitingListItem.contact?.coordinationComment ?? ''}
                    bookingDate={occupancyData.date}
                    location={operatingRoom?.shortName ?? ''}
                    patientAgeGroup={patientAgeGroup}
                    isShortNotice={waitingListItem.contact?.isShortNotice}
                    operation={waitingListItem.surgeryType?.name ?? ''}
                    onClose={onCloseDialog}
                    onSubmit={async (checkInTime, plannedTheatreInTime, mainSurgeonShortName) => {
                        const resourceSlots: DipsSchemas['ScheduleSurgeryParams']['surgeryResources'] = (waitingListItem.surgeryResources ?? [])
                            .map(res => {
                                // We completely replace the TheaterRoom resource
                                if (isTheaterRoom(res)) {
                                    return {
                                        ...res,
                                        resourceId: occupancyData.location.dipsResource?.id,
                                        shortName: occupancyData.location.dipsResource?.shortName,
                                        name: occupancyData.location.dipsResource?.name,
                                    }
                                }

                                // If the resource is not a TheaterRoom, we apply the correct typeId and assignedResourceTypeName attributes
                                if (isMainSurgeon(res)) {
                                    if (res.shortName === mainSurgeonShortName) {
                                        return res
                                    } else {
                                        return toAssistant(res)
                                    }
                                } else {
                                    if (res.shortName === mainSurgeonShortName) {
                                        return toMainSurgeon(res)
                                    } else {
                                        return res
                                    }
                                }
                            })
                            .map(res => {
                                // Find the correct dips resource fields, if missing, by matching the shortName
                                if (!res.resourceId) {
                                    const dipsResource = getDipsResources.byShortName(res.shortName)

                                    if (dipsResource) {
                                        return { ...res, resourceId: dipsResource.id }
                                    } else {
                                        console.error(`Resource ${res.id} not found in dips resources`)
                                    }
                                }

                                return res
                            })
                            .map(res => ({
                                resourceId: res.resourceId,
                                id: res.id,
                            }))

                        await scheduleSurgery({
                            waitingListItem,
                            resourceSlots,
                            checkInTime: getUpdatedTime(checkInTime),
                            plannedTheatreInTime: getUpdatedTime(plannedTheatreInTime),
                        })
                    }}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                />
            </div>

            {env.VITE_REMOVE_BOOKING_TEST_FLAGS && <div data-test="test-flags-disabled" />}
        </HeroDialog>
    )
}
