import { Dayjs, UnitType } from 'dayjs'

// Cache to store the results of `isBefore` comparisons
const isBeforeCache = new Map<string, boolean>()

// Returns a unique key for the two dates being compared
function getCacheKey(date1: Dayjs, date2: Dayjs, unit: UnitType): string {
    return `${date1.toISOString()}_${date2.toISOString()}_${unit}`
}

/**
 * Determines whether a date is before another date.
 *
 * @param {Dayjs} date1 - The first date value.
 * @param {Dayjs} date2 - The second date value.
 * @param {UnitType} unit - The granularity level used in the comparison (defaults to `millisecond`). Units are case insensitive, and support plural and short forms.
 * @return {boolean} `true` if date1 is before date2 in the given unit, `false` otherwise.
 */
export function isBefore(date1?: Dayjs, date2?: Dayjs, unit: UnitType = 'millisecond'): boolean {
    if (date1 === undefined || date2 === undefined) {
        return false
    }

    const cacheKey = getCacheKey(date1, date2, unit)

    // Check if result is in cache
    if (isBeforeCache.has(cacheKey)) {
        return isBeforeCache.get(cacheKey)!
    }

    // Compute the result and store it in the cache
    const result = date1.isBefore(date2, unit)
    isBeforeCache.set(cacheKey, result)

    return result
}
