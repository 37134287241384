import { RefObject, useEffect } from 'react'

type Props = {
    dataGridRef: RefObject<HTMLDivElement>
    selectedCells: Set<string>
    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
    setDefault: () => void
}

export function useDataGridKeys({ dataGridRef, setIsModalOpen, selectedCells, isModalOpen, setDefault }: Props) {
    useEffect(() => {
        const dataGrid = dataGridRef.current
        if (!dataGrid) return

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && selectedCells.size > 0) {
                e.preventDefault()
                e.stopPropagation()
                setIsModalOpen(true)
            }
        }

        dataGrid.addEventListener('keydown', handleKeyDown)

        return () => {
            dataGrid.removeEventListener('keydown', handleKeyDown)
        }

        // Skipping subscription to setIsModalOpen (it causes infinite loop)
        // Skipping subscription to dataGridRef (it's a ref, it's not supposed to change)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen, selectedCells.size])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (!isModalOpen) setDefault()
                else setIsModalOpen(false)
            }
        }

        document.body.addEventListener('keydown', handleKeyDown)

        return () => {
            document.body.removeEventListener('keydown', handleKeyDown)
        }

        // Skipping subscription to setIsModalOpen (it causes infinite loop)
        // Skipping subscription to setDefault (it causes infinite loop)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen, selectedCells.size])
}
