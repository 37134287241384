import clsx from 'clsx'

import { Show } from '../Show'

type PillsProps = {
    values: string[]
    options: { label: string; value: string }[]
    onChange: (args: { label: string; value: string }) => void
    title?: string
}

const Pills = ({ values, options, onChange }: PillsProps) => (
    <>
        {options.map(option => (
            <div
                key={option.value}
                onClick={() => onChange({ label: option.label, value: option.value })}
                className={clsx(
                    'cursor-pointer rounded-full border px-3 py-0.5',
                    values.includes(option.value) ? 'bg-indigo-700 text-white hover:bg-indigo-800' : 'border-indigo-700 text-indigo-700 hover:bg-indigo-700/10'
                )}
            >
                <p className="text-sm">{option.label}</p>
            </div>
        ))}
    </>
)

type PillsSelectProps = {
    values: string[]
    options: { label: string; value: string }[]
    onChange: (args: { label: string; value: string }) => void
    title?: string
}

export const PillsSelect = ({ title, values, options, onChange }: PillsSelectProps) => {
    const hasTitle = title !== undefined

    return (
        <Show condition={hasTitle} fallback={<Pills values={values} options={options} onChange={onChange} />}>
            <div className="pt-2' flex flex-col items-center px-4 pb-4">
                <p className="w-full pb-2">{title}</p>
                <div className="flex w-full flex-wrap justify-start gap-2">
                    <Pills values={values} options={options} onChange={onChange} />
                </div>
            </div>
        </Show>
    )
}
