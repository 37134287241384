import * as Sentry from '@sentry/browser'
import { Merge, SetOptional, SimplifyDeep } from 'type-fest'

import { DipsSchemas, getClient } from '~/clients/dips-client'
import env from '~/env'
import { isNullish } from '~/utils/guards'

import { useStore } from './store'
import { withFetchObserver } from './utils/fetchObserver'

export type UnifiedScheduledSurgery = SetOptional<
    Merge<SimplifyDeep<DipsSchemas['FullScheduledSurgeryResult']>, SimplifyDeep<DipsSchemas['FullScheduledSurgeryResultV2']>>,
    'letterStatus'
>
export type UnifiedScheduledSurgeryWithId = SimplifyDeep<{ id: string } & Omit<UnifiedScheduledSurgery, 'id'>>

function isFullScheduledSurgeryWithId(entity: UnifiedScheduledSurgery): entity is UnifiedScheduledSurgeryWithId {
    return typeof entity.id === 'string'
}

function unifyFullScheduledSurgery(entity: UnifiedScheduledSurgeryWithId) {
    return {
        plannedStartTime: entity.plannedTheatreInTime ?? entity.plannedStartTime, // v1, can be removed when v1 is deprecated
        plannedTheatreInTime: entity.plannedTheatreInTime ?? entity.plannedStartTime, // v2
        ...entity,
    } satisfies Omit<UnifiedScheduledSurgeryWithId, 'plannedStartTime'>
}

export const importFullScheduledSurgeries = withFetchObserver(
    async function (startTime: string, endTime: string, treatmentLocationId: number): Promise<UnifiedScheduledSurgeryWithId[]> {
        const apiVersion = env.VITE_SCHEDULED_SURGERY_API_VERSION
        const client = getClient()
        const response = await Sentry.startSpan({ name: 'GET /api/v1/surgery/scheduled' }, () => {
            const params = {
                params: {
                    query: {
                        TreatmentLocationId: treatmentLocationId,
                        StartTime: startTime,
                        EndTime: endTime,
                        View: 'full',
                    },
                },
            }

            if (apiVersion === 'v2') {
                return client.GET('/api/v2/surgery/scheduled', params)
            }

            return client.GET('/api/v1/surgery/scheduled', params)
        })

        const entities: UnifiedScheduledSurgery[] = response.data ?? []
        const validEntities = entities.filter(isFullScheduledSurgeryWithId)

        if (validEntities.length !== entities.length) {
            console.warn('Some full scheduled surgeries are missing ids')
        }

        const compatibleEntities = validEntities.map(unifyFullScheduledSurgery)

        useStore.getState().dips.actions.addFullScheduledSurgeries(compatibleEntities)

        return validEntities
    },
    'id',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeFullScheduledSurgeries(ids)
        },
    }
)

export type UnScheduledSurgeryWithBookingId = SimplifyDeep<{ bookingId: string } & Omit<DipsSchemas['UnScheduledSurgery'], 'bookingId'>>

function isUnScheduledSurgeryWithBookingId(entity: DipsSchemas['UnScheduledSurgery']): entity is UnScheduledSurgeryWithBookingId {
    return typeof entity.bookingId === 'string'
}

export const importUnScheduledSurgeries = withFetchObserver(
    async () => {
        const response = await getClient().GET('/api/v1/surgery/waiting', {
            params: { query: { TreatmentLocationId: Number(env.VITE_DIPS_TREATMENT_LOCATION_ID) } },
        })

        // Number of items where access is denied
        const numberOfItemsWithNoAccess = response.data?.numberOfItemsWithNoAccess
        // Number of items where access is permitted but is blocked because of missing access to department, ward, section or location and also all blocked patients for the user.
        const numberOfItemsBlockedForUser = response.data?.numberOfItemsBlockedForUser

        if (
            (!isNullish(numberOfItemsBlockedForUser) && numberOfItemsBlockedForUser > 0) ||
            (!isNullish(numberOfItemsWithNoAccess) && numberOfItemsWithNoAccess > 0)
        ) {
            useStore.getState().waitingList.actions.setHasDeniedAccessItems(true)
        } else {
            useStore.getState().waitingList.actions.setHasDeniedAccessItems(false)
        }

        const entities = response.data?.surgeryWaitingList ?? []
        const validEntities = entities.filter(isUnScheduledSurgeryWithBookingId)

        if (validEntities.length !== entities.length) {
            console.warn('Some unscheduled surgeries are missing bookingIds')
        }

        useStore.getState().dips.actions.addUnScheduledSurgeries(validEntities)

        return validEntities
    },
    'bookingId',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeUnScheduledSurgeries(ids)
        },
    }
)

export type SurgeryTypeWithId = SimplifyDeep<{ id: number } & Omit<DipsSchemas['CodeListDto'], 'id'>>

function isSurgeryTypeWithId(entity: DipsSchemas['CodeListDto']): entity is SurgeryTypeWithId {
    return typeof entity.id === 'number'
}

export const importSurgeryTypes = withFetchObserver(
    async () => {
        const response = await getClient().GET('/api/v1/organization/code-list/{codelistid}', {
            params: { path: { codelistid: 2507 } },
        })

        const entities = response.data?.codes ?? []
        const validEntities = entities.filter(isSurgeryTypeWithId)

        if (validEntities.length !== entities.length) {
            console.warn('Some surgery types are missing ids')
        }

        useStore.getState().dips.actions.addSurgeryTypes(validEntities)

        return validEntities
    },
    'id',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeSurgeryTypes(ids)
        },
    }
)
