import { createSelector } from 'reselect'

import { selectEntities, SurgeryTypeGroup } from './entities'
import { ExternalSurgeryTypeId } from './hospitalSurgeryTypes'
import { resolveSurgeryTypeGroups } from './internal/internalSurgeryTypeGroups'

export type SurgeryTypeGroupId = SurgeryTypeGroup['id']
export type SurgeryTypeGroupCode = SurgeryTypeGroup['code']

export type SurgeryTypeMetadata = {
    weight?: number
}

export type ResolvedSurgeryTypeGroupMetadata = {
    resolvedSurgeryTypes: Record<ExternalSurgeryTypeId, SurgeryTypeMetadata>
    sortedUniqueSurgeryWeights: number[]
}

export type ResolvedSurgeryTypeGroup = SurgeryTypeGroup &
    ResolvedSurgeryTypeGroupMetadata & {
        resolvedChildren: ResolvedSurgeryTypeGroup[]
    }

/**
 * @Returns Getters for the resolved surgery type groups (i.e. surgery type groups with resolved dips surgery types + metadata)
 */
export const selectSurgeryTypeGroup = createSelector(selectEntities, ({ surgeryTypeGroups, surgeryTypeGroupHierarchies }) => {
    const { resolvedSurgeryTypeGroupsById, resolvedSurgeryTypeGroupsByCode, resolvedSurgeryTypeGroupsByHospitalId } = resolveSurgeryTypeGroups(
        surgeryTypeGroupHierarchies,
        surgeryTypeGroups
    )

    return {
        byId: (id: SurgeryTypeGroupId): ResolvedSurgeryTypeGroup | null => resolvedSurgeryTypeGroupsById[id] ?? null,
        byCode: (code: SurgeryTypeGroupCode): ResolvedSurgeryTypeGroup | null => resolvedSurgeryTypeGroupsByCode[code] ?? null,
        byHospitalId: (id: ExternalSurgeryTypeId): ResolvedSurgeryTypeGroup[] | null => resolvedSurgeryTypeGroupsByHospitalId[id] ?? null,
    }
})

export type GetResolvedSurgeryTypeGroup = ReturnType<typeof selectSurgeryTypeGroup>
