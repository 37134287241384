import clsx from 'clsx'
import { memo } from 'react'

import { useDataGridContext } from '../DataGridContext'
import { Id, toCellId } from '../utils'
import { BodyCell } from './BodyCell'
import { CellClassName, CellRender, RowClassName, RowRender } from './types'

export type BodyRowProps<Row extends { id: Id }, Col extends { id: Id }> = {
    cols: Col[]

    row: Row
    rowIndex: number
    rowRender: RowRender<Row>
    rowClassName?: RowClassName<Row>

    cellRender: CellRender<Row, Col>
    cellClassName?: CellClassName<Row, Col>

    height: number
    setIsModalOpen: (isOpen: boolean) => void
    gridTemplateColumns: string
}

function BodyRowComponent<Row extends { id: Id }, Col extends { id: Id }>({
    cols,
    row,
    height,
    rowRender,
    cellRender,
    rowClassName,
    cellClassName,
    rowIndex,
    setIsModalOpen,
    gridTemplateColumns,
}: BodyRowProps<Row, Col>) {
    const { cursor, selectedCells, onMouseDown, onKeyDown } = useDataGridContext()

    return (
        <div key={row.id} id={`row-${rowIndex}`} className="group grid w-full" style={{ gridTemplateColumns, height }}>
            <div className={clsx('sticky left-0 flex items-center truncate border-b border-r border-r-[#8391c3] bg-white', rowClassName?.(row))}>
                {rowRender(row)}
            </div>

            {cols.map((col, colIndex) => {
                const isCursor = cursor.row === rowIndex && cursor.col === colIndex

                return (
                    <div
                        autoFocus={isCursor}
                        id={toCellId({ rowIndex, colIndex })}
                        tabIndex={isCursor ? 0 : -1}
                        key={`${row.id}-${col.id}`}
                        aria-rowindex={rowIndex}
                        aria-colindex={colIndex}
                        data-row-id={row.id}
                        data-col-id={col.id}
                        className={clsx(
                            'min-w-[28px] cursor-pointer rounded-none border-b border-r p-1 outline-none last:border-r-0',
                            cellClassName?.(row, col),
                            {
                                '!bg-diBlue-400/20': selectedCells.has(toCellId({ rowIndex, colIndex })),
                                'fake-border': cursor.row === rowIndex && cursor.col === colIndex,
                            }
                        )}
                        onMouseDown={e => {
                            if (isCursor) {
                                setIsModalOpen(true)
                                return
                            }

                            onMouseDown(e, rowIndex, colIndex)
                        }}
                        onKeyDown={onKeyDown}
                    >
                        <BodyCell cellRender={cellRender} col={col} row={row} />
                    </div>
                )
            })}
        </div>
    )
}

export const BodyRow = memo(BodyRowComponent) as typeof BodyRowComponent
