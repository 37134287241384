import { OperationIcon, PatientIcon, SettingsOutlined, StaffingIcon } from '~/icons'
import { KapasitetOutlined } from '~/icons/KapasitetOutlined'

export const pages = {
    MASTER_SURGERY_SCHEDULER: {
        title: 'Kapasitet',
        views: {
            'master-scheduler': { path: '/master-scheduler', title: 'Kalender' },
            'planning-periods': { path: '/periods', title: 'Perioder' },
            'period-calendar': { path: '/period-calendar', title: 'Kalender2' },
        },
        icon: KapasitetOutlined,
    },
    SURGEON_SCHEDULER: {
        title: 'Bemanning',
        views: {
            practitioners: { path: '/surgeon-scheduler/practitioners', title: 'Kirurger' },
            locations: { path: '/surgeon-scheduler/locations', title: 'Stuer' },
        },
        icon: StaffingIcon,
    },
    OPERATIONAL_PLANNER: {
        title: 'Operasjonsprogram ',
        views: {
            calendar: { path: '/or-schedule/calendar', title: 'Kalender' },
            availability: { path: '/or-schedule/availability', title: 'Ledige tider' },
        },
        icon: OperationIcon,
    },
    WAITING_LIST: {
        title: 'Pasienter',
        views: {
            unscheduled: { path: '/patients/unscheduled', title: 'Venteliste' },
            scheduled: { path: '/patients/scheduled', title: 'Planlagt' },
            all: { path: '/patients/all', title: 'Alle' },
        },
        icon: PatientIcon,
    },
    DOWNLOAD: {
        title: 'Download',
        views: {
            download: { path: '/download', title: 'Download' },
        },
    },
    ADMIN_PANEL: {
        title: 'Admin',
        views: {
            'admin-panel': { path: '/admin-panel', title: 'Admin' },
        },
        icon: SettingsOutlined,
    },
} as const

export const redirects = {
    SURGEON_SCHEDULER: '/surgeon-scheduler',
    OPERATIONAL_PLANNER: '/or-schedule',
    WAITING_LIST: '/patients',
} as const

type Pages = typeof pages

export type PageKey = keyof Pages

type ExtractPath<V> = V extends { path: infer R } ? R : never

export type PagePaths = {
    [K in keyof Pages]: ExtractPath<Pages[K]['views'][keyof Pages[K]['views']]>
}

export type Path = PagePaths[keyof PagePaths]

export function getPageKeyByPath(path: string): keyof Pages | undefined {
    for (const [pageKey, page] of Object.entries(pages)) {
        for (const view of Object.values(page.views)) {
            if (path === view.path) {
                return pageKey as keyof Pages
            }
        }
    }
    return undefined // Return undefined if no match is found
}
