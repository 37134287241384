import { Dayjs } from 'dayjs'

import { HolidayTag, Show } from '~/components'
import { format, isHoliday } from '~/utils/extendedDayjs'

type Props = {
    date: Dayjs
}

export const DayColumn = ({ date }: Props) => {
    const label = format(date, 'dddd DD.MM')

    return (
        <div className="flex flex-col gap-1">
            <div className="whitespace-nowrap capitalize">{label}</div>

            <Show condition={!isHoliday(date)} fallback={<HolidayTag />}>
                <></>
            </Show>
        </div>
    )
}
