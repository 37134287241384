// Tremor Raw cx [v0.0.0]

import clsx, { type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cx(...args: ClassValue[]) {
    return twMerge(clsx(...args))
}

// Tremor Raw focusInput [v0.0.1]

export const focusInput = [
    // base
    'focus:ring-2',
    // ring color
    'focus:ring-blue-200 focus:dark:ring-blue-700/30',
    // border color
    'focus:border-blue-500 focus:dark:border-blue-700',
]

// Tremor Raw focusRing [v0.0.1]

export const focusRing = [
    // base
    'outline outline-offset-2 outline-0 focus-visible:outline-2',
    // outline color
    'outline-blue-500 dark:outline-blue-500',
]

// Tremor Raw hasErrorInput [v0.0.1]

export const hasErrorInput = [
    // base
    'ring-2',
    // border color
    'border-red-500 dark:border-red-700',
    // ring color
    'ring-red-200 dark:ring-red-700/30',
]

// Tremor Raw chartColors [v0.1.0]

export type ColorUtility = 'bg' | 'stroke' | 'fill' | 'text'

export const chartColors = {
    blue: {
        bg: 'bg-blue-500',
        stroke: 'stroke-blue-500',
        fill: 'fill-blue-500',
        text: 'text-blue-500',
    },
    emerald: {
        bg: 'bg-emerald-500',
        stroke: 'stroke-emerald-500',
        fill: 'fill-emerald-500',
        text: 'text-emerald-500',
    },
    violet: {
        bg: 'bg-violet-500',
        stroke: 'stroke-violet-500',
        fill: 'fill-violet-500',
        text: 'text-violet-500',
    },
    amber: {
        bg: 'bg-amber-500',
        stroke: 'stroke-amber-500',
        fill: 'fill-amber-500',
        text: 'text-amber-500',
    },
    gray: {
        bg: 'bg-gray-500',
        stroke: 'stroke-gray-500',
        fill: 'fill-gray-500',
        text: 'text-gray-500',
    },
    cyan: {
        bg: 'bg-cyan-500',
        stroke: 'stroke-cyan-500',
        fill: 'fill-cyan-500',
        text: 'text-cyan-500',
    },
    pink: {
        bg: 'bg-pink-500',
        stroke: 'stroke-pink-500',
        fill: 'fill-pink-500',
        text: 'text-pink-500',
    },
    lime: {
        bg: 'bg-lime-500',
        stroke: 'stroke-lime-500',
        fill: 'fill-lime-500',
        text: 'text-lime-500',
    },
    fuchsia: {
        bg: 'bg-fuchsia-500',
        stroke: 'stroke-fuchsia-500',
        fill: 'fill-fuchsia-500',
        text: 'text-fuchsia-500',
    },
} as const satisfies {
    [color: string]: {
        [key in ColorUtility]: string
    }
}

export type AvailableChartColorsKeys = keyof typeof chartColors

export const AvailableChartColors: AvailableChartColorsKeys[] = Object.keys(chartColors) as Array<AvailableChartColorsKeys>

export const constructCategoryColors = (categories: string[], colors: AvailableChartColorsKeys[]): Map<string, AvailableChartColorsKeys> => {
    const categoryColors = new Map<string, AvailableChartColorsKeys>()
    categories.forEach((category, index) => {
        categoryColors.set(category, colors[index % colors.length] as AvailableChartColorsKeys)
    })
    return categoryColors
}

export const getColorClassName = (color: AvailableChartColorsKeys, type: ColorUtility): string => {
    const fallbackColor = {
        bg: 'bg-gray-500',
        stroke: 'stroke-gray-500',
        fill: 'fill-gray-500',
        text: 'text-gray-500',
    }
    return chartColors[color]?.[type] ?? fallbackColor[type]
}

// Tremor Raw getYAxisDomain [v0.0.0]

export const getYAxisDomain = (autoMinValue: boolean, minValue: number | undefined, maxValue: number | undefined) => {
    const minDomain = autoMinValue ? 'auto' : (minValue ?? 0)
    const maxDomain = maxValue ?? 'auto'
    return [minDomain, maxDomain]
}

// Tremor Raw hasOnlyOneValueForKey [v0.1.0]

export function hasOnlyOneValueForKey(array: never[], keyToCheck: string): boolean {
    const val: unknown[] = []

    for (const obj of array) {
        if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
            val.push(obj[keyToCheck])
            if (val.length > 1) {
                return false
            }
        }
    }

    return true
}

export const SIMIPLE_DATA = [
    {
        name: 'SolarCells',
        amount: 4890,
    },
    {
        name: 'Glass',
        amount: 2103,
    },
    {
        name: 'JunctionBox',
        amount: 2050,
    },
    {
        name: 'Adhesive',
        amount: 1300,
    },
    {
        name: 'BackSheet',
        amount: 1100,
    },
    {
        name: 'Frame',
        amount: 700,
    },
    {
        name: 'Encapsulant',
        amount: 200,
    },
]
