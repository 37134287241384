import { Dayjs } from 'dayjs'

import { Button } from '~/components'
import { DayPicker } from '~/components/Inputs/DatePickers'
import { SurgeryMetadata } from '~/store/selectors'
import { add, day, format, getToday } from '~/utils/extendedDayjs'

type Props = {
    value: SurgeryMetadata['follow_up_date']
    onChange: (value: { follow_up_date: string | null }) => void
}

export function FollowUpDate({ value, onChange }: Props) {
    const today = getToday()
    const selectedDate = value ? day(value) : undefined

    const handleOnChange = (value?: Dayjs) => {
        onChange({ follow_up_date: value ? format(value, 'YYYY-MM-DD') : null })
    }

    return (
        <div className="flex gap-2">
            <div className="flex flex-1">
                <DayPicker placement="bottom" value={selectedDate} onChange={handleOnChange} />
            </div>
            <div className="flex flex-1 gap-2">
                <Button fullWidth color="white" onClick={() => handleOnChange(add(today, 1, 'week'))}>
                    Om 1 uke
                </Button>
                <Button fullWidth color="white" onClick={() => handleOnChange(add(today, 1, 'month'))}>
                    Om 1 måned
                </Button>
            </div>
        </div>
    )
}
