import { Dayjs } from 'dayjs'

import { Button } from '~/components'
import { LeftOutlined, RightOutlined } from '~/icons'
import { add, format } from '~/utils/extendedDayjs'

type Direction = 'backward' | 'forward'
export type Unit = 'day' | 'week' | 'month'

export function moveByUnit(value: Dayjs, direction: Direction, unit: Unit) {
    return add(value, direction === 'forward' ? 1 : -1, unit)
}

type YearSwitchProps = {
    yearBack: (previousYear: number) => void
    year: number
    yearForward: (nextYear: number) => void
}

export const YearSwitch = ({ yearBack, year, yearForward }: YearSwitchProps) => (
    <div className="flex items-center justify-between space-x-2 px-4 pb-1 pt-2">
        <Button size="custom" color="white" onClick={() => yearBack(year - 1)} data-tooltip="Forrige år">
            <LeftOutlined />
        </Button>
        <span className="font-medium">{year}</span>
        <Button size="custom" color="white" onClick={() => yearForward(year + 1)} data-tooltip="Neste år">
            <RightOutlined />
        </Button>
    </div>
)

type MonthSwitchProps = {
    monthBack: (previousMonth: Dayjs) => void
    month: Dayjs
    monthForward: (nextMonth: Dayjs) => void
}

export const MonthSwitch = ({ monthBack, month, monthForward }: MonthSwitchProps) => (
    <div className="flex items-center justify-between space-x-2 px-4 pb-1 pt-2">
        <Button size="custom" color="white" onClick={() => monthBack(moveByUnit(month, 'backward', 'month'))} data-tooltip="Forrige måned">
            <LeftOutlined />
        </Button>
        <span className="font-medium first-letter:capitalize">{format(month, 'MMM YYYY')}</span>
        <Button size="custom" color="white" onClick={() => monthForward(moveByUnit(month, 'forward', 'month'))} data-tooltip="Neste måned">
            <RightOutlined />
        </Button>
    </div>
)
