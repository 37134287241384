import { baseClient } from '~/clients/api-client'
import { AccessTokenResponse } from '~/clients/derived-api-types'

import { day } from './extendedDayjs/helpers/day'
import { isNullish } from './guards'

type AccessToken = AccessTokenResponse | undefined

type RefreshAccessTokenArgs = {
    currentAccessToken: AccessToken
    callback: (newAccessToken: AccessToken) => void
}

const margin_s = 10 // Refresh the token 10 seconds before it expires

let refreshTokenTimeout: NodeJS.Timer | undefined
let cancelled = true

function refreshAccessTokenRepeatedly({ currentAccessToken, callback }: RefreshAccessTokenArgs): void {
    clearTimeout(refreshTokenTimeout)
    refreshTokenTimeout = undefined

    if (cancelled) {
        return
    }

    if (isNullish(currentAccessToken?.token_exp)) {
        // The accessToken doesn't need to be refreshed
        return
    }

    let delay_s = currentAccessToken.token_exp - day().unix() - margin_s
    if (delay_s < 0) {
        // refresh the accessToken in 1 second
        // Note: the accessToken can be refreshed even if it has already expired
        delay_s = 1
    }

    refreshTokenTimeout = setTimeout(async () => {
        try {
            const { data: newAccessToken } = await baseClient.GET('/oauth/token')
            if (currentAccessToken.access_token !== newAccessToken?.access_token) {
                callback(newAccessToken)
            }
            refreshAccessTokenRepeatedly({ currentAccessToken: newAccessToken, callback })
        } catch (err) {
            console.error(err)
        }
    }, delay_s * 1000)
}

export function scheduleRefreshingAccessToken({ currentAccessToken, callback }: RefreshAccessTokenArgs): void {
    cancelled = false
    refreshAccessTokenRepeatedly({ currentAccessToken, callback })
}

export function cancelRefreshingAccessToken() {
    if (refreshTokenTimeout) {
        clearTimeout(refreshTokenTimeout)
        refreshTokenTimeout = undefined
    }
    cancelled = true
}
