import * as Sentry from '@sentry/browser'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useState } from 'react'

import api from '~/clients/api-client'
import { getClient, isDipsUrlConfigured } from '~/clients/dips-client'
import { StoreState, useStore } from '~/store/store'
import { day, getToday } from '~/utils/extendedDayjs'

/**
 * In this file we import all of the common, stable data that is prevalent throughout the application. The criteria to include data here is:
 * - The data is relatively stable (doesn't update too often)
 * - The data is used in multiple places in the application
 * - The data is not schedule-specific, meaning it doesn't change based on the selected date
 *
 * We check if the data is already loaded in the store, but we do not save or load it here.
 * The only thing we decide here is whether the data load should be awaited or whether it will be a fire-and-forget operation.
 * - If it's awaited, the user will sit behind the loading screen until the data is loaded.
 * - If it's not, the user will much sooner see the application and the data will be updated in the background.
 */

type AllHeroEntityNames = keyof StoreState['di']['entities']
export const persistedHeroEntityNames = [
    'ageGroups',
    'departmentLocationAssignments',
    'departmentPractitionerAssignments',
    'departments',
    'hospitalSurgeryTypeGroupAssociations',
    'hospitalSurgeryTypes',
    'locations',
    'practitioners',
    'practitionerStatusDefinitions',
    'ruleDefinitions',
    'sections',
    'specialities',
    'surgeryTypeGroupAgeRestrictions',
    'surgeryTypeGroupHierarchies',
    'surgeryTypeGroups',
    'surgeryTypeGroupSpecialities',
] as const satisfies AllHeroEntityNames[]
type PersistedHeroEntityNames = (typeof persistedHeroEntityNames)[number]

function getHeroCommonDataFetches() {
    return {
        ageGroups: api.fetchAgeGroups({}),
        departmentLocationAssignments: api.fetchDepartmentLocationAssignments({}),
        departmentPractitionerAssignments: api.fetchDepartmentPractitionerAssignments({}),
        departments: api.fetchDepartments({ exclude_locations: true, exclude_practitioners: true, exclude_sections: true }),
        hospitalSurgeryTypeGroupAssociations: api.fetchHospitalSurgeryTypeGroupAssociations({ exclude_surgery_type: true }),
        hospitalSurgeryTypes: api.fetchHospitalSurgeryTypes({}),
        locations: api.fetchLocations({ exclude_departments: true }),
        practitioners: api.fetchPractitioners({ exclude_departments: true }),
        practitionerStatusDefinitions: api.fetchPractitionerStatusDefinitions({}),
        ruleDefinitions: api.fetchRuleDefinitions({}),
        sections: api.fetchSections({}),
        specialities: api.fetchSpecialities({}),
        surgeryTypeGroupAgeRestrictions: api.fetchSurgeryTypeGroupAgeRestrictions({}),
        surgeryTypeGroupHierarchies: api.fetchSurgeryTypeGroupHierarchies({}),
        surgeryTypeGroups: api.fetchSurgeryTypeGroups({}),
        surgeryTypeGroupSpecialities: api.fetchSurgeryTypeGroupSpecialities({}),
    } as const satisfies Record<PersistedHeroEntityNames, Promise<unknown>>
}

export type PersistedHeroEntityCollections = {
    [K in PersistedHeroEntityNames]: StoreState['di']['entities'][K]
}

type AllDipsEntityNames = keyof StoreState['dips']['entities']
export const persistedDipsEntityNames = ['dipsDepartments', 'dipsResources'] as const satisfies AllDipsEntityNames[]
type PersistedDipsEntityNames = (typeof persistedDipsEntityNames)[number]
export type PersistedDipsEntityCollections = {
    [K in PersistedDipsEntityNames]: StoreState['dips']['entities'][K]
}

export function useImportCommonData() {
    const [isLoading, setIsLoading] = useState(false)
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
    const dipsApiAccessToken = useStore(state => state.user.dipsApiAccessToken)
    const dipsStoreActions = useStore(state => state.dips.actions)
    const isDeepinsightUser = useStore(state => state.user.issuer === 'google')

    useEffect(() => {
        const shouldWaitForDipsAccessToken = isDipsUrlConfigured() && dipsApiAccessToken === null && !isDeepinsightUser

        if (isLoading || hasLoadedOnce || shouldWaitForDipsAccessToken) {
            return
        }

        async function importCommonDipsData() {
            // Step 1. Set up the import function
            async function importDipsData() {
                const dipsClient = getClient()
                const today = getToday()

                const departmentResponse = await dipsClient.GET('/api/v1/organization/departments', {
                    // We're getting all of the departments that are valid/active today
                    params: { query: { includeNotAccessTo: true, includeReplaced: false } },
                })

                const validDipsDepartments = (departmentResponse.data ?? [])
                    .filter(dept => dept.reshId)
                    .filter(dept => dept.validTo === null || day(dept.validTo).isAfter(today))
                    .filter(dept => dept.validFrom === null || day(dept.validFrom).isBefore(today))

                const operatingTheaterPromises = uniqBy(validDipsDepartments, 'reshId').map(dept => {
                    if (!dept.reshId) {
                        return
                    }

                    return dipsClient.GET('/api/v1/resources', {
                        params: {
                            query: {
                                departmentReshId: dept.reshId,
                                resourceType: 'OperatingTheater',
                            },
                        },
                    })
                })

                const mainSurgeonPromises = uniqBy(validDipsDepartments, 'reshId').map(dept => {
                    if (!dept.reshId) {
                        return
                    }

                    return dipsClient.GET('/api/v1/resources', {
                        params: {
                            query: {
                                departmentReshId: dept.reshId,
                                resourceType: 'MainSurgeon',
                            },
                        },
                    })
                })

                const responses = await Promise.all([...operatingTheaterPromises, ...mainSurgeonPromises])

                const allResources = uniqBy(responses.map(response => response?.data ?? []).flat(), 'shortName')

                dipsStoreActions.addDepartments(validDipsDepartments)
                dipsStoreActions.addResources(allResources)
            }

            await importDipsData()
        }

        async function importCommonDipsDataWrapper() {
            try {
                await importCommonDipsData()
            } catch (error) {
                if (isDeepinsightUser) {
                    // Expect an error if a Deepinsight user is not allowed to access DIPS in production
                    return
                }
                throw error
            }
        }

        async function importCommonHeroData() {
            // Step 1. Set up the import function
            async function importHeroData() {
                const promisesByStoreKey = getHeroCommonDataFetches()
                const promises = Object.values(promisesByStoreKey)

                const results = await Promise.all(promises)

                const entities = results
                    .map(result => result.data)
                    .flat()
                    .filter(Boolean)

                useStore.getState().di.actions.addEntities(entities)
            }

            await importHeroData()
        }

        async function importCommonData() {
            setIsLoading(true)

            // We're measuring the time it takes to import all of the data from DIPS,
            // since it can cause a lot of queries
            const promises: Promise<unknown>[] = [Sentry.startSpan({ name: 'importCommonHeroData' }, importCommonHeroData)]

            if (isDipsUrlConfigured()) {
                promises.push(Sentry.startSpan({ name: 'importCommonDipsData' }, importCommonDipsDataWrapper))
            }

            await Promise.all(promises)

            setIsLoading(false)
            setHasLoadedOnce(true)
        }

        const timeout = setTimeout(importCommonData, 10)
        return () => clearTimeout(timeout)
    }, [dipsApiAccessToken, isLoading, hasLoadedOnce, dipsStoreActions, isDeepinsightUser])

    return { isLoading, hasLoadedOnce }
}
