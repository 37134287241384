import { PillsSelect } from '~/components'
import { Option, selectPrioritizationValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const prioritizationOptions: Option[] = [{ label: 'Prio', value: 'Prio' }]

export const PrioritizationFilter = () => {
    const prioritizationValues = useStore(selectPrioritizationValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'prio', value, label })
        setSelectedPage(1)
    }

    return <PillsSelect values={prioritizationValues} options={prioritizationOptions} onChange={({ value, label }) => handleOnChange(value, label)} />
}
