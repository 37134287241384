import { createSelector } from 'reselect'

import { DipsSchemas } from '~/clients/dips-client'
import { keyBy } from '~/utils/array'

import { selectEntities } from './entities'

export const selectGetDipsResources = createSelector(selectEntities, ({ dipsResources }) => {
    const byShortName = keyBy(dipsResources, 'shortName')

    return {
        byShortName: (shortName: DipsSchemas['ResourceDto']['shortName']) => {
            if (!shortName) {
                return null
            }

            return byShortName[shortName] ?? null
        },
    }
})
