import * as Sentry from '@sentry/react'
import type { Options } from '@sentry/types'

import env from './env'
import { urlToRegex } from './utils/utils'

export async function showUserFeedbackForm(username: string | null) {
    const client = Sentry.getClient<Sentry.BrowserClient>()
    const feedback = client?.getIntegrationByName<ReturnType<typeof Sentry.feedbackIntegration>>('Feedback')

    if (!feedback) {
        return null
    }

    if (username !== null) {
        Sentry.setUser({ username: username })
    }

    // `useSentryUser` provides a mapping from the keys provided in `setUser()` for use in submitted feedback.
    const form = await feedback.createForm({ useSentryUser: { email: 'email', name: 'username' } })
    form.appendToDom()
    form.open()
}

if (env.VITE_SENTRY_DSN) {
    Sentry.setTags({
        'app.name': 'frontend',
        'app.git_tag': env.VITE_GIT_TAG,
        'app.git_sha': env.VITE_GIT_SHA,
    })
    const tracePropagationTargets: string[] = []

    if (env.VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_API) {
        tracePropagationTargets.push(env.VITE_API_BASE_URL)
    }

    if (env.VITE_ENABLE_SENTRY_TRACE_PROPAGATION_TO_DIPS) {
        tracePropagationTargets.push(env.VITE_DIPS_BASE_URL)
    }

    const integrations: Options['integrations'] = [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration()]

    if (env.VITE_ENABLE_SENTRY_USER_FEEDBACK) {
        integrations.push(
            Sentry.feedbackIntegration({
                enableScreenshot: false, // Keep this disabled to avoid leaking sensitive data.
                autoInject: false,
                showBranding: false,
                showName: false,
                showEmail: false,
                formTitle: 'Gi tilbakemelding',
                submitButtonLabel: 'Send inn',
                cancelButtonLabel: 'Avbryt',
                confirmButtonLabel: 'Bekreft',
                isRequiredLabel: '',
                messageLabel: 'Husk å ikke dele sensitiv informasjon her.',
                messagePlaceholder: 'Gikk noe galt, i såfall hva da? Hva forventet du at skulle skje?',
                successMessageText: 'Takk for din tilbakemelding!',
            })
        )
    }

    if (env.VITE_SENTRY_ENVIRONMENT === 'local') {
        integrations.push(Sentry.captureConsoleIntegration({ levels: ['info'] }))
    } else {
        integrations.push(Sentry.captureConsoleIntegration({ levels: ['info', 'warn', 'error'] }))
    }

    Sentry.init({
        dsn: env.VITE_SENTRY_DSN,
        environment: env.VITE_SENTRY_ENVIRONMENT,
        release: env.VITE_GIT_TAG,
        integrations,
        // Performance Monitoring
        tracesSampleRate: Number(env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 1.0),
        // Session Replay
        // replaysSessionSampleRate: The sample rate for replays that begin recording immediately and last the entirety of the user's session.
        // You may want to change this to 100% while in development and then sample at a lower rate in production.
        replaysSessionSampleRate: Number(env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE ?? 0.1),
        // replaysOnErrorSampleRate: the sample rate for replays that are recorded when an error happens. This type of replay will record
        // up to a minute of events prior to the error and continue recording until the session ends.
        // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        replaysOnErrorSampleRate: Number(env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE ?? 1.0),
        // tracePropagationTargets attaches baggage and sentry-trace http headers to the listed urls
        tracePropagationTargets: tracePropagationTargets.map(urlToRegex),
    })
} else {
    console.warn('Sentry disabled')
}
