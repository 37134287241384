import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { BlockScheduleEditor, Button, Show } from '~/components'
import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { MoreOutlined } from '~/icons'
import { Location } from '~/store/selectors'

import { ActionsMenu } from './ActionsMenu'

type Props = {
    date: Dayjs
    location: Location
    showDropdown: boolean
    setShowButton: (value: React.SetStateAction<boolean>) => void
    setShowDropdown: (value: React.SetStateAction<boolean>) => void
}

export const ThreeDotsMenu = ({ date, location, showDropdown, setShowDropdown, setShowButton }: Props) => {
    const [showCapacityEditor, setShowCapacityEditor] = useState(false)

    if (!date || !location) {
        return null
    }

    function handleTriggerClick() {
        setShowDropdown(prev => !prev)
        setShowCapacityEditor(false)
    }

    const hide = () => {
        setShowButton(false)
        setShowDropdown(false)
        setShowCapacityEditor(false)
    }

    return (
        <div className="layer-stack pointer-events-none cursor-auto justify-self-end">
            <HeroPopover
                align="start"
                noPadding
                isOpen={showDropdown}
                onOpenChange={show => {
                    setShowDropdown(show)
                    if (!show) {
                        hide()
                    }
                }}
                onPointerDownOutside={hide}
                popoverContent={
                    <div className="pointer-events-auto">
                        <Show
                            condition={!showCapacityEditor}
                            fallback={<BlockScheduleEditor date={date} location={location} onSubmit={hide} onRemove={hide} />}
                        >
                            <ActionsMenu date={date} location={location} setShowCapacityEditor={setShowCapacityEditor} hide={hide} />
                        </Show>
                    </div>
                }
            >
                <Button className="pointer-events-auto shadow-md" onClick={handleTriggerClick} size="icon" color="white" data-test="triple-dot-button">
                    <MoreOutlined className="h-5 w-5" />
                </Button>
            </HeroPopover>
        </div>
    )
}
