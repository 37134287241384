import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { EmptyItem } from '~/app/pages/OperationalPlanner/BookingDrawer/EmptyItem'
import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { getSurgeons } from '~/utils/dips'
import { format } from '~/utils/extendedDayjs'

import { useOccupancyData } from '../../hooks/useOccupancyData'
import { OperatingTheaterList } from './OperatingTheaterList'

type Props = {
    selectedDay?: Dayjs
    showAllPractitioners: boolean
    surgery: UnScheduledSurgery
    selectedMonth: Dayjs
    isLoading: boolean
    onClick: (selectedOccupancy: OccupancyData) => void
}

export const AvailableLocations = ({ selectedDay, showAllPractitioners, surgery, selectedMonth, isLoading, onClick }: Props) => {
    const occupancyData = useOccupancyData(surgery, isLoading, selectedMonth)
    const occupancyForSelectedDate = useMemo(() => selectedDay && occupancyData[selectedDay.format('YYYY-MM-DD')], [selectedDay, occupancyData])

    const surgeryPractitioner = surgery ? getSurgeons(surgery.surgeryResources) : []

    const formattedMonth = format(selectedMonth, 'MMMM')

    return (
        <div className="flex flex-col gap-4">
            {occupancyForSelectedDate && selectedDay && (
                <OperatingTheaterList
                    occupancies={occupancyForSelectedDate}
                    date={selectedDay}
                    onClick={onClick}
                    surgeryPractitioner={surgeryPractitioner}
                    showAllPractitioners={showAllPractitioners}
                />
            )}
            {!selectedDay && (
                <EmptyItem>
                    <p>Ingen ledig tid funnet i {formattedMonth}. Bruk datovelgeren for å lete lengre frem i tid.</p>
                </EmptyItem>
            )}
        </div>
    )
}
