import { RefObject, useLayoutEffect } from 'react'

import { toCellId } from '../utils'
import { Cursor } from './useCreateKeyboardNavigation'

export type RecalculatePositionOnNavigationProps = {
    firstColumnWidth: number

    cursor: Cursor

    headerRef: RefObject<HTMLDivElement>
    dataGridRef: RefObject<HTMLDivElement>
    footerRef: RefObject<HTMLDivElement>
}

export function useRecalculatePositionOnNavigation({ cursor, firstColumnWidth, headerRef, dataGridRef, footerRef }: RecalculatePositionOnNavigationProps) {
    useLayoutEffect(() => {
        const { row: rowIndex, col: colIndex } = cursor
        const cell = document.getElementById(toCellId({ rowIndex, colIndex }))

        if (cell) {
            const headerRect = headerRef.current?.getBoundingClientRect()
            const footerRect = footerRef.current?.getBoundingClientRect()
            const cellRect = cell.getBoundingClientRect()
            const dataGrid = dataGridRef.current

            if (!headerRect || !dataGrid || !footerRect) return

            if (cellRect.bottom > footerRect.top) {
                dataGrid.scrollTop += cellRect.bottom - footerRect.top
            }

            if (cellRect.right > dataGrid.clientWidth) {
                dataGrid.scrollLeft += cellRect.right - dataGrid.clientWidth
            }

            if (cellRect.left < firstColumnWidth) {
                dataGrid.scrollLeft -= firstColumnWidth - cellRect.left + 10
            }

            if (cellRect.top < headerRect.bottom) {
                dataGrid.scrollTop -= headerRect.bottom - cellRect.top
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cursor, firstColumnWidth])
}
