import { ComponentProps, FC } from 'react'

export const InProgressCircleOutlined: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 14.5C12.0376 14.5 14.5 12.0376 14.5 9C14.5 5.96243 12.0376 3.5 9 3.5C5.96243 3.5 3.5 5.96243 3.5 9C3.5 12.0376 5.96243 14.5 9 14.5ZM9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z"
            fill="currentColor"
        />
        <path d="M12.5714 9C12.5714 10.9725 10.9725 12.5714 9.00003 12.5714L8.99978 5.42857C10.9722 5.42857 12.5714 7.02755 12.5714 9Z" fill="currentColor" />
    </svg>
)
