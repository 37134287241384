import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react'

import { GridMonth, inputStyles, MonthSwitch, selectSize, Show, Tag } from '~/components'
import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { CloseOutlined, DownOutlined } from '~/icons'
import { selectGetBlockSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format, getToday, isSameDate } from '~/utils/extendedDayjs'

export type BookedDay = {
    date: Dayjs
    isBooked: boolean
}

type Props = {
    locationId: number

    dates: BookedDay[]
    setDates: Dispatch<SetStateAction<BookedDay[]>>
}

export const MultipleDatePicker = ({ locationId, setDates, dates }: Props) => {
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const sortedDates = dates.toSorted((a, b) => a.date.valueOf() - b.date.valueOf())

    const [relativeDate, setRelativeDate] = useState(selectedDate)

    function deleteSingleDate(date: Dayjs) {
        setDates(prev => prev.filter(d => !isSameDate(d.date, date)))
    }

    function clearAllDates(e?: MouseEvent) {
        e?.stopPropagation()
        setDates([])
    }

    return (
        <HeroPopover
            noPadding
            side="bottom"
            popoverContent={
                <>
                    <MonthSwitch monthBack={setRelativeDate} month={relativeDate} monthForward={setRelativeDate} />
                    <hr className="my-1 h-px bg-gray-200" />
                    <GridMonth relativeDate={relativeDate}>
                        {week => (
                            <div key={week.weekNumber} className={'grid grid-cols-5 text-center'}>
                                {week.weekdays.map((day, i) => {
                                    const isBooked = getBlockSchedules.byDateAndLocationId(day, locationId)

                                    return (
                                        <div
                                            key={i}
                                            className={clsx('mx-1 cursor-pointer select-none rounded p-1', {
                                                'calendar-today': isSameDate(day, getToday()),
                                                'bg-gray-200': isBooked,
                                                'bg-diBlue-500 text-white': dates.some(date => isSameDate(date.date, day)),
                                                'bg-red-400 text-white': isBooked && dates.some(date => isSameDate(date.date, day)),
                                                'hover:bg-gray-100': !isBooked && !dates.some(date => isSameDate(date.date, day)),
                                            })}
                                            onClick={() => {
                                                setDates(prev => {
                                                    if (prev.some(date => isSameDate(date.date, day))) {
                                                        return prev.filter(date => !isSameDate(date.date, day))
                                                    }

                                                    return [...prev, { date: day, isBooked: !!isBooked }]
                                                })
                                            }}
                                            data-tooltip="Datoen er opptatt"
                                        >
                                            {format(day, 'D')}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </GridMonth>
                </>
            }
        >
            <div
                className={clsx(inputStyles, selectSize['md'], 'flex w-full cursor-pointer items-center', {
                    'text-gray-400': dates.length < 0,
                })}
            >
                <div className="w-full truncate">
                    <div className="flex flex-wrap gap-1">
                        <Show condition={dates.length > 0} fallback="Velg datoer">
                            {sortedDates.map((d, i) => (
                                <Tag
                                    key={i}
                                    defaultCursor
                                    color={d.isBooked ? 'holiday' : undefined}
                                    data-tooltip={d.isBooked ? 'Denne dagens program blir overskrevet' : ''}
                                >
                                    {format(d.date, 'DD.MM.YYYY')}
                                    <CloseOutlined
                                        onClick={(e?: MouseEvent) => {
                                            e?.stopPropagation()
                                            deleteSingleDate(d.date)
                                        }}
                                        className="h-4 w-4 cursor-pointer"
                                    />
                                </Tag>
                            ))}
                        </Show>
                    </div>
                </div>

                <Show condition={dates?.length !== 0} fallback={<DownOutlined className="h-5 w-5 shrink-0" />}>
                    <CloseOutlined onClick={clearAllDates} className="ml-1 h-5 w-5 shrink-0 cursor-pointer text-gray-700 hover:text-gray-400" />
                </Show>
            </div>
        </HeroPopover>
    )
}
