import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

import { BookingDialog } from '~/app/pages/OperationalPlanner/BookingDrawer/BookingDialog/BookingDialog'
import { Show, Tag } from '~/components'
import env from '~/env'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { CloseOutlined, HelpOutlined, HomeHealthOutlined } from '~/icons'
import { OccupancyData } from '~/store/selectors'
import { getDayOvernightTooltipText, isDayOvernightLabel } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { format, getToday } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

import { useGetWaitingListSurgery } from '../../hooks/useGetWaitingListSurgery'
import { columns } from '../../shared/columns'
import { Remark } from '../../shared/Remarks'
import { Payload, saveSurgeryMetadata } from '../../shared/saveSurgeryMetadata'
import { getCompletedStatus, Status } from '../../shared/Statuses'
import { Comment } from '../Comment/Comment'
import { AvailableDaysPicker } from './AvailableDaysPicker'
import { AvailableLocations } from './AvailableLocations'
import { ClearedRadioGroup } from './ClearedRadioGroup'
import { FollowUpDate } from './FollowUpDate'
import { PatientInfo } from './PatientInfo'
import { SidebarSwitch } from './SidebarSwitch'
import { SurgeryInfo } from './SurgeryInfo'

export const BookingContent = () => {
    const today = getToday()

    const [selectedDay, setSelectedDay] = useState<Dayjs | undefined>()
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const [month, setMonth] = useState<Dayjs>(today.startOf('month'))
    const showAllPractitioners = useStore(state => state.waitingList.showAllPractitioners)
    const setShowAllPractitioners = useStore(state => state.waitingList.actions.setShowAllPractitioners)
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [selectedOccupancy, setSelectedOccupancy] = useState<OccupancyData>()
    const [showPatientStatusHelp, setShowPatientStatusHelp] = useState<boolean>(false)

    const monthRange = useMemo(
        () => ({
            startTime: format(month.startOf('month')),
            endTime: format(month.endOf('month').add(1, 'day')),
        }),
        [month]
    )

    // needed to derive the open slots for the selected surgery type
    const { isLoading } = useImportOccupancyEntities(monthRange.startTime, monthRange.endTime)

    const { BookingId, BookedStatus, Day } = drawerProps?.item ?? {}
    const isUnscheduledItem = BookedStatus !== 'Scheduled'

    const selectedSurgery = useGetWaitingListSurgery(drawerProps?.item)

    if (drawerProps === null || !selectedSurgery) return <div />

    const followUpDate = selectedSurgery?.surgeryMetadata?.follow_up_date
    const isPatientReady = selectedSurgery?.surgeryMetadata?.patient_ready

    const isNew = Boolean(isUnscheduledItem && isNullish(isPatientReady))
    const isCleared = Boolean(isPatientReady)
    const isConfirmed = Boolean(selectedSurgery?.surgeryMetadata?.patient_confirmed)
    const isShortNotice = Boolean(selectedSurgery?.surgeryMetadata?.patient_short_notice)
    const isPrio = Boolean(selectedSurgery?.surgeryMetadata?.patient_prioritized)

    const onCloseDialog = () => {
        setSelectedOccupancy(undefined)
        setShowBookingDialog(false)
    }

    function saveMetadata(metadata: Omit<Payload, 'booking_id'>) {
        saveSurgeryMetadata(selectedSurgery?.surgeryMetadata, { booking_id: BookingId!, ...metadata })
    }

    return (
        <div className="flex flex-col gap-4" data-test="waiting-list-booking-content">
            <PatientInfo item={drawerProps.item} isUnscheduledItem={isUnscheduledItem} surgery={selectedSurgery} />

            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-0.5">
                    <div className="flex items-center gap-1">
                        <p className="font-bold">Pasientstatus</p>
                        <div className={`mr-2 flex items-center justify-center rounded ${showPatientStatusHelp ? 'bg-neutral-100' : 'hover:bg-neutral-50'}`}>
                            <HelpOutlined className="h-5 w-5 cursor-pointer" onClick={() => setShowPatientStatusHelp(prev => !prev)} />
                        </div>
                        <Show condition={isNew}>
                            <Tag size="sm" color="new">
                                Ny
                            </Tag>
                        </Show>
                    </div>
                    <Show condition={showPatientStatusHelp}>
                        <div className="flex gap-1 rounded bg-neutral-100 p-2">
                            <p>{`Planleggeren oppdaterer pasientstatusen for å vise om pasienten er klar for å settes opp til operasjon${!isUnscheduledItem ? ', og om de har fått bekreftet at pasienten vil møte opp' : ''}.`}</p>
                            <CloseOutlined className="h-5 w-5 shrink-0 cursor-pointer hover:fill-neutral-500" onClick={() => setShowPatientStatusHelp(false)} />
                        </div>
                    </Show>
                </div>
                <ClearedRadioGroup isUnscheduledItem={isUnscheduledItem} isNew={isNew} isCleared={isCleared} onChange={saveMetadata} />
                <Show condition={!isCleared && !isNew}>
                    <FollowUpDate value={followUpDate} onChange={saveMetadata} />
                </Show>
                <Show condition={!isUnscheduledItem}>
                    <SidebarSwitch label="Bekreftet oppmøte" checked={isConfirmed} onCheckedChange={checked => saveMetadata({ patient_confirmed: checked })}>
                        <Status status={{ type: 'confirmed', value: getCompletedStatus(isConfirmed) }} isSelected={isConfirmed} />
                    </SidebarSwitch>
                </Show>
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-bold">{columns.Remarks.label}</p>
                {Day && !isDayOvernightLabel(Day) && (
                    <div className="bg-yellow-100 px-4 py-4">
                        <p className="flex gap-2">
                            <HomeHealthOutlined className="shrink-0" />
                            {getDayOvernightTooltipText(Day)}
                        </p>
                    </div>
                )}
                <div className="flex gap-2">
                    <SidebarSwitch label="Kort varsel" checked={isShortNotice} onCheckedChange={checked => saveMetadata({ patient_short_notice: checked })}>
                        <Remark remark="shortNotice" color={isShortNotice ? 'on' : 'off'} />
                    </SidebarSwitch>
                    <SidebarSwitch label="Prioritet" checked={isPrio} onCheckedChange={checked => saveMetadata({ patient_prioritized: checked })}>
                        <Remark remark="prioritized" color={isPrio ? 'on' : 'off'} />
                    </SidebarSwitch>
                </div>
            </div>

            <Comment item={drawerProps.item} />

            <div className="flex flex-col gap-2">
                <p className="font-bold">{`${isUnscheduledItem ? 'Operasjon til planlegging' : 'Planlagt operasjon'}`}</p>
                <SurgeryInfo item={drawerProps.item} surgery={selectedSurgery} />
            </div>

            <Show condition={isUnscheduledItem && env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER}>
                <div className="flex flex-col gap-2">
                    <p className="font-bold">Finn operasjonstid</p>
                    <label className="flex w-full gap-2 hover:cursor-pointer">
                        <input
                            type="checkbox"
                            data-test="show-all-practitioners-checkbox"
                            className="accent-indigo-700 hover:cursor-pointer"
                            checked={showAllPractitioners}
                            onChange={e => {
                                setShowAllPractitioners(e.target.checked)
                            }}
                        />
                        <p>Vis ledige tider for alle kirurger</p>
                    </label>
                    <AvailableDaysPicker
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        surgery={selectedSurgery}
                        selectedMonth={month}
                        onMonthChange={setMonth}
                        isLoading={isLoading}
                        showAllPractitioners={showAllPractitioners}
                    />
                    <AvailableLocations
                        isLoading={isLoading}
                        selectedDay={selectedDay}
                        selectedMonth={month}
                        showAllPractitioners={showAllPractitioners}
                        surgery={selectedSurgery}
                        onClick={occ => {
                            setSelectedOccupancy(occ)
                            setShowBookingDialog(true)
                        }}
                    />
                </div>
            </Show>

            {selectedOccupancy && (
                <BookingDialog isOpen={showBookingDialog} onCloseDialog={onCloseDialog} waitingListItem={selectedSurgery} occupancyData={selectedOccupancy} />
            )}
        </div>
    )
}
