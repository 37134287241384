import { useMemo } from 'react'

import { Show, Spinner } from '~/components'
import { HeroAccordion, Item } from '~/hero-ui/stories/HeroAccordion/HeroAccordion'
import { importPlanningPeriods } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'

import { CallToAction } from './CallToAction/CallToAction'
import PlanningPeriodList from './PlanningPeriodList/PlanningPeriodList'

type PeriodTimelineOptions = 'KOMMENDE' | 'FULLFØRT' | 'AKTUELT'

export function Timeline() {
    const { isLoading } = useImportEntities(() => [importPlanningPeriods({})], [])

    const planningPeriods = useStore(selectPlanningPeriods)

    const planningPeriodsByStatus = useMemo(() => {
        const periods: Item<PeriodTimelineOptions>[] = [
            {
                title: 'KOMMENDE',
                content: <PlanningPeriodList planningPeriods={planningPeriods.future()} />,
            },
            {
                title: 'AKTUELT',
                content: <PlanningPeriodList planningPeriods={planningPeriods.current()} />,
            },
            {
                title: 'FULLFØRT',
                content: <PlanningPeriodList planningPeriods={planningPeriods.completed()} />,
            },
        ]
        return periods
    }, [planningPeriods])

    const hasPlanningPeriods = planningPeriods.all().length > 0

    return (
        <Show
            condition={!isLoading || hasPlanningPeriods}
            fallback={
                <div className="flex h-screen items-center justify-center">
                    <Spinner size="lg" />
                </div>
            }
        >
            <Show condition={hasPlanningPeriods} fallback={<CallToAction />}>
                <HeroAccordion items={planningPeriodsByStatus} defaultOpen={['KOMMENDE', 'FULLFØRT', 'AKTUELT']} />
            </Show>
        </Show>
    )
}
