import { createBatchPractitionerSchedule } from '~/store/di-entity.api'
import { selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { HOSPITAL_TIMEZONE } from '~/utils/extendedDayjs/config'

import { DatePractitionerCell } from '../utils'

export function useCreateMissingSchedules(selectedCells: DatePractitionerCell[]) {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const cellsWithoutPractitionerSchedule = selectedCells.filter(
        ({ date, practitionerId }) => !getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId)
    )

    return () =>
        createBatchPractitionerSchedule(
            cellsWithoutPractitionerSchedule.map(({ date, practitionerId }) => ({
                practitioner_id: practitionerId,
                start_time: date.format(),
                end_time: date.add(1, 'day').format(),
                tzid: HOSPITAL_TIMEZONE,
            }))
        )
}
