import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { AgeGroupCode } from '~/clients/derived-api-types'

import { AgeGroup, selectEntities } from './entities'

export const selectGetAgeGroups = createSelector(selectEntities, ({ ageGroups }) => {
    const byId = keyBy(ageGroups, 'id')
    const byCode = keyBy(ageGroups, 'age_group_code')

    return {
        byId: (id: AgeGroup['id']) => byId[id],
        byCode: (ageGroupCode?: AgeGroupCode | null): AgeGroup | null => {
            return ageGroupCode ? (byCode[ageGroupCode] ?? null) : null
        },
    }
})
