import { ComponentProps, FC } from 'react'

export const ArrowUp: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.96967 2.80304C8.26256 2.51015 8.73744 2.51015 9.03033 2.80304L13.0303 6.80304C13.3232 7.09594 13.3232 7.57081 13.0303 7.8637C12.7374 8.1566 12.2626 8.1566 11.9697 7.8637L9.25 5.14403V12.6667C9.25 13.0809 8.91421 13.4167 8.5 13.4167C8.08579 13.4167 7.75 13.0809 7.75 12.6667V5.14403L5.03033 7.8637C4.73744 8.1566 4.26256 8.1566 3.96967 7.8637C3.67678 7.57081 3.67678 7.09594 3.96967 6.80304L7.96967 2.80304Z"
            fill="currentColor"
        />
    </svg>
)
