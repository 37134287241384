import { motion } from 'framer-motion'

import { Button, Tag, useConfirmDialog } from '~/components'
import { CloseOutlined } from '~/icons'

import { DatePractitionerCell } from '../utils'
import { useCreateKeyboardShortcuts } from './useCreateKeyboardShortcuts'

type Props = {
    selectedCells: DatePractitionerCell[]
    setIsOpen: (isOpen: boolean) => void
    clearSelection: () => void
}

export const Popover = ({ selectedCells, setIsOpen, clearSelection }: Props) => {
    const cellsAmount = selectedCells.length

    const [confirm, AlertDialog] = useConfirmDialog()
    useCreateKeyboardShortcuts({ selectedCells, confirm })

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute bottom-6 left-1/2 z-elevated -translate-x-1/2 transform"
            >
                <div className="popover flex !min-w-fit items-center justify-between gap-x-12 p-2">
                    <div className="flex items-center gap-x-2">
                        <Tag color="primary">{cellsAmount}</Tag>
                        <span>valgt</span>
                    </div>
                    <Button size="sm" color="secondary" onClick={() => setIsOpen(true)}>
                        Åpne
                    </Button>
                    <CloseOutlined className="cursor-pointer rounded-full" onClick={clearSelection} />
                </div>
            </motion.div>
            <AlertDialog />
        </>
    )
}
