import { Show } from '~/components'
import env from '~/env'
import { selectDrawerDates, selectGetLocations, selectGetOccupancies, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import { BlockCard } from '../Calendar/CellBlock/BlockCard'
import { BookingWaitingList } from './BookingWaitingList'

export const BookingDrawerAvailability = () => {
    const { locationId } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const { date } = useStore(selectDrawerDates)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const getLocations = useStore(selectGetLocations)

    const location = locationId ? getLocations.byId(locationId) : null
    if (isNullish(location)) return null

    const occupancyData = locationId ? getOccupancies.byDateAndLocation(date, location) : null
    const scheduledPractitioners = locationId ? getScheduledPractitioners.byDateAndLocationId(date, locationId) : []

    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col" data-test="BookingDrawerAvailability">
                <p className="pb-1 text-base font-semibold">Ledige Tider</p>
                {occupancyData && (
                    <>
                        <BlockCard date={date} occupancyData={occupancyData} practitioners={scheduledPractitioners} style="available" location={location} />
                        <div data-test="booking-drawer-available-loaded" className="hidden" />
                    </>
                )}
            </div>
            <div className="flex flex-col gap-2">
                <Show condition={env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER}>
                    <BookingWaitingList practitioners={scheduledPractitioners} occupancyData={occupancyData} />
                </Show>
            </div>
        </div>
    )
}
