import { isCountBasedRuleEvaluation, OccupancyData, ResolvedPatientGroup } from '~/store/selectors'

import { MinimalPractitioner } from '../dips'

export const hasMatchingSurgeryType = (pg: ResolvedPatientGroup | null, surgeryTypeId: number) => {
    if (!pg) return false
    return pg?.surgeryTypeGroup?.resolvedSurgeryTypes?.hasOwnProperty(surgeryTypeId)
}

export const isOccupancyBookable = ({ occupancy, matchPatientGroup }: { occupancy: OccupancyData; matchPatientGroup?: { surgeryTypeId: number } }) => {
    return occupancy?.evaluations
        .filter(isCountBasedRuleEvaluation)
        .filter(allCountEvals => allCountEvals.status === 'Available')
        .map(availableEval => availableEval.filteredByPatientGroup)
        .some(pg => (matchPatientGroup ? hasMatchingSurgeryType(pg, matchPatientGroup.surgeryTypeId) : true))
}

export const hasMatchingPractitioner = (surgeryPractitioner: MinimalPractitioner[], practitionersForLocation: MinimalPractitioner[]) => {
    return surgeryPractitioner.some(practitioner => {
        return practitionersForLocation.map(p => p.short_name.toLocaleLowerCase()).includes(practitioner.short_name.toLocaleLowerCase())
    })
}
