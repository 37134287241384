import { MaxCountOfSurgeriesPerSurgeryTypeGroup } from '~/clients/derived-api-types'
import { CapacityRule, GetResolvedPatientGroups } from '~/store/selectors'
import { isNullish } from '~/utils/guards'

import { maxCountRule } from './rules/implementations/maxCountRule'

/**
 * implementation of the block rule: max_count_of_surgeries_per_patient_group
 * @param parameters shall contain the parameters of the rule as an array of type {max_count: number, patient_group: string}
 */
export function getMaxCountBlockRules(parameters: MaxCountOfSurgeriesPerSurgeryTypeGroup, getPatientGroups: GetResolvedPatientGroups): CapacityRule[] {
    const capacityRules: CapacityRule[] = []

    for (const constraint of parameters.constraints) {
        const patientGroup = getPatientGroups.byConstraints(constraint.patient_group)

        if (isNullish(patientGroup?.surgeryTypeGroup)) {
            console.error(`Surgery Type group "${constraint.patient_group.patient_group_id}" not found (max_count_of_surgeries_per_patient_group)`)
        } else {
            capacityRules.push(maxCountRule(constraint.max_count, patientGroup))
        }
    }

    return capacityRules
}
