import { Duration } from 'dayjs/plugin/duration'

import { CapacityRule, CapacityRuleTypes } from '~/store/selectors'
import { getRoomDuration } from '~/utils/dips'

import { newDurationBasedRule } from '../durationBasedRule'

const marginInMinutes = 30

export function maxRoomDurationRule(maxDuration: Duration): CapacityRule {
    return newDurationBasedRule(CapacityRuleTypes.RoomDurationBased, null, maxDuration, getRoomDuration, marginInMinutes)
}
