import clsx from 'clsx'

import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { MoreOutlined } from '~/icons'

import { HeroButton } from '../HeroButton/HeroButton'

const optionClassName: Record<string, string> = {
    default: '',
    delete: 'text-red-500',
}

type MenuOptionVariantKeys = keyof typeof optionClassName

// This is workaround for allowing to pass any string but also give intellisense for predefined values
type MenuOptionVariant = MenuOptionVariantKeys | (string & { _?: never })

type Props = {
    /**
     * The slot for the title section of the list item
     */
    titleSlot: React.ReactNode
    /**
     * The slot for the content (largest) section of the list item
     */
    contentSlot: React.ReactNode
    /**
     * The main action for the button on the list item
     */
    mainAction: { label: string; disabled?: boolean; action: () => void; icon?: React.ReactNode }
    /**
     * An array of menu options to display when the menu button is clicked. also controls showing the menu button
     */
    menuOptions?: Array<{ label: string; action: () => void; icon?: React.ReactNode; variant?: MenuOptionVariant }>
    /**
     * An optional action to be attached to the title section of the card
     */
    cardAction?: { tooltip: string; action: () => void }
}

export const HeroListItem = ({ titleSlot, contentSlot, mainAction, menuOptions, cardAction }: Props) => {
    const TitleSection = () => {
        return cardAction ? (
            <HeroTooltip tooltipContent={cardAction.tooltip} side="top">
                <button
                    aria-label={cardAction.tooltip}
                    className="flex h-full w-60 items-center text-ellipsis border-r border-slate-200 pl-3 transition-all hover:bg-slate-100"
                    onClick={cardAction.action}
                >
                    {titleSlot}
                </button>
            </HeroTooltip>
        ) : (
            <div className="flex h-full w-60 items-center text-ellipsis border-r border-slate-200 pl-3">{titleSlot}</div>
        )
    }
    return (
        <div data-test="hero-list-item" className="flex h-12 items-center gap-3 rounded border border-slate-200 pr-3">
            <TitleSection />
            <div className="flex-grow">{contentSlot}</div>
            <div className="flex items-center gap-2">
                {mainAction && (
                    <HeroButton
                        type="secondary"
                        disabled={mainAction.disabled}
                        onClick={mainAction.action}
                        startIcon={mainAction.icon}
                        data-test="hero-list-item-main-action-button"
                    >
                        {mainAction.label}
                    </HeroButton>
                )}
                {menuOptions && (
                    <HeroPopover
                        isModal={false}
                        noPadding
                        popoverContent={
                            <ul>
                                {menuOptions.map(option => (
                                    <li key={option.label} className={clsx('[&:not(:last-child)]:border-b', optionClassName[option?.variant || 'default'])}>
                                        <button className="flex w-full items-center gap-2 px-4 py-2 hover:bg-slate-100" onClick={option.action}>
                                            {option?.icon} {option.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        }
                    >
                        <button className="inline-flex h-8 w-8 items-center justify-center rounded bg-white hover:bg-slate-100">
                            <MoreOutlined />
                        </button>
                    </HeroPopover>
                )}
            </div>
        </div>
    )
}
