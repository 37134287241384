// import { cva, VariantProps } from 'class-variance-authority'
import { Slot } from '@radix-ui/react-slot'
import cn from 'clsx'
import { ComponentProps, createContext, ElementRef, forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Button } from '~/components/Button'
import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { PanelLeftClose } from '~/icons/PanelLeftClose'
import { PanelLeftOpen } from '~/icons/PanelLeftOpen'

const SIDEBAR_COOKIE_NAME = 'sidebar:state'
const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7
const SIDEBAR_WIDTH = '13.75rem'
const SIDEBAR_WIDTH_ICON = '3rem'
const SIDEBAR_KEYBOARD_SHORTCUT = 'b'

type SidebarContext = {
    state: 'expanded' | 'collapsed'
    open: boolean
    setOpen: (open: boolean) => void
    toggleSidebar: () => void
}

const SidebarContext = createContext<SidebarContext | null>(null)

function useHeroSideNav() {
    const context = useContext(SidebarContext)
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider.')
    }

    return context
}

const HeroSidebarProvider = ({
    defaultOpen = true,
    open: openProp,
    onOpenChange: setOpenProp,
    children,
    style,
    className,
    ...props
}: ComponentProps<'div'> & {
    defaultOpen?: boolean
    open?: boolean
    onOpenChange?: (open: boolean) => void
    children: React.ReactNode
}) => {
    // This is the internal state of the sidebar.
    // We use openProp and setOpenProp for control from outside the component.
    const [_open, _setOpen] = useState(defaultOpen)
    const open = openProp ?? _open
    const setOpen = useCallback(
        (value: boolean | ((value: boolean) => boolean)) => {
            const openState = typeof value === 'function' ? value(open) : value
            if (setOpenProp) {
                setOpenProp(openState)
            } else {
                _setOpen(openState)
            }

            // This sets the cookie to keep the sidebar state.
            document.cookie = `${SIDEBAR_COOKIE_NAME}=${openState}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`
        },
        [setOpenProp, open]
    )

    // Helper to toggle the sidebar.
    const toggleSidebar = useCallback(() => {
        return setOpen(open => !open)
    }, [setOpen])

    // Adds a keyboard shortcut to toggle the sidebar.
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === SIDEBAR_KEYBOARD_SHORTCUT && (event.metaKey || event.ctrlKey)) {
                event.preventDefault()
                toggleSidebar()
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [toggleSidebar])

    // We add a state so that we can do data-state="expanded" or "collapsed".
    // This makes it easier to style the sidebar with Tailwind classes.
    const state = open ? 'expanded' : 'collapsed'

    const contextValue = useMemo<SidebarContext>(
        () => ({
            state,
            open,
            setOpen,
            toggleSidebar,
        }),
        [state, open, setOpen, toggleSidebar]
    )

    return (
        <SidebarContext.Provider value={contextValue}>
            <div
                style={
                    {
                        '--sidebar-width': SIDEBAR_WIDTH,
                        '--sidebar-width-icon': SIDEBAR_WIDTH_ICON,
                        ...style,
                    } as React.CSSProperties
                }
                className={cn('group/sidebar-wrapper flex min-h-svh w-full has-[[data-variant=inset]]:bg-sidebar', className)}
                {...props}
            >
                {children}
            </div>
        </SidebarContext.Provider>
    )
}

const Sidebar = ({
    side = 'left',
    variant = 'sidebar',
    collapsible = 'offcanvas',
    className,
    children,
    ...props
}: React.ComponentProps<'div'> & {
    side?: 'left' | 'right'
    variant?: 'sidebar' | 'inset'
    collapsible?: 'offcanvas' | 'icon' | 'none'
}) => {
    const { state } = useHeroSideNav()

    if (collapsible === 'none') {
        return (
            <div className={cn('flex h-full w-[--sidebar-width] flex-col bg-sidebar text-sidebar-foreground', className)} {...props}>
                {children}
            </div>
        )
    }

    return (
        <div
            className="group peer text-sidebar-foreground"
            data-state={state}
            data-collapsible={state === 'collapsed' ? collapsible : ''}
            data-variant={variant}
            data-side={side}
        >
            {/* This is what handles the sidebar gap on desktop */}
            <div
                className={cn(
                    'relative h-svh w-[--sidebar-width] bg-transparent transition-[width] duration-200 ease-linear',
                    'group-data-[collapsible=offcanvas]:w-0',
                    'group-data-[side=right]:rotate-180',
                    variant === 'inset'
                        ? 'group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]'
                        : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon]'
                )}
            />
            <div
                className={cn(
                    'fixed inset-y-0 flex h-svh w-[--sidebar-width] transition-[left,right,width] duration-200 ease-linear',
                    side === 'left'
                        ? 'left-0 group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]'
                        : 'right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]',
                    // Adjust the padding for floating and inset variants.
                    variant === 'inset'
                        ? 'p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]'
                        : 'group-data-[collapsible=icon]:w-[--sidebar-width-icon] group-data-[side=left]:border-r group-data-[side=right]:border-l',
                    className
                )}
                {...props}
            >
                <div
                    data-sidebar="sidebar"
                    className="flex h-full w-full flex-col bg-sidebar group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-sidebar-border group-data-[variant=floating]:shadow"
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export const HeroSideBar = ({ children, ...props }: { children: React.ReactNode } & ComponentProps<'div'>) => {
    return (
        <Sidebar collapsible="icon" {...props}>
            {children}
        </Sidebar>
    )
}

const HeroSideNavTrigger = forwardRef<ElementRef<typeof Button>, ComponentProps<typeof Button>>(({ className, onClick, ...props }, ref) => {
    const { toggleSidebar, state } = useHeroSideNav()

    return (
        <HeroTooltip side="bottom" tooltipContent="Toggle Sidebar">
            <Button
                ref={ref}
                data-sidebar="trigger"
                size="icon"
                color="custom"
                className={cn('h-7 w-7 !p-0 text-[#80838d]', className)}
                onClick={event => {
                    onClick?.(event)
                    toggleSidebar()
                }}
                {...props}
            >
                {state === 'collapsed' ? <PanelLeftOpen height={28} width={28} /> : <PanelLeftClose height={28} width={28} />}
                <span className="sr-only">Toggle Sidebar</span>
            </Button>
        </HeroTooltip>
    )
})
HeroSideNavTrigger.displayName = 'SidebarTrigger'

const SidebarHeader = ({ className, ...props }: ComponentProps<'div'>) => {
    return <div data-sidebar="header" className={cn('flex flex-col gap-2 p-2', className)} {...props} />
}

const SidebarFooter = forwardRef<HTMLDivElement, ComponentProps<'div'>>(({ className, ...props }, ref) => {
    return <div data-sidebar="footer" className={cn('flex flex-col gap-2 p-2', className)} {...props} ref={ref} />
})

const SidebarContent = ({ className, ...props }: ComponentProps<'div'>) => {
    return (
        <div
            data-sidebar="content"
            className={cn('flex min-h-0 flex-1 flex-col gap-2 overflow-auto group-data-[collapsible=icon]:overflow-hidden', className)}
            {...props}
        />
    )
}

const SidebarGroup = ({ className, ...props }: ComponentProps<'div'>) => {
    return <div data-sidebar="group" className={cn('relative flex w-full min-w-0 flex-col p-2', className)} {...props} />
}

const SidebarGroupContent = ({ className, ...props }: ComponentProps<'div'>) => (
    <div data-sidebar="group-content" className={cn('w-full text-sm', className)} {...props} />
)

const SidebarMenu = ({ className, ...props }: ComponentProps<'ul'>) => (
    <ul data-sidebar="menu" className={cn('flex w-full min-w-0 flex-col gap-1', className)} {...props} />
)

const SidebarMenuItem = ({ className, ...props }: ComponentProps<'li'>) => (
    <li data-sidebar="menu-item" className={cn('group/menu-item relative', className)} {...props} />
)

const SidebarMenuSubItem = ({ ...props }: ComponentProps<'li'>) => <li {...props} />

const SidebarMenuSub = forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(({ className, ...props }, ref) => (
    <ul
        ref={ref}
        data-sidebar="menu-sub"
        className={cn(
            'mx-3.5 flex min-w-0 translate-x-px flex-col gap-1 border-l border-sidebar-border py-0.5 pl-[18px] pr-2.5',
            'group-data-[collapsible=icon]:hidden',
            className
        )}
        {...props}
    />
))
SidebarMenuSub.displayName = 'SidebarMenuSub'

const SidebarMenuSubButton = forwardRef<
    HTMLAnchorElement,
    ComponentProps<'a'> & {
        asChild?: boolean
        size?: 'sm' | 'md'
        isActive?: boolean
    }
>(({ asChild = false, size = 'md', isActive, className, ...props }, ref) => {
    const Comp = asChild ? Slot : 'a'

    return (
        <Comp
            ref={ref}
            data-sidebar="menu-sub-button"
            data-size={size}
            data-active={isActive}
            className={cn(
                'flex h-7 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-md px-3 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-[22px] [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground',
                'data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground',
                size === 'sm' && 'text-xs',
                size === 'md' && 'text-sm',
                'group-data-[collapsible=icon]:hidden',
                className
            )}
            {...props}
        />
    )
})
SidebarMenuSubButton.displayName = 'SidebarMenuSubButton'

type Size = 'default' | 'sm' | 'lg'

const sizeStyles: Record<Size, string> = {
    default: 'h-8 text-sm',
    sm: 'h-7 text-xs',
    lg: 'h-12 text-sm group-data-[collapsible=icon]:!p-0',
}

const SidebarMenuButton = forwardRef<
    HTMLButtonElement,
    ComponentProps<'button'> & {
        asChild?: boolean
        isActive?: boolean
        variant?: 'default' | 'outline'
        size?: 'default' | 'sm' | 'lg'
        tooltipContent?: string
    }
>(({ asChild = false, isActive = false, variant = 'default', size = 'default', className, tooltipContent, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
        <HeroTooltip side="right" tooltipContent={tooltipContent}>
            <Comp
                ref={ref}
                data-sidebar="menu-button"
                data-size={size}
                data-active={isActive}
                className={cn(
                    // Base styles
                    `peer/menu-button flex w-full items-center gap-3 overflow-hidden rounded-md p-[5px] text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground data-[state=open]:hover:bg-sidebar-accent data-[state=open]:hover:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-[5px] [&>span:last-child]:truncate [&>svg]:size-[22px] [&>svg]:shrink-0 ${sizeStyles[size]}`,
                    // Variant styles
                    {
                        'hover:bg-sidebar-accent hover:text-sidebar-accent-foreground': variant === 'default',
                    },
                    // Default values (fallbacks)
                    variant ?? 'hover:bg-sidebar-accent hover:text-sidebar-accent-foreground',
                    size ?? 'h-8 text-sm',
                    // Additional custom classes
                    className
                )}
                {...props}
            />
        </HeroTooltip>
    )
})
SidebarMenuButton.displayName = 'SidebarMenuButton'

export {
    HeroSidebarProvider,
    HeroSideNavTrigger,
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useHeroSideNav as useSidebar,
}
