import { SurgeryMetadatumCreate } from '~/clients/derived-api-types'
import { createSurgeryMetadata, partialUpdateSurgeryMetadata } from '~/store/di-entity.api'
import { SurgeryMetadata } from '~/store/selectors'

export type Payload = Omit<SurgeryMetadatumCreate, 'checklist_items'> // we use patient statuses instead, e.g. patient_ready etc.

export function saveSurgeryMetadata(existingItem: SurgeryMetadata | undefined, payload: Payload) {
    if (!existingItem) {
        void createSurgeryMetadata(payload)
    } else {
        void partialUpdateSurgeryMetadata(existingItem.id, payload)
    }
}
