import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { DepartmentKey } from '../slices/filterSlice'
import { selectEntities, UnScheduledSurgery } from './entities'

export const selectGetUnScheduledSurgeries = createSelector(selectEntities, ({ unScheduledSurgeries }) => {
    const byBookingId = keyBy(unScheduledSurgeries, 'bookingId')
    const byDepartmentId = groupBy(unScheduledSurgeries, unScheduledSurgery => {
        return unScheduledSurgery.section?.department?.id
    })

    return {
        // Intended to act as a selector to search for scheduled surgeries by a search term,
        // the specific logic here is subject to change based on the actual requirements
        bySearchTerm: (searchTerm: string): UnScheduledSurgery[] => {
            if (!searchTerm) return []
            const searchTermLower = searchTerm.toLowerCase()
            const filtersSurgeries = unScheduledSurgeries.filter(surgery => {
                return `${surgery.patient?.firstName?.toLocaleLowerCase()} ${surgery.patient?.lastName?.toLocaleLowerCase()} ${surgery.patient?.nationalId?.toLocaleLowerCase()}`.includes(
                    searchTermLower
                )
            })
            return filtersSurgeries
        },
        byBookingId: (bookingId: UnScheduledSurgery['bookingId']) => {
            return !bookingId ? null : (byBookingId[bookingId] ?? null)
        },
        byDepartmentKey: (departmentKey: DepartmentKey) => {
            if (departmentKey === 'all') {
                return unScheduledSurgeries
            }

            return byDepartmentId[departmentKey] ?? []
        },
    }
})
