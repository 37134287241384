type Props = { columnCount: number; rowsToShow: number }

export const LoadingRows = ({ columnCount, rowsToShow }: Props) => {
    const columns = Array.from({ length: columnCount }, (_, i) => i)
    const rows = Array.from({ length: rowsToShow }, (_, i) => i)

    return (
        <>
            {rows.map(row => (
                <tr className="border-b" key={`row-${row}`}>
                    {columns.map((column, i) => (
                        <td key={`loader-${i}-${row}`} className="">
                            <div className="mx-6 my-4 box-border h-[24px] animate-pulse-fast rounded bg-gradient-to-l from-slate-300 via-slate-100 to-slate-300 bg-large" />
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}
