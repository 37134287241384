import { ComponentProps, FC } from 'react'

export const OperationIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33333 1.83325C7.83959 1.83325 8.25 2.24366 8.25 2.74992V3.66659H13.75V2.74992C13.75 2.24366 14.1604 1.83325 14.6667 1.83325C15.1729 1.83325 15.5833 2.24366 15.5833 2.74992V3.66659H16.5C17.2293 3.66659 17.9288 3.95632 18.4445 4.47204C18.9603 4.98777 19.25 5.68724 19.25 6.41659V17.4166C19.25 18.1459 18.9603 18.8454 18.4445 19.3611C17.9288 19.8769 17.2293 20.1666 16.5 20.1666H5.5C4.77065 20.1666 4.07118 19.8769 3.55546 19.3611C3.03973 18.8454 2.75 18.1459 2.75 17.4166V6.41659C2.75 5.68724 3.03973 4.98777 3.55546 4.47204C4.07118 3.95632 4.77065 3.66659 5.5 3.66659H6.41667V2.74992C6.41667 2.24366 6.82707 1.83325 7.33333 1.83325ZM6.41667 5.49992H5.5C5.25688 5.49992 5.02373 5.5965 4.85182 5.7684C4.67991 5.94031 4.58333 6.17347 4.58333 6.41659V9.16659H17.4167V6.41659C17.4167 6.17347 17.3201 5.94031 17.1482 5.7684C16.9763 5.5965 16.7431 5.49992 16.5 5.49992H15.5833V6.41659C15.5833 6.92285 15.1729 7.33325 14.6667 7.33325C14.1604 7.33325 13.75 6.92285 13.75 6.41659V5.49992H8.25V6.41659C8.25 6.92285 7.83959 7.33325 7.33333 7.33325C6.82707 7.33325 6.41667 6.92285 6.41667 6.41659V5.49992ZM17.4167 10.9999H4.58333V17.4166C4.58333 17.6597 4.67991 17.8929 4.85182 18.0648C5.02373 18.2367 5.25689 18.3333 5.5 18.3333H16.5C16.7431 18.3333 16.9763 18.2367 17.1482 18.0648C17.3201 17.8929 17.4167 17.6597 17.4167 17.4166V10.9999ZM6.41667 13.7499C6.41667 13.2437 6.82707 12.8333 7.33333 12.8333H9.16667C9.67293 12.8333 10.0833 13.2437 10.0833 13.7499V15.5833C10.0833 16.0895 9.67293 16.4999 9.16667 16.4999H7.33333C6.82707 16.4999 6.41667 16.0895 6.41667 15.5833V13.7499Z"
            fill="currentColor"
        />
    </svg>
)
