import stringify from 'fast-safe-stringify'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import { useEffect, useState } from 'react'

import { BlockLockFingerprint } from '~/clients/derived-api-types'
import { deleteBlockLock } from '~/store/di-entity.api'
import { CapacityRuleType, RuleEvaluation, selectEntities, selectGetLocations, selectGetOccupancies } from '~/store/selectors'
import { useStore } from '~/store/store'

import { day, getOnlyDate } from './extendedDayjs'

type CapacityRuleFingerprintV1 = Partial<Record<CapacityRuleType, number | null>>

type FingerprintV1 = {
    v: 1
    data: CapacityRuleFingerprintV1[]
}

/**
 * The fingerprint is used to determine if relevant information has changed in order
 * to decide if a BlockLock should be deleted, which will show availability in the calendar.
 *
 * Right now we're going with a simple approach where we just do a deep equality check between
 * the saved fingerprint and the actual fingerprint.
 *
 * Version 1 fingerprint is just availability data.
 */
export function createFingerprintV1(evaluations: RuleEvaluation[]): string {
    const data = evaluations
        .filter(evaluation => evaluation.status === 'Available' && evaluation.remaining !== null)
        .map(function (evaluation): CapacityRuleFingerprintV1 {
            if (evaluation.remaining === null || typeof evaluation.remaining === 'number') {
                return { [evaluation.type]: evaluation.remaining }
            }

            return { [evaluation.type]: evaluation.remaining.asHours() }
        })

    const fingerprint: FingerprintV1 = { v: 1, data }
    return stringify(fingerprint)
}

function parseFingerprint(fingerprint: BlockLockFingerprint): FingerprintV1 | null {
    if (!fingerprint) {
        return null
    }

    let parsed

    try {
        parsed = JSON.parse(fingerprint)
    } catch (e) {
        console.error('Failed to parse fingerprint', e)
        return null
    }

    if (!isObject(parsed)) {
        console.error('Fingerprint is not an object', parsed)
        return null
    }

    if (!('v' in parsed) || typeof parsed.v !== 'number') {
        console.error('Fingerprint is missing version', parsed)
        return null
    }

    if (parsed.v === 1) {
        return fingerprint as unknown as FingerprintV1
    }

    console.error('Unsupported fingerprint version', parsed.v)
    return null
}

function compareFingerprints(evaluations: RuleEvaluation[], fingerprintString: BlockLockFingerprint): boolean {
    const fingerprint = parseFingerprint(fingerprintString)

    if (!fingerprint) {
        return false
    }

    return isEqual(fingerprint, createFingerprintV1(evaluations))
}

export function useAutomaticBlockLockRelease(options: { skip: boolean }) {
    const [isOpeningBlockLock, setIsOpeningBlockLock] = useState(false)

    useEffect(() => {
        if (options.skip || isOpeningBlockLock) {
            return
        }

        const state = useStore.getState()
        const entities = selectEntities(state)
        const getLocations = selectGetLocations(state)
        const getOccupancies = selectGetOccupancies(state)

        const blockLocksToRelease = entities.blockLocks.filter(blockLock => {
            const date = day(getOnlyDate(blockLock.start_time))
            const location = getLocations.byId(blockLock.location_id)

            if (!location || !date) {
                return false
            }

            const occupancyData = getOccupancies.byDateAndLocation(date, location)

            return !compareFingerprints(occupancyData.evaluations, blockLock.fingerprint)
        })

        if (blockLocksToRelease.length === 0) {
            return
        }

        async function releaseLocks() {
            setIsOpeningBlockLock(true)
            await Promise.all(blockLocksToRelease.map(blockLock => deleteBlockLock(blockLock.id)))
            setIsOpeningBlockLock(false)
        }

        const timeout = setTimeout(releaseLocks, 100)

        return () => {
            clearTimeout(timeout)
        }
    }, [options.skip, isOpeningBlockLock])
}
