import { getClient } from '~/clients/dips-client'

async function fetchSagaStatusById(sagaId: string) {
    return await getClient().GET('/api/v1/saga/{sagaId}/status', {
        params: {
            path: { sagaId },
        },
    })
}

type SagaStatusResponse = Awaited<ReturnType<typeof fetchSagaStatusById>>

function getError(response: SagaStatusResponse) {
    const isError = response.error || !String(response.response.status).startsWith('2') || Boolean(response.data?.errorMessage)

    return {
        isError,
        errorMessage: response.data?.errorMessage ?? response.error?.errorMessage,
    }
}

type SagaStatusError = { errorMessage: string; isError: true; data?: undefined }
type SagaStatusSuccess = { errorMessage: null; isError: false; data: SagaStatusResponse['data'] }

function errorResponse(errorMessage: string): SagaStatusError {
    return { isError: true, errorMessage }
}

function delayAsyncCall<T>(fn: () => Promise<T>, delay: number): Promise<T> {
    return new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                const result = await fn()
                resolve(result)
            } catch (error) {
                reject(error)
            }
        }, delay)
    })
}

export async function pollSurgeryBookingStatus(sagaId: string): Promise<SagaStatusError | SagaStatusSuccess> {
    let response
    try {
        response = await fetchSagaStatusById(sagaId)
    } catch (e) {
        console.error('Error fetching saga status:', e)
        return errorResponse('Booking failed.')
    }

    const error = getError(response)

    if (error.isError) {
        console.error('Error fetching saga status from response:', error.errorMessage)
        return errorResponse(error.errorMessage ?? 'Booking failed.')
    }

    if (response.data?.status === 'Failed') {
        console.error('Booking failed:', response.data.errorMessage)
        return errorResponse(`Booking failed: ${response.data.errorMessage}`)
    }

    try {
        if (response.data?.status === 'Pending') {
            return await delayAsyncCall(() => pollSurgeryBookingStatus(sagaId), 500)
        }
    } catch (e) {
        console.error('Error re-polling saga status:', e)
        return errorResponse('Booking failed.')
    }

    return { isError: false, errorMessage: null, data: response.data }
}
