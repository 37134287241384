import * as icons from '~/icons'

export function Icons() {
    return (
        <div className="grid max-w-[60rem] grid-cols-6 gap-2">
            {Object.entries(icons).map(([name, Icon]) => {
                const nameParts = name.split(/(?=[A-Z])/)
                return (
                    <div key={name} className="flex flex-col justify-center gap-2 rounded border p-2">
                        <Icon className="mx-auto max-w-12" />
                        <div className="text-center text-xs">
                            {nameParts.map(part => (
                                <span key={part}>
                                    {part}
                                    <wbr />
                                </span>
                            ))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
