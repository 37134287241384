import { Dayjs } from 'dayjs'

import { PractitionerTag, RuleValuesTags, Show } from '~/components'
import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { Location, selectGetBlockSchedules, selectGetPractitionerSchedules, selectSurgeryTypeGroup } from '~/store/selectors'
import { useStore } from '~/store/store'
import { rulesResolver } from '~/utils/blockEvaluation'
import { day, humanizeDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'

type Props = {
    date: Dayjs
    location: Location
}

export const ResolvedRule = ({ date, location }: Props) => {
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)
    const getBlockSchedules = useStore(selectGetBlockSchedules)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    const blockSchedule = getBlockSchedules.byDateAndLocationId(date, location.id)
    const resolvedRule = rulesResolver({ rules: blockSchedule?.rule_instance, getSurgeryTypeGroup })
    const editInfo = blockSchedule && `Sist endret av ${blockSchedule.updated_by} ${humanizeDate(day(blockSchedule.updated_at))}`
    const practitioners = getPractitionerSchedules
        .byDateAndLocationId(day(date), location.id)
        .map(ps => ps.practitioner?.short_name)
        .filter(Boolean)

    return (
        <HeroTooltip tooltipContent={editInfo}>
            <div data-test={`block-schedule-${blockSchedule?.id}`} className="flex w-full grow flex-col justify-between gap-1">
                <div className="flex flex-col gap-1">
                    <Show condition={isNotNullish(blockSchedule)}>
                        <RuleValuesTags fullWidth size="md" resolvedConstraints={resolvedRule} />
                    </Show>
                </div>

                <div className="flex gap-2 px-0 py-2">
                    {practitioners.map(name => (
                        <PractitionerTag key={name} shortName={name} />
                    ))}
                </div>
            </div>
        </HeroTooltip>
    )
}
