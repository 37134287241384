import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

import { selectWaitingListFilters } from '~/store/slices/filterSlice'

export const selectWaitingListFilterValues = createSelector(selectWaitingListFilters, filters => {
    const filtersByType = groupBy(filters, 'type')

    const filterValues = {
        shortNoticeValues: filtersByType['kort-varsel']?.map(filter => filter.value) ?? [],
        prioritizationValues: filtersByType['prio']?.map(filter => filter.value) ?? [],
        clearedValues: filtersByType['cleared']?.map(filter => filter.value) ?? [],
        confirmedValues: filtersByType['confirmed']?.map(filter => filter.value) ?? [],
        asaValues: filtersByType['asa']?.map(filter => filter.value) ?? [],
        practitionerValues: filtersByType['practitioner']?.map(filter => filter.value) ?? [],
        diagnosisGroupValues: filtersByType['diagnosis-group']?.map(filter => filter.value) ?? [],
        surgeryCategoryValues: filtersByType['surgery-category']?.map(filter => filter.value) ?? [],
        dayOvernightValues: filtersByType['day-overnight']?.map(filter => filter.value) ?? [],
    }

    const isFiltersApplied = Object.values(filterValues).some(value => value.length > 0)

    return {
        ...filterValues,
        isFiltersApplied,
    }
})
