import { WidgetProps } from '@rjsf/utils'

import { DISelect } from '~/components'

export const CustomSelectWidget = ({ onChange, options, value }: WidgetProps) => {
    const candidateOption = options.enumOptions?.find(({ value: optionValue }) => optionValue === value)

    return (
        <DISelect
            isClearable={false}
            size="sm"
            options={options.enumOptions as { label: string; value: string }[]}
            value={candidateOption}
            onChange={val => onChange(val?.value)}
            placeholder="Søk etter en pasientgruppe"
        />
    )
}
