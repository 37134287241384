import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import optimusClient from '~/clients/optimus-client'
import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { HeroDonutChart } from '~/hero-ui/stories/visualizations/Donut/HeroDonutChart'
import { Location, selectGetBlockSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'

type Props = {
    date: Dayjs
    location: Location
}

export const Utilization = ({ date, location }: Props) => {
    const getBlockSchedules = useStore(selectGetBlockSchedules)

    const blockSchedule = getBlockSchedules.byDateAndLocationId(date, location.id)

    const [utilizationData, setUtilizationData] = useState<{
        description?: string | undefined
        utilization: number
    }>()

    useEffect(() => {
        if (!blockSchedule || blockSchedule.rule_instance?.rule_definition_id !== 'max_count_of_surgeries_per_patient_group') {
            setUtilizationData(undefined)
            return
        }
        const fetchStatus = async () => {
            try {
                const { data } = await optimusClient.getBlockScheduleUtilization(blockSchedule.id)
                setUtilizationData(data)
            } catch (error) {
                // awaiting design for error handling
            }
        }

        void fetchStatus()
    }, [blockSchedule])

    return (
        utilizationData && (
            <HeroTooltip side="top" tooltipContent={<p>{utilizationData.description}</p>} className="w-48">
                <div className="mb-1 flex w-fit items-center gap-1 rounded border border-slate-200 p-1" data-test="utilization-chart-loaded">
                    <HeroDonutChart
                        showTooltip={false}
                        className="h-4 w-4"
                        data={[
                            {
                                name: 'Utilization',
                                utilization: utilizationData?.utilization ?? 0,
                            },
                            {
                                name: 'Available',
                                utilization: 1 - (utilizationData?.utilization ?? 0),
                            },
                        ]}
                        category="name"
                        value="utilization"
                        colors={['emerald', 'gray']}
                    />
                    <p>{(utilizationData.utilization * 100).toPrecision(2)}%</p>
                </div>
            </HeroTooltip>
        )
    )
}
