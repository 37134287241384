import { createSelector } from 'reselect'
import { immer } from 'zustand/middleware/immer'

import env from '~/env'

import { Slice } from '../store'
import { AppSlice } from './appSlice'

export const landingPagesOrdered = ['OPERATIONAL_PLANNER', 'WAITING_LIST', 'MASTER_SURGERY_SCHEDULER', 'SURGEON_SCHEDULER', 'ADMIN_PANEL'] as const
export type LandingPage = (typeof landingPagesOrdered)[number]

type State = Record<LandingPage, boolean> & {
    periods: boolean
    waitingListMutableComments: boolean
    operationalPlannerOverbookedCountEvaluations: false
    refreshToken: boolean
}

export type FeatureFlagSlice = {
    featureFlags: State
}

export const createFeatureFlagSlice: Slice<FeatureFlagSlice> = immer(() => ({
    featureFlags: {
        // pages
        SURGEON_SCHEDULER: env.VITE_FF_SURGEON_SCHEDULER,
        WAITING_LIST: env.VITE_FF_WAITING_LIST,
        OPERATIONAL_PLANNER: env.VITE_FF_OPERATIONAL_PLANNER,
        MASTER_SURGERY_SCHEDULER: env.VITE_FF_MASTER_SURGERY_SCHEDULER,
        ADMIN_PANEL: env.VITE_FF_ENTITY_EDITOR_IN_NAVIGATION,
        periods: env.VITE_FF_PLANNING_PERIODS_TAB,
        // other
        waitingListMutableComments: env.VITE_FF_WAITING_LIST_MUTABLE_COMMENTS,
        operationalPlannerOverbookedCountEvaluations: false,
        refreshToken: env.VITE_FF_REFRESH_TOKEN,
    },
}))

export const selectLandingPage = createSelector(
    (state: FeatureFlagSlice) => state.featureFlags,
    (state: AppSlice) => state.app.activeViews,
    (featureFlags, activeViews) => {
        for (const landingPage of landingPagesOrdered) {
            if (featureFlags[landingPage]) {
                return activeViews[landingPage]
            }
        }
        return null
    }
)
