import { createContext, useContext } from 'react'

import { useCreateKeyboardNavigation } from './hooks'

export type KeyboardNavigationReturnType = ReturnType<typeof useCreateKeyboardNavigation>

export type DataGridContextProps = KeyboardNavigationReturnType

export const DataGridContext = createContext<DataGridContextProps | null>(null)
export const DataGridContextProvider = DataGridContext.Provider

export const useDataGridContext = () => {
    const context = useContext(DataGridContext)
    if (!context) throw new Error('useDataGridContext must be used within DataGridContextProvider')
    return context
}
