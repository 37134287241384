import * as Popover from '@radix-ui/react-popover'
import { PopoverContentProps } from '@radix-ui/react-popover'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

export type PopoverProps = {
    children: React.ReactNode
    /*
     * The content to display inside the popover.
     */
    popoverContent: React.ReactNode
    /*
     * Callback function that gets called when the popover open state changes.
     */
    onOpenChange?: (open: boolean) => void
    /*
     * noPadding is used to remove padding from the popover content.
     */
    noPadding?: boolean
    /*
     * isModal is used to control the modal state of the popover
     */
    isModal?: boolean
    // isOpen is used to control the open state of the popover
    // do not use this prop if you want the popover to be controlled by the Popover component
    isOpen?: boolean
} & PopoverContentProps

export const HeroPopover = ({ children, popoverContent, onOpenChange, noPadding = false, isModal = true, isOpen, ...contentProps }: PopoverProps) => {
    return (
        <Popover.Root
            modal={isModal}
            open={isOpen}
            onOpenChange={open => {
                onOpenChange?.(open)
            }}
        >
            <Popover.Trigger asChild data-test="hero-popover-trigger">
                {children}
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    asChild
                    sideOffset={5}
                    {...contentProps}
                    className={twMerge(
                        `rounded border border-slate-200 bg-white ${noPadding ? '' : 'p-4'} mx-2 shadow-xl outline-none`,
                        contentProps.className
                    )}
                >
                    <motion.div initial={{ opacity: 0, y: 25 }} animate={{ opacity: 1, y: 0 }} transition={{ type: 'linear' }}>
                        {popoverContent}
                    </motion.div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    )
}
