import { useRef } from 'react'

import { PractitionerTag, Show, Tag } from '~/components'
import { useStore } from '~/store/store'
import { getLastNameAndBirthYear } from '~/utils/dips'
import { isEllipsisActive } from '~/utils/utils'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'
import { columns, FormattedWaitingListItem } from '../shared/columns'
import { Remarks } from '../shared/Remarks'
import { Statuses } from '../shared/Statuses'
import { Cell } from './Cell'

type Props = {
    item: FormattedWaitingListItem
    className?: string
    'data-test'?: string
}

export const TableRowCells = ({ item }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)

    const isAll = activeView === '/patients/all'
    const isAllOrUnscheduled = isAll || activeView === '/patients/unscheduled'
    const isAllOrScheduled = isAll || activeView === '/patients/scheduled'

    const commentRef = useRef(null)
    const surgeryTypeRef = useRef(null)

    const surgery = useGetWaitingListSurgery(item)
    const patient = getLastNameAndBirthYear(surgery?.patient)

    const isFollowUpDateOverdue = item.FollowUpDate.includes('+')

    return (
        <>
            <Cell scope="row">{item.WaitingTime}</Cell>
            <Show condition={isAllOrUnscheduled}>
                <Cell>{item.TentativeDate}</Cell>
            </Show>
            <Show condition={isAllOrScheduled}>
                <Cell>{item.OperationDate}</Cell>
            </Show>
            <Cell>
                <div data-tooltip={item.BirthDate}>{patient}</div>
            </Cell>
            <Show condition={columns.ASA.showColumn(departmentKey)}>
                <Cell>
                    <Show condition={Boolean(item.ASA)}>
                        <div className="flex">
                            <Tag color="ASA" textSize="sm">
                                {item.ASA}
                            </Tag>
                        </div>
                    </Show>
                </Cell>
            </Show>
            <Cell>
                <Remarks remarks={item.Remarks} />
            </Cell>
            <Cell maxWidth={true} data-tooltip={commentRef?.current && isEllipsisActive(commentRef.current) ? item.Comment : ''}>
                <div ref={commentRef} className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.Comment}
                </div>
            </Cell>
            <Cell>{item.KnifeTime}</Cell>
            <Cell maxWidth={true} data-tooltip={surgeryTypeRef?.current && isEllipsisActive(surgeryTypeRef.current) ? item.SurgeryType : ''}>
                <div ref={surgeryTypeRef} className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {item.SurgeryType}
                </div>
            </Cell>
            <Cell>
                <div className="flex gap-1">
                    {item.Surgeons.map(shortName => (
                        <PractitionerTag key={shortName} shortName={shortName} />
                    ))}
                </div>
            </Cell>
            <Cell>
                <Statuses statuses={item.Statuses} isScheduledItem={item.BookedStatus === 'Scheduled'} />
            </Cell>
            <Cell>
                <p className={`${isFollowUpDateOverdue ? 'text-red-800' : ''}`}>{item.FollowUpDate}</p>
            </Cell>
        </>
    )
}
