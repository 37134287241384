import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Bar, Button } from '~/components'
import { HeroBreadcrumbs } from '~/hero-ui/stories/HeroBreadcrumbs/HeroBreadcrumbs'
import { HeroSideNavTrigger } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'
import { useStore } from '~/store/store'

import { CreatePeriodDialog } from '../shared/CreatePeriodDialog'

const RightContent = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button data-test="planning-period-create" onClick={() => setOpen(true)}>
                Legg til periode
            </Button>
            <CreatePeriodDialog open={open} onClose={() => setOpen(false)} />
        </>
    )
}

export const Header = () => {
    const breadcrumbs = useStore(state => state.app.breadcrumbs)
    const { setBreadcrumbs } = useStore(state => state.app.actions)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Kapasitet', href: '#' },
            { label: 'Perioder', href: location.pathname },
        ])
    }, [location.pathname, setBreadcrumbs])
    return (
        <Bar
            left={
                <div className="flex items-center gap-4">
                    <HeroSideNavTrigger />
                    <HeroBreadcrumbs options={breadcrumbs} />
                </div>
            }
            right={<RightContent />}
        />
    )
}
