import { useLocation } from 'react-router-dom'

import { pages } from '~/app/routes'
import env from '~/env'
import { HeroSideNavFooter, MenuOption } from '~/hero-ui/stories/HeroSideNav/HeroSideNavFooter'
import { useCopyToClipBoard } from '~/hooks/useCopyToClipBoard'
import { useWebSocketConnection } from '~/hooks/useWebSocketUpdates/useWebSocketUpdates'
import { FeedbackOutlined, HelpOutlined } from '~/icons'
import { showUserFeedbackForm } from '~/sentry'
import { selectEntities } from '~/store/selectors'
import { DepartmentKey } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { getPKCELogoutUrl, pkceClearLocalStorage } from '../PKCEAuthenticationFlow'

export const UserMenu = () => {
    const { connectionStatus } = useWebSocketConnection()
    const idTokenPKCE = useStore(state => state.user.idTokenPKCE)
    const dipsApiAccessTokenIsFromPKCE = useStore(state => state.user.dipsApiAccessTokenIsFromPKCE)
    const { display_name, username } = useStore(state => state.user)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const departments = useStore(state => state.di.entities.departments)
    const { copyToClipboard, CopyNotice, refs } = useCopyToClipBoard({ copyText: 'https://help.deepinsight.io' })

    const department = departmentKey !== 'all' ? departments.byId[departmentKey]?.name : 'Alle'
    const usesDepartmentFilter = !useLocation().pathname.startsWith(pages.DOWNLOAD.views.download.path)
    const logoutUrl = dipsApiAccessTokenIsFromPKCE ? getPKCELogoutUrl(idTokenPKCE) : env.VITE_API_BASE_URL + '/oauth/logout'
    const showWebSocketConnected = env.VITE_HINT_WEBSOCKET && connectionStatus === 'Open'

    const handleLogOut = () => {
        if (dipsApiAccessTokenIsFromPKCE) {
            pkceClearLocalStorage()
        }
    }

    const { departments: departmentsList } = useStore(selectEntities)
    const { setDepartment } = useStore(state => state.appFilters.actions)

    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnClick = (value: DepartmentKey, external_id: number | null) => {
        setDepartment(value, external_id)
        setSelectedPage(1)
    }

    const departmentOptions: MenuOption[] = usesDepartmentFilter
        ? [
              { title: 'Alle', action: () => handleOnClick('all', null), isActive: 'all' === departmentKey },
              ...departmentsList
                  .filter(Boolean)
                  ?.map(({ name, external_id, id }) => ({ title: name, action: () => handleOnClick(id, external_id ?? null), isActive: id === departmentKey })),
          ]
        : []

    return (
        <>
            <HeroSideNavFooter
                ref={refs.setReference}
                display_name={display_name ?? ''}
                username={username ?? ''}
                department={department ?? ''}
                logOutCallback={handleLogOut}
                showWebSocketStatus={showWebSocketConnected}
                logOutUrl={logoutUrl}
                departmentOptions={departmentOptions}
                userMenuOptions={[
                    {
                        title: 'Gi Tilbakemelding',
                        icon: FeedbackOutlined,
                        isHidden: !env.VITE_ENABLE_SENTRY_USER_FEEDBACK,
                        action: () => showUserFeedbackForm(display_name),
                    },
                    {
                        title: 'Hjelp',
                        action: copyToClipboard,
                        icon: HelpOutlined,
                        subTitle: 'Kopier lenke',
                    },
                ]}
            />
            {CopyNotice}
        </>
    )
}
