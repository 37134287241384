import { memo, RefObject } from 'react'

import { useVirtualizeList } from '../hooks'
import { Id } from '../utils'
import { BodyRow } from './BodyRow'
import { CellClassName, CellRender, RowClassName, RowRender } from './types'

export const cellHeightStyle = {
    short: 36,
    tall: 65,
} as const

export type CellHeight = keyof typeof cellHeightStyle

export type BodyProps<Row extends { id: Id }, Col extends { id: Id }> = {
    cols: Col[]

    dataGridRef: RefObject<HTMLDivElement>
    cellHeight?: CellHeight
    setIsModalOpen: (isOpen: boolean) => void
    gridTemplateColumns: string

    rows: Row[]
    rowRender: RowRender<Row>
    rowClassName?: RowClassName<Row>
    cellRender: CellRender<Row, Col>
    cellClassName?: CellClassName<Row, Col>
}

export type ExternalBodyProps<Row extends { id: Id }, Col extends { id: Id }> = Pick<
    BodyProps<Row, Col>,
    'rows' | 'rowRender' | 'rowClassName' | 'cellRender' | 'cellClassName' | 'cellHeight'
>

function BodyComponent<Row extends { id: Id }, Col extends { id: Id }>({
    dataGridRef,
    cellHeight = 'tall',
    rows,
    cols,
    rowRender,
    cellRender,
    rowClassName,
    cellClassName,
    gridTemplateColumns,
    setIsModalOpen,
}: BodyProps<Row, Col>) {
    const rowHeight = cellHeightStyle[cellHeight]
    const indexesOfVisibleRows = useVirtualizeList({ rowHeight, elementRef: dataGridRef })

    return (
        <>
            {rows.map((row, rowIndex) => {
                const isRowVisible = indexesOfVisibleRows.includes(rowIndex)

                if (!isRowVisible) return <div key={row.id} style={{ height: rowHeight }} />

                return (
                    <BodyRow
                        key={row.id}
                        setIsModalOpen={setIsModalOpen}
                        gridTemplateColumns={gridTemplateColumns}
                        rowIndex={rowIndex}
                        row={row}
                        cols={cols}
                        height={rowHeight}
                        rowRender={rowRender}
                        cellRender={cellRender}
                        rowClassName={rowClassName}
                        cellClassName={cellClassName}
                    />
                )
            })}
        </>
    )
}

export const TableBody = memo(BodyComponent) as typeof BodyComponent
