import { Dayjs } from 'dayjs'

import { BookingsList } from '~/app/pages/OperationalPlanner/Calendar/CellBlock/BookingsList'
import { Button, CollapsibleCard, PractitionerTags } from '~/components'
import env from '~/env'
import { OccupancyData } from '~/store/selectors'
import { MinimalPractitioner } from '~/utils/dips'

export type LocationCardProps = {
    date: Dayjs
    occupancyData: OccupancyData
    onClick: (selectedOccupancy: OccupancyData) => void
    scheduledPractitioners: MinimalPractitioner[]
}

export const LocationCard = ({ occupancyData, onClick, scheduledPractitioners }: LocationCardProps) => {
    return (
        <>
            <CollapsibleCard
                title={<PractitionerTags practitioners={scheduledPractitioners} />}
                icon={<div className="rounded bg-[#fff0c8] px-2 py-1 text-xs">{occupancyData.location.room_code}</div>}
                variant="white"
            >
                <div className="pb-2" data-test="OR-card">
                    <div className="flex flex-col gap-2 pt-4">
                        <p className="font-bold">Planlagt program på stuen</p>
                        <BookingsList occupancyData={occupancyData} data-test="waitling-list-open-rooms" />
                    </div>
                    {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON && (
                        <Button color="secondary" onClick={() => onClick(occupancyData)} className="mt-4" data-test="order-button">
                            Bestill operasjon
                        </Button>
                    )}
                </div>
            </CollapsibleCard>
        </>
    )
}
