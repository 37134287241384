import clsx from 'clsx'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
    scope?: 'row'
    maxWidth?: true
}

export const Cell = ({ children, scope, maxWidth, ...dataset }: Props) => {
    const className = clsx(
        'whitespace-nowrap p-2 font-normal text-slate-900 first-letter:capitalize',
        maxWidth && 'max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'
    )

    return scope === 'row' ? (
        <th scope="row" className={className}>
            {children}
        </th>
    ) : (
        <td {...dataset} className={className}>
            {children}
        </td>
    )
}
